/**
 * System Edit Component
 */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

/* MUI */
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Switch,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import NativeSelect from '@mui/material/NativeSelect';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

/* Icons */
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

/* Custom Components */
import { Slider } from './slider';
import BorderedSection from '../../../../shared/borderedSection';
import { Notifier } from '../../../../../system/services/notificationManager';
import { Choice, systemChoices } from '../helpers/choices';
import { TemperatureUnit } from '../../../../../system/models/temperatureUnits';

/* Types */
import { CurrentAttributes, SetpointProfile, Thermostat } from '../../types';
import { useSetpointProfileDetailQuery } from '../../../../../../types/generated-types';

interface SystemEditProps {
  currentValues: CurrentAttributes;
  handleUseCustomSetpointLimitsChange: (newValue: boolean) => void;
  propertySetpointProfile?: Maybe<SetpointProfile>;
  unitSetpointProfile?: Maybe<SetpointProfile>;
  thermostat?: Partial<Thermostat>;
  operatingMode?: Maybe<string>;
  systemType?: Maybe<string>;
  fanMode?: Maybe<string>;
  fanSpeed?: Maybe<string>;
  keypadLockoutLevel?: Maybe<number>;
  progMode?: Maybe<string>;
  maxCoolSetpointLimit?: Maybe<number>;
  maxHeatSetpointLimit?: Maybe<number>;
  minCoolSetpointLimit?: Maybe<number>;
  minHeatSetpointLimit?: Maybe<number>;
  schedVisibilityMode?: Maybe<string>;
  holdState?: Maybe<string>;
  holdDuration?: Maybe<number>;
  holdEnd?: Maybe<number>;
  handleChange: (
    changes: Record<string, number | string | boolean>,
    ensureConformity?: boolean,
  ) => void;
  handleSetpointLimitChange: (
    key: string,
    value: number,
  ) => Record<string, number | string | boolean>;
  targetUnits: TemperatureUnit;
}
export function SystemEdit(props: SystemEditProps) {
  const {
    currentValues,
    thermostat,
    handleChange,
    handleSetpointLimitChange,
    handleUseCustomSetpointLimitsChange,
    // TODO: CleanUp: if this prop is not useful (it is not used anywhere), remove it.
    // targetUnits,
  } = props;

  const [operatingModes, setOperatingModes] = useState<Choice[]>();
  const [setpointProfile, setSetpointProfile] =
    React.useState<Partial<SetpointProfile>>();
  const [
    openCustomSetpointLimitWarningDialog,
    setOpenCustomSetpointLimitWarningDialog,
  ] = useState(false);

  const supportedSystemTypes = systemChoices.systemTypes.filter(
    (type) =>
      thermostat?.supportsAlternateHeatSystemType ||
      (thermostat?.modbusRTU && type.id === 'SmartAhu') ||
      type.id !== 'AlternateHeat',
  );

  const theme = useTheme();
  const isSmall = theme.breakpoints.down('sm');

  enum MenuItems {
    OPERATING_MODE = 'operating_mode',
    FAN_MODE = 'fan_mode',
    SYSTEM_TYPE = 'system_type',
    SETPOINTS = 'setpoints',
    SCHEDULE_MODE = 'schedule_mode',
  }

  const minSetpointLimit = 45;
  const maxSetpointLimit = 90;

  const [expand, setExpand] = useState<Record<MenuItems, boolean>>({
    [MenuItems.OPERATING_MODE]: true,
    [MenuItems.FAN_MODE]: true,
    [MenuItems.SYSTEM_TYPE]: true,
    [MenuItems.SETPOINTS]: true,
    [MenuItems.SCHEDULE_MODE]: true,
  });

  const [values, setValues] = useState<
    Partial<CurrentAttributes> & {
      setpointLimitType?: string | boolean | number | undefined | null;
    }
  >();

  useEffect(() => {
    if (currentValues) {
      const {
        operatingMode,
        systemType,
        fanMode,
        progMode,
        baseMaxCoolSetpointLimit,
        baseMaxHeatSetpointLimit,
        baseMinCoolSetpointLimit,
        baseMinHeatSetpointLimit,
        maxCoolSetpointLimit,
        maxHeatSetpointLimit,
        minCoolSetpointLimit,
        minHeatSetpointLimit,
        keypadLockoutLevel,
        useCustomSetpointLimits,
        setpointLimitBasis,
        // TODO: CleanUp: do we need these any longer? If not, remove them?
        // schedVisibilityMode,
        // showFanButton,
        // supportsShowFanButton,
        // supportsPasscode,
        // supportsSystemType,
        // supportsRemoteSensorTempDelta,
        // supportsLastSetpointChange,
        // supportsFanOperations,
      } = currentValues;

      setValues({
        ...currentValues,
        operatingMode: operatingMode ?? 'Off',
        systemType: systemType ?? 'HeatAndCool',
        fanMode: fanMode ?? 'Off',
        progMode: progMode ?? 'SIMPLE_SETPOINT_MODE',
        keypadLockoutLevel: keypadLockoutLevel ?? '0',
        minCoolSetpointLimit: useCustomSetpointLimits
          ? minCoolSetpointLimit
          : baseMinCoolSetpointLimit,
        maxCoolSetpointLimit: useCustomSetpointLimits
          ? maxCoolSetpointLimit
          : baseMaxCoolSetpointLimit,
        minHeatSetpointLimit: useCustomSetpointLimits
          ? minHeatSetpointLimit
          : baseMinHeatSetpointLimit,
        maxHeatSetpointLimit: useCustomSetpointLimits
          ? maxHeatSetpointLimit
          : baseMaxHeatSetpointLimit,
        useCustomSetpointLimits: useCustomSetpointLimits ?? false,
        setpointLimitType: useCustomSetpointLimits
          ? 'custom'
          : setpointLimitBasis ?? 'default',
      });
    }
  }, [currentValues]);

  const fanModeLabel = thermostat?.isMultiSpeedThermostat
    ? 'Fan Speed Mode'
    : isSmall
      ? 'Fan Speed Cycling Mode'
      : 'Fan Single-Speed Cycling Mode';

  const fanModeChoices = thermostat?.isMultiSpeedThermostat
    ? systemChoices.fanSpeedModes
    : thermostat?.modbusRTU
      ? systemChoices.fanDutyCycleModes.filter(
          (choice) => choice.id !== 'SmartAhu',
        )
      : systemChoices.fanDutyCycleModes;

  useEffect(() => {
    if (values) {
      let choices: Choice[];
      const systemType = values.systemType ?? 'HeatAndCool';

      switch (systemType) {
        case 'SmartAhu':
        case 'HeatAndCool':
        case 'HeatPump':
          // Valid operatingModes are: Cool, Heat, Auto, Off - all the possible values
          choices = systemChoices.operatingModes;
          break;
        case 'CoolOnly':
          // Valid operatingModes are: Cool, Off
          choices = systemChoices.operatingModes.filter((mode) => {
            return mode.id === 'Cool' || mode.id === 'Off';
          });
          break;
        default:
          // All other Heat types
          // Valid operatingModes are: Heat, Off
          choices = systemChoices.operatingModes.filter((mode) => {
            return mode.id === 'Heat' || mode.id === 'Off';
          });
      }
      setOperatingModes(choices);
    }
  }, [values]);

  useEffect(() => {
    if (!values) return;
    const operatingMode = values.operatingMode;
    let correctionMade = false;

    switch (values.systemType) {
      case 'SmartAhu':
      case 'HeatAndCool':
      case 'HeatPump':
        // Valid operatingModes are: Cool, Heat, Auto, Off - all the possible values
        // So never have to change operatingMode when systemType changed to HeatAndCool
        break;
      case 'CoolOnly':
        // Valid operatingModes are: Cool, Off
        if (operatingMode !== 'Cool' && operatingMode !== 'Off') {
          // Change operating mode to Off
          values.operatingMode = 'Off';
          correctionMade = true;
        }
        break;
      default:
        // All other Heat types
        // Valid operatingModes are: Heat, Off
        if (operatingMode !== 'Heat' && operatingMode !== 'Off') {
          // Change operating mode to Off
          values.operatingMode = 'Off';
          correctionMade = true;
        }
        break;
    }
    if (correctionMade) {
      Notifier.warn(
        `${operatingMode} Mode invalid for ${values.systemType} HVAC System Type`,
        `Operating Mode has been changed to Off!`,
      );
    }
  }, [values]);

  const handleExpand = (menuItem: MenuItems) => {
    setExpand((current) => {
      return { ...current, [menuItem]: !current[menuItem] };
    });
  };

  // noinspection DuplicatedCode
  const handleSlider = (
    keys: [string, string],
    newValues: number | number[],
    activeThumb: number,
  ) => {
    /* required check because slider API requires both number and number[] */
    if (!Array.isArray(newValues)) {
      return;
    }

    if (activeThumb === 0) {
      handleSetpointLimitChange(keys[0], newValues[0] as number);
    } else {
      handleSetpointLimitChange(keys[1], newValues[1] as number);
    }
  };

  const handleOperatingModeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setValues((current) => ({ ...current, operatingMode: value }));
    handleChange({ operatingMode: value });
  };

  const handleFanModeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setValues((current) => ({ ...current, fanMode: value }));
    handleChange({ fanMode: value });
  };

  const handleSystemTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setValues((current) => ({ ...current, systemType: value }));
    handleChange({ systemType: value });
  };

  const handleShowFanSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setValues((current) => ({
      ...current,
      showFanButton: value,
    }));
    handleChange({ showFanButton: value });
  };

  const handleKeyboardLockoutLevelChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = event.target.value ?? '0';
    setValues((current) => ({
      ...current,
      keypadLockoutLevel: value,
    }));
    handleChange({ keypadLockoutLevel: value });
  };

  const handleOpenCustomSetpointLimitWarningDialog = () => {
    setOpenCustomSetpointLimitWarningDialog(true);
  };

  const handleCloseCustomSetpointLimitWarningDialog = () => {
    setOpenCustomSetpointLimitWarningDialog(false);
  };

  // TODO: CleanUp: is this still needed?
  // const _handleUseCustomSetpointLimitsChange = (newValue: boolean) => {
  //   const value = newValue;
  //   console.log('[handle-use-custom-setpoint-limits-change]', value);
  //   setValues((current) => ({
  //     ...current,
  //     useCustomSetpointLimits: value,
  //   }));
  //   handleUseCustomSetpointLimitsChange(newValue);
  // };

  return values ? (
    <>
      <Divider
        style={{ marginBottom: '12px' }}
        variant="middle"
        component="hr"
      />
      <Box sx={{ p: 0 }}>
        <BorderedSection
          title="Thermostat Control"
          labelStyle={{ fontSize: '10px' }}
          style={{ marginLeft: '4px', marginRight: '4px', padding: '0px' }}
          ccStyle={{ margin: '0px', padding: '0px' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0px',
              margin: '0px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {thermostat?.supportsSystemType && (
                <BorderedSection
                  title="HVAC System Type"
                  labelStyle={{ fontSize: '10px' }}
                  style={{ margin: '4px 4px 4px 4px' }}
                  ccStyle={{
                    width: '100%',
                    paddingTop: '0px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingBottom: '8px',
                  }}
                >
                  {values.modbusRTU ? (
                    <div style={{ width: '100%' }}>Smart AHU</div>
                  ) : (
                    <NativeSelect
                      value={values.systemType}
                      onChange={handleSystemTypeChange}
                      style={{ width: '100%' }}
                    >
                      {supportedSystemTypes.map((choice) => (
                        <option
                          key={`${choice.id}=${choice.label}`}
                          value={choice.id}
                          label={choice.label}
                        >
                          {choice.label}
                        </option>
                      ))}
                    </NativeSelect>
                  )}
                </BorderedSection>
              )}
              <BorderedSection
                title="Menu Lockout Settings"
                labelStyle={{ fontSize: '10px' }}
                style={{ margin: '4px 4px 4px 4px' }}
                ccStyle={{
                  width: '100%',
                  paddingTop: '0px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '8px',
                }}
              >
                <NativeSelect
                  value={values.keypadLockoutLevel}
                  onChange={handleKeyboardLockoutLevelChange}
                  style={{ width: '100%' }}
                >
                  {systemChoices.lockoutLevels
                    .filter((choice) => {
                      return values.keypadLockoutLevels?.includes(choice.id);
                    })
                    .map((choice) => (
                      <option
                        key={`${choice.id}=${choice.label}`}
                        value={choice.id}
                        label={choice.label}
                      >
                        {choice.label}
                      </option>
                    ))}
                </NativeSelect>
              </BorderedSection>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '4px',
              }}
            >
              <BorderedSection
                title="Operating Mode"
                labelStyle={{ fontSize: '10px' }}
                style={{ margin: '4px 4px 4px 4px' }}
                ccStyle={{
                  width: '100%',
                  paddingTop: '0px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '8px',
                }}
              >
                <NativeSelect
                  value={values.operatingMode}
                  onChange={handleOperatingModeChange}
                  style={{ width: '100%' }}
                >
                  {(operatingModes ?? []).map((choice) => (
                    <option
                      key={`${choice.id}=${choice.label}`}
                      value={choice.id}
                      label={choice.label}
                    >
                      {choice.label}
                    </option>
                  ))}
                </NativeSelect>
              </BorderedSection>

              <BorderedSection
                title={fanModeLabel}
                labelStyle={{ fontSize: '10px' }}
                style={{ margin: '4px 4px 4px 4px' }}
                ccStyle={{
                  width: '100%',
                  paddingTop: '0px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '8px',
                }}
              >
                {values.modbusRTU ? (
                  <div style={{ width: '100%' }}>Smart AHU</div>
                ) : (
                  <NativeSelect
                    value={values.fanMode}
                    onChange={handleFanModeChange}
                    style={{ width: '100%' }}
                  >
                    {fanModeChoices.map((choice) => (
                      <option
                        key={`${choice.id}=${choice.label}`}
                        value={choice.id}
                        label={choice.label}
                      >
                        {choice.label}
                      </option>
                    ))}
                  </NativeSelect>
                )}
              </BorderedSection>
            </Box>
            {values.supportsShowFanButton && !values.modbusRTU ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '4px',
                }}
              >
                <BorderedSection
                  title="Fan Button"
                  style={{ margin: '4px 4px 4px 4px' }}
                  ccStyle={{
                    width: '100%',
                    paddingTop: '0px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingBottom: '8px',
                  }}
                >
                  <FormGroup style={{ paddingLeft: '6px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          onChange={handleShowFanSwitchChange}
                          name="isSuper"
                          id="isSuper"
                          checked={values.showFanButton as boolean}
                        />
                      }
                      label="Show Fan Button"
                    />
                  </FormGroup>
                </BorderedSection>
              </Box>
            ) : null}
            {values.operatingMode === 'Off' ? null : (
              <BorderedSection
                title="Setpoint Limits"
                style={{ margin: '8px 4px 4px 4px', padding: '0px' }}
              >
                <List>
                  {/* Setpoint Limits */}
                  <ListItemButton
                    onClick={() => handleExpand(MenuItems.SETPOINTS)}
                  >
                    <Stack
                      width={'100%'}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <ListItemText
                        primary={`Using ${capitalize(
                          (values.setpointLimitType as string) ?? 'default',
                        )} Limits`}
                      />
                      {['Heat', 'Auto'].includes(
                        values.operatingMode as string,
                      ) ? (
                        <ListItemText
                          secondaryTypographyProps={{
                            variant: 'caption',
                            align: 'right',
                          }}
                          secondary={
                            <Chip
                              sx={{ backgroundColor: theme.embue.heating }}
                              label={`${values.minHeatSetpointLimit}-${values.maxHeatSetpointLimit}`}
                            />
                          }
                        />
                      ) : null}
                      {['Cool', 'Auto'].includes(
                        values.operatingMode as string,
                      ) ? (
                        <ListItemText
                          secondaryTypographyProps={{
                            pr: 1,
                            variant: 'caption',
                            align: 'right',
                          }}
                          secondary={
                            <Chip
                              sx={{ backgroundColor: theme.embue.cooling }}
                              label={`${values.minCoolSetpointLimit}-${values.maxCoolSetpointLimit}`}
                            />
                          }
                        />
                      ) : null}
                    </Stack>
                    {expand.setpoints ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={expand.setpoints}
                    timeout={'auto'}
                    unmountOnExit
                  >
                    <Box
                      sx={{
                        justifyContent: 'center',
                        px: 4,
                        py: 2,
                      }}
                    >
                      <Stack
                        spacing={2}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!values.useCustomSetpointLimits}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (thermostat?.thermostatScheduleTemplateId) {
                                  handleOpenCustomSetpointLimitWarningDialog();
                                } else {
                                  handleUseCustomSetpointLimitsChange(
                                    !values.useCustomSetpointLimits,
                                  );
                                }
                              }}
                            />
                          }
                          label="Use Custom Setpoint Limits"
                        />
                      </Stack>
                      {['Heat', 'Auto'].includes(
                        values.operatingMode as string,
                      ) ? (
                        <Stack
                          spacing={2}
                          direction={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Typography variant="button">
                            {minSetpointLimit}
                          </Typography>
                          <Slider
                            disabled={!values.useCustomSetpointLimits}
                            getAriaLabel={() => 'Heat Setpoint Limits'}
                            value={[
                              values.minHeatSetpointLimit as number,
                              values.maxHeatSetpointLimit as number,
                            ]}
                            min={minSetpointLimit}
                            max={maxSetpointLimit}
                            onChange={(
                              _event: Event,
                              newValues: number | number[],
                              activeThumb: number,
                            ) =>
                              handleSlider(
                                [
                                  'minHeatSetpointLimit',
                                  'maxHeatSetpointLimit',
                                ],
                                newValues,
                                activeThumb,
                              )
                            }
                            valueLabelDisplay="on"
                            disableSwap
                            sx={{
                              color: theme.embue.heating,
                              py: 2,
                            }}
                          />
                          <Typography variant="button">
                            {maxSetpointLimit}
                          </Typography>
                        </Stack>
                      ) : null}
                      {['Cool', 'Auto'].includes(
                        values.operatingMode as string,
                      ) ? (
                        <Stack
                          spacing={2}
                          direction={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Typography variant="button">
                            {minSetpointLimit}
                          </Typography>
                          <Slider
                            disabled={!values.useCustomSetpointLimits}
                            getAriaLabel={() => 'Cool Setpoint Limits'}
                            value={[
                              values.minCoolSetpointLimit as number,
                              values.maxCoolSetpointLimit as number,
                            ]}
                            min={minSetpointLimit}
                            max={maxSetpointLimit}
                            onChange={(
                              _event: Event,
                              newValues: number | number[],
                              activeThumb: number,
                            ) =>
                              handleSlider(
                                [
                                  'minCoolSetpointLimit',
                                  'maxCoolSetpointLimit',
                                ],
                                newValues,
                                activeThumb,
                              )
                            }
                            valueLabelDisplay="on"
                            disableSwap
                            sx={{
                              color: theme.embue.cooling,
                              py: 2,
                            }}
                          />
                          <Typography variant="button">
                            {maxSetpointLimit}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Box>
                  </Collapse>
                </List>
              </BorderedSection>
            )}
          </Box>
        </BorderedSection>
      </Box>
      <CustomThermostatSetpointLimitChangeWarningDialog
        handleClose={handleCloseCustomSetpointLimitWarningDialog}
        open={openCustomSetpointLimitWarningDialog}
        handleUseCustomSetpointLimitsChange={
          handleUseCustomSetpointLimitsChange
        }
        values={values}
      />
    </>
  ) : null;
}

function CustomThermostatSetpointLimitChangeWarningDialog(props: {
  handleClose: () => void;
  values: Partial<CurrentAttributes>;
  handleUseCustomSetpointLimitsChange: (b: boolean) => void;
  open: boolean;
}) {
  const { handleClose, open, values, handleUseCustomSetpointLimitsChange } =
    props;

  return (
    <Dialog
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <Typography variant="h4" sx={{ padding: '15px' }}>
        Warning: Custom Setpoint Limit incompatible with current thermostat
        schedule template
      </Typography>
      <DialogContent sx={{ padding: '10px 10px' }}>
        <Typography>
          Using a custom setpoint limit will remove the current schedule
          template applied to the thermostat. Do you wish to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container rowSpacing={1} direction={'row'}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="warning"
              sx={{ color: '#fff' }}
              onClick={() => {
                handleUseCustomSetpointLimitsChange(
                  !values.useCustomSetpointLimits,
                );
                handleClose();
              }}
            >
              Continue
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              color="error"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
