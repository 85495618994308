/**
 * This file provides a shared React Context that encapsulates the state which
 * represents an Authenticated User and their associated 'session'. It also
 * provides an API to manage that session state.
 *
 * This includes attributes which track whether there is an active session,
 * which user is logged in, whether the session has expired and, if not, when
 * the session will expire, and if the session is 'about to expire' (warning).
 *
 * It also provides hook methods to check if there is an active session ongoing,
 * extend the current, active session, reset the authentication state, and to
 * manage the server interactions to facilitate the MFA (authenticate user) and
 * reset password workflows.
 */

import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import EJSON from 'ejson';
import axios from 'axios';
import { Service } from 'axios-middleware';

import {
  AUTH_ID_TOKEN_NAME,
  AUTH_REMEMBER_ME_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_SESSION_WARNING_MINUTES,
  AUTH_STATE_TOKEN_NAME,
  LOCAL_AUTH_USER_KEY,
  AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
} from '../../helpers/config';

import { SHA256 } from './helpers/sha';

import {
  TokenFactoryMap,
  useSessionState,
} from '../system/SessionStateManager';
import { useSystemCommands } from '../system/SystemCommunications';
import { useSystemConnection } from '../system/ConnectionManager';

import { LocalState } from '../system/services/localStateManager';
import { Notifier } from '../system/services/notificationManager';
import { Log, LogCategory } from '../system/services/logger';

import {
  AuthenticationProfileFactors,
  SystemCommand,
  useUserDetailQuery,
  User,
  useCurrentUserDetailSubscription,
  EnumAuthenticatorDeviceCredentialBoundTo,
  EnumAuthenticatorDeviceCredentialOs,
} from '../../types/generated-types';

import { AuthorizedUser, IServerToken } from './models';

import { EmbueError } from '../system/models/embueError';
import { updateCacheFromSubscriptionEvent } from '../../helpers/subscriptionUtils';
import { getAuthHeaders } from '../../helpers/apolloClient';
import { useSystemHistory } from '../ui/history/history-manager';
import { usePendoClient } from '../system/PendoContext';
import {
  AuthenticationResponseJSON,
  CredentialDeviceType,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from '@simplewebauthn/types';
import {
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isIOS,
  isMacOs,
  isSafari,
  isWindows,
} from 'react-device-detect';
import { AccountLinkingRequestData } from './types/smart';

export type AuthenticationExtensionsAuthenticatorOutputs = {
  devicePubKey?: DevicePublicKeyAuthenticatorOutput;
  uvm?: UVMAuthenticatorOutput;
};
export type DevicePublicKeyAuthenticatorOutput = {
  dpk?: Uint8Array;
  sig?: string;
  nonce?: Uint8Array;
  scope?: Uint8Array;
  aaguid?: Uint8Array;
};
export type UVMAuthenticatorOutput = {
  uvm?: Uint8Array[];
};

type VerifiedAuthenticationResponse = {
  verified: boolean;
  authenticationInfo: {
    credentialID: Uint8Array;
    newCounter: number;
    userVerified: boolean;
    credentialDeviceType: CredentialDeviceType;
    credentialBackedUp: boolean;
    authenticatorExtensionResults?: AuthenticationExtensionsAuthenticatorOutputs;
  };
};

export interface IAuthenticationRequest {
  value?: string;
  smart?: boolean;
}

/**
 * This is the shared context and represents the following state and API:
 *   activeSession: True if there is an active session, falsy otherwise.
 *   expirationWarning: True if the session is about to expire, falsy otherwise.
 *   sessionExpiresAt: Time when the session will expire, initially undefined.
 *   user: The authenticated and authorized user, initially undefined.
 *   sessionExpired: True if the session has expired, falsy otherwise.
 *
 *   authenticate: A function to authenticate a user. Used repeatedly to check
 *       the user's response to each factor challenge in an MFA workflow.
 *   extendSession: A function to extend the current session. Resets
 *       sessionExpiresAt to the standard session expiration time and resets
 *       expirationWarning to false.
 *   hasActiveSession: A function to check for an active session. True if found.
 *   resetAuthState: A function to reset the authentication state. Clears any
 *       current authentication state and, optionally, resets the endpoint ID.
 *   signOut: A function to log the current users out. Resets the authentication
 *       state, sessionExpiresAt, expirationWarning, sessionExpired, and user.
 *   initiatePasswordReset: A function to initiate a password reset workflow.
 *   resetPassword: A function to reset a user's password as the final step in
 *       a password reset workflow.
 */
export interface IAuthenticator {
  activeSession: boolean;
  expirationWarning: boolean | undefined;
  sessionExpiresAt: Date | undefined;
  user: AuthorizedUser | undefined;
  userName: string | undefined;
  sessionExpired: boolean | undefined;
  authenticate: (
    request: IAuthenticationRequest,
    requestAlternate?: boolean,
  ) => Promise<IAuthenticationResult | void>;
  extendSessionTime: () => Promise<void>;
  getPasskeyCreationOptions: (
    external: boolean,
    enforceUserVerification: boolean,
  ) => Promise<PublicKeyCredentialCreationOptionsJSON | void>;
  getPasskeyAuthenticationOptions: () => Promise<PublicKeyCredentialRequestOptionsJSON | void>;
  validatePasskeyAuthentication: (
    res: AuthenticationResponseJSON,
  ) => Promise<VerifiedAuthenticationResponse | void>;
  hasActiveSession: () => boolean;
  resetAuthState: (clear?: boolean) => Promise<IAuthenticationResult>;
  signOut: () => Promise<boolean>;

  linkAccounts: (
    sessionToken: string,
    linkParams: AccountLinkingRequestData,
  ) => Promise<string>;

  initiatePasswordReset: (email: string) => Promise<string[]>;
  resetPassword: (
    email: string,
    password: string,
    token: string,
  ) => Promise<IAuthenticationResult>;
}

export const getCredentialOS = () => {
  let credentialOS: EnumAuthenticatorDeviceCredentialOs;

  if (isWindows) {
    credentialOS = EnumAuthenticatorDeviceCredentialOs.Windows;
  } else if (isMacOs) {
    credentialOS = EnumAuthenticatorDeviceCredentialOs.Macos;
  } else if (isIOS) {
    credentialOS = EnumAuthenticatorDeviceCredentialOs.Ios;
  } else if (isAndroid) {
    credentialOS = EnumAuthenticatorDeviceCredentialOs.Android;
  } else {
    credentialOS = EnumAuthenticatorDeviceCredentialOs.None;
  }
  return credentialOS;
};
export const getCredentialBoundTo = () => {
  let credentialBoundTo: EnumAuthenticatorDeviceCredentialBoundTo;

  if (isEdge) {
    credentialBoundTo = EnumAuthenticatorDeviceCredentialBoundTo.Edge;
  } else if (isFirefox) {
    credentialBoundTo = EnumAuthenticatorDeviceCredentialBoundTo.Firefox;
  } else if (isIE) {
    credentialBoundTo = EnumAuthenticatorDeviceCredentialBoundTo.Ie;
  } else if (isChrome) {
    credentialBoundTo = EnumAuthenticatorDeviceCredentialBoundTo.Chrome;
  } else if (isSafari) {
    credentialBoundTo = EnumAuthenticatorDeviceCredentialBoundTo.Safari;
  } else {
    credentialBoundTo = EnumAuthenticatorDeviceCredentialBoundTo.None;
  }
  return credentialBoundTo;
};

export interface IAuthenticationResult {
  context?: {
    display?: string;
    challenge?: string;
  };
  alternateFactors?: AuthenticationProfileFactors[];
  factor?: AuthenticationProfileFactors;
  user?: AuthorizedUser;
  message?: {
    severity: number;
    message: string;
  };
  reset?: boolean;
  alternate?: boolean;
}

const ObserveCurrentUser = ({
  user,
  handleChange,
}: {
  user: AuthorizedUser;
  handleChange: (updatedUser: AuthorizedUser) => void;
}) => {
  useCurrentUserDetailSubscription({
    variables: { userId: user._id || '0' },
    fetchPolicy: 'no-cache',
    onData: async (data) => {
      // TODO: Peter: test this back as we have now added the await here to satisfy typescript (and, we should have in the first place)
      //  but we should test this to be sure it still works (no reason to think it won't)
      await updateCacheFromSubscriptionEvent(data);
      const newUserData = data.data.data?.userEventsByIds;
      if (newUserData) {
        handleChange(
          // Update any fields that may be edited while the user is logged in
          new AuthorizedUser({
            ...user,
            name: {
              firstName: null,
              middleName: null,
              lastName: newUserData.name ?? user.name.lastName,
            },
            preferredUnits: newUserData.preferredUnits,
            preferredAirFlowUnits: newUserData.preferredAirFlowUnits,
          }),
        );
      }
    },
  });
  return null;
};

/**
 *  Create the initial authentication context with default values for all
 *  attributes and API methods (replaced by real code/values later in the code).
 */
const AuthenticationContext = createContext<IAuthenticator>({
  activeSession: false,
  expirationWarning: undefined,
  sessionExpiresAt: undefined,
  user: undefined,
  userName: undefined,

  sessionExpired: undefined,

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  authenticate: (_request: IAuthenticationRequest) => {
    return Promise.resolve();
  },
  extendSessionTime: () => Promise.resolve(),
  getPasskeyCreationOptions: () => Promise.resolve(),
  getPasskeyAuthenticationOptions: () => Promise.resolve(),
  validatePasskeyAuthentication: () => Promise.resolve(),
  resetAuthState: () => Promise.resolve({}),
  hasActiveSession: () => false,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  initiatePasswordReset: (_email: string) => Promise.resolve([]),
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  resetPassword: (_email: string, _password: string, _token: string) =>
    Promise.resolve({}),
  signOut: () => Promise.resolve(false),
  linkAccounts: () => Promise.resolve(''),
});

// Provides easier identification of the context in the browser debugger.
AuthenticationContext.displayName = 'AuthenticationContext';

/**
 * Create the context provider for the AuthenticationContext, providing the
 * real implementation of the context's API methods and real (live) state values.
 * @param children: The children nodes to render inside the context provider.
 */
export const AuthenticationProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////
  //
  // Access system services / contexts used by the Authentication Context.
  //
  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  const SessionStateManager = useSessionState();
  const { subscribeToCommands } = useSystemCommands();
  const { addresses, refreshConnection, connected } = useSystemConnection();
  const { clear } = useSystemHistory();

  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////
  //
  // Set up local state for the Authentication Context.
  //
  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  // user holds the currently logged in / authenticated and authorized user.
  const [user, setUser] = useState<AuthorizedUser | undefined>(
    LocalState.getItem<AuthorizedUser>(LOCAL_AUTH_USER_KEY),
  );
  const [userName, setUserName] = useState<string>('Not logged in');
  const [expirationWarning, setExpirationWarning] = useState<
    boolean | undefined
  >(undefined);
  // sessionExpiresAt holds the date/time at which the current session expires.
  const [sessionExpiresAt, setSessionExpiresAt] = useState<Date | undefined>(
    new Date(
      (LocalState.getItem<IServerToken>(AUTH_SESSION_EXPIRATION_TOKEN_NAME)
        ?.value as number) ?? 0,
    ),
  );
  // sessionExpired is true if the current session has already expired.
  const [sessionExpired, setSessionExpired] = useState<boolean | undefined>(
    (LocalState.getItem<IServerToken>(AUTH_SESSION_EXPIRATION_TOKEN_NAME)
      ?.value ?? 0) < Date.now(),
  );
  // sessionTimeoutTimer holds a handle to the session timeout timer instance.
  const [sessionTimeoutTimer, setSessionTimeoutTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();
  // sessionWarningTimer holds a handle to the session warning timer instance.
  const [sessionWarningTimer, setSessionWarningTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();
  // hasValidSession: Helper method which returns true if there is a valid,
  //  authorized user and if the session has not expired yet.
  const hasValidSession = useCallback(() => {
    return (
      !!user &&
      sessionExpiresAt !== undefined &&
      sessionExpiresAt.valueOf() - Date.now() > 0
    );
  }, [sessionExpiresAt, user]);

  // TODO: Refactoring: Experiment: Peter: perhaps remove the use of 'activeSession'
  //  as a variable within this file and only export it for use elsewhere.
  //  Seems like we could just use 'hasValidSession()' in its place within this file.
  //
  // activeSession is true if there is an active session, falsy otherwise.
  const [activeSession, setActiveSession] =
    useState<boolean>(hasValidSession());

  // TODO: Feature: We should put up a 'delayed toast' during the login process
  //  ... as it seems to sometimes take a while to log in ...
  //  and then dismiss that toast once we get the server response.
  // signIn is a helper method for internal use only which, when called, attempts to log the user in.
  const signIn = useCallback(
    (userLike: AuthorizedUser): void => {
      const user: AuthorizedUser = new AuthorizedUser(userLike);
      setUser(user);
      refreshConnection();
    },
    [refreshConnection],
  );

  const {
    data: userData,
    // TODO: Loading_error: handle loading/error for the user data.
    // error: userDataError,
    // loading: userDataLoading,
  } = useUserDetailQuery({ variables: { userId: user?._id ?? '' } });

  useEffect(() => {
    if (userData?.userById) {
      const myUser: Partial<User> = userData.userById as Partial<User>;
      setUserName(`${myUser.name ?? 'Not logged in'}`);
    } else if (user) {
      setUserName(
        `${user.name ?? 'Not logged in'}${
          user.email ? ' (' + user.email + ')' : ''
        }`,
      );
    } else {
      setUserName('Not logged in');
    }
  }, [user, userData]);

  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////
  //
  // Action API methods shared through the Authentication Context.
  //
  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  // hasActiveSession returns true if there is an active session.
  const hasActiveSession = useCallback((): boolean => {
    return !!user && activeSession;
  }, [user, activeSession]);

  // authenticate provides the implementation of the main method used by the
  // authenticate user MFA workflow.
  const authenticate = (
    request: IAuthenticationRequest,
    requestAlternate = false,
  ): Promise<IAuthenticationResult | void> => {
    return new Promise<IAuthenticationResult | void>((s, f) => {
      if (!LocalState.itemExists(LOCAL_AUTH_USER_KEY)) {
        LocalState.transitionConnectionState();
        const headers = getAuthHeaders(
          {
            'Content-Type': 'application/json',
          },
          [
            AUTH_REMEMBER_ME_TOKEN_NAME,
            AUTH_ID_TOKEN_NAME,
            AUTH_STATE_TOKEN_NAME,
            AUTH_SESSION_TOKEN_NAME,
          ],
        );

        axios
          .post(
            '/auth/users/authenticate',
            {
              request: request,
              environment: {
                requestAlternate,
                client: getCredentialBoundTo(),
                os: getCredentialOS(),
              },
            },
            {
              headers,
            },
          )
          .then((results) => {
            const res = results.data;
            res.alternate = requestAlternate;

            if (res.user && !request.smart) {
              const user = EJSON.parse(res.user) as AuthorizedUser;
              signIn(user);
            }
            s(res as unknown as IAuthenticationResult);
          })
          .catch((authenticateError) => {
            const message = `[Authenticate 1] Failed to authenticate user: ${authenticateError.message}.`;
            f(new EmbueError(message));
          });
      } else if (
        LocalState.itemExists(LOCAL_AUTH_USER_KEY) &&
        SessionStateManager[AUTH_SESSION_TOKEN_NAME] &&
        SessionStateManager[AUTH_SESSION_EXPIRATION_TOKEN_NAME] &&
        SessionStateManager[AUTH_SESSION_EXPIRATION_TOKEN_NAME].valueOf() -
          Date.now() >
          0
      ) {
        s();
      } else if (
        LocalState.itemExists(LOCAL_AUTH_USER_KEY) ||
        SessionStateManager[AUTH_SESSION_TOKEN_NAME] ||
        SessionStateManager[AUTH_SESSION_EXPIRATION_TOKEN_NAME]
      ) {
        signOut().then(() => {
          const headers = getAuthHeaders(
            {
              'Content-Type': 'application/json',
            },
            [
              AUTH_REMEMBER_ME_TOKEN_NAME,
              AUTH_ID_TOKEN_NAME,
              AUTH_STATE_TOKEN_NAME,
              AUTH_SESSION_TOKEN_NAME,
            ],
          );

          axios
            .post(
              '/auth/users/authenticate',
              {
                request: request,
                environment: {
                  client: getCredentialBoundTo(),
                  os: getCredentialOS(),
                },
              },
              {
                headers: headers,
              },
            )
            .then((results) => {
              const res = results.data;

              if (res.user) {
                const user = EJSON.parse(res.user) as AuthorizedUser;
                signIn(user);
              }
              s(res as unknown as IAuthenticationResult);
            })
            .catch((authenticateError) => {
              const message = `[Authenticate 2] Failed to authenticate user: ${authenticateError.message}.`;
              f(new EmbueError(message));
            });
        });
      } else {
        f('UNKNOWN PROBLEM');
      }
    });
  };

  // extendSession allows the user to extend the current session, resetting the
  //    session timeout and warning timers to their default values.
  const extendSessionTime = (): Promise<void> => {
    return new Promise<void>((s, f) => {
      const headers: Record<string, string> = getAuthHeaders(
        {
          'Content-Type': 'application/json',
        },
        [AUTH_SESSION_EXPIRATION_TOKEN_NAME, AUTH_SESSION_TOKEN_NAME],
      );

      axios
        .post(
          '/auth/sessions/extend',
          {},
          {
            headers,
          },
        )
        .then(() => s())
        .catch((extendError) => {
          f(
            new EmbueError('[Extend Session] Unable to extend session.', {
              extendError,
            }),
          );
        });
    });
  };

  // extendSession allows the user to extend the current session, resetting the
  //    session timeout and warning timers to their default values.
  const getPasskeyCreationOptions = (
    external: boolean,
    enforceUserVerification: boolean,
  ): Promise<PublicKeyCredentialCreationOptionsJSON> => {
    return new Promise<PublicKeyCredentialCreationOptionsJSON>((s, f) => {
      const headers: Record<string, string> = getAuthHeaders(
        {
          'Content-Type': 'application/json',
        },
        [AUTH_SESSION_EXPIRATION_TOKEN_NAME, AUTH_SESSION_TOKEN_NAME],
      );

      const credentialOS: EnumAuthenticatorDeviceCredentialOs =
        getCredentialOS();
      const credentialBoundTo: EnumAuthenticatorDeviceCredentialBoundTo =
        getCredentialBoundTo();

      axios
        .post<{ registrationOptions: PublicKeyCredentialCreationOptionsJSON }>(
          '/generate-registration-options',
          {
            external,
            enforceUserVerification,
            credentialOS,
            credentialBoundTo,
          },
          {
            headers,
          },
        )
        .then(({ data }) => s(data?.registrationOptions))
        .catch((extendError) => {
          f(
            new EmbueError(
              '[Create Passkey Options] Unable to generate passkey creation options.',
              {
                extendError,
              },
            ),
          );
        });
    });
  };

  // extendSession allows the user to extend the current session, resetting the
  //    session timeout and warning timers to their default values.
  const getPasskeyAuthenticationOptions =
    (): Promise<PublicKeyCredentialRequestOptionsJSON> => {
      return new Promise<PublicKeyCredentialRequestOptionsJSON>((s, f) => {
        const headers: Record<string, string> = getAuthHeaders(
          {
            'Content-Type': 'application/json',
          },
          [
            AUTH_REMEMBER_ME_TOKEN_NAME,
            AUTH_ID_TOKEN_NAME,
            AUTH_STATE_TOKEN_NAME,
            AUTH_SESSION_TOKEN_NAME,
          ],
        );

        axios
          .post<{
            authenticationXOptions: PublicKeyCredentialRequestOptionsJSON;
          }>(
            '/generate-authentication-options',
            {
              credentialOS: getCredentialOS(),
              credentialBoundTo: getCredentialBoundTo(),
            },
            {
              headers,
            },
          )
          .then(({ data }) => s(data?.authenticationXOptions))
          .catch((authOptionsError) => {
            f(
              new EmbueError(
                '[Authenticate Passkey Options] Unable to generate passkey authentication options.',
                {
                  authOptionsError,
                },
              ),
            );
          });
      });
    };

  // extendSession allows the user to extend the current session, resetting the
  //    session timeout and warning timers to their default values.
  const validatePasskeyAuthentication = (
    res: AuthenticationResponseJSON,
  ): Promise<VerifiedAuthenticationResponse> => {
    return new Promise<VerifiedAuthenticationResponse>((s, f) => {
      const headers: Record<string, string> = getAuthHeaders(
        {
          'Content-Type': 'application/json',
        },
        [AUTH_SESSION_EXPIRATION_TOKEN_NAME, AUTH_SESSION_TOKEN_NAME],
      );

      axios
        .post<{ verified: VerifiedAuthenticationResponse }>(
          '/verify-authentication',
          res,
          {
            headers,
          },
        )
        .then(({ data }) => {
          s(data?.verified);
        })
        .catch((extendError) => {
          f(
            new EmbueError(
              '[Create Passkey Options] Unable to generate passkey creation options.',
              {
                extendError,
              },
            ),
          );
        });
    });
  };

  // resetAuthState clears the current session and resets the session tokens to
  //   their default values. Optionally, if clear is true, this will also clear
  //   the endpoint ID for the current 'device' or endpoint if one has been set.
  const resetAuthState = (clear = false): Promise<IAuthenticationResult> => {
    LocalState.removeItems([AUTH_STATE_TOKEN_NAME, AUTH_ID_TOKEN_NAME]);
    return new Promise((s, f) => {
      axios
        .post(
          '/auth/users/resetAuthState',
          { clear },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          // TODO: Loading_error: We should probably put in an interceptor for all axios commands which checks for non-200 responses?
          authenticate({})
            .then((res) => {
              s(res as unknown as IAuthenticationResult);
            })
            .catch((authError) => {
              const message = `[Reset Authenticate 1] Failed to authenticate user: ${authError.message}.`;
              f(new EmbueError(message));
            });
        })
        .catch((resetError) => {
          const message = `[Reset Authenticate 2] Failed to authenticate user: ${resetError.message}.`;
          f(new EmbueError(message));
        });
    });
  };

  // initiatePasswordReset allows the user to initiate the password reset workflow.
  const initiatePasswordReset = (email: string): Promise<string[]> => {
    return new Promise((s, f) => {
      const payload = { email: email };
      return axios
        .post<string[]>('/auth/passwords/reset/initiate', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          s(res.data);
        })
        .catch((resetError) => {
          f(new EmbueError(resetError.response.data.message));
        });
    });
  };

  // TODO: Peter: Follow this through to see what happens when this fails. If we
  //  return a error string in the response.data.message field of the error object,
  //  we need to deal with that differently if we want to use an async version of this.
  // const initiatePasswordReset = async (email: string): Promise<string[]> => {
  //   try {
  //     const payload = { email: email };
  //     const response = await axios.post<string[]>(
  //       '/auth/passwords/reset/initiate',
  //       payload,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       },
  //     );
  //     return response.data;
  //   } catch (resetError) {
  //     throw new EmbueError(resetError.response.data.message);
  //   }
  // };

  // resetPassword concludes the reset their password workflow by storing the new,
  // user-supplied password, assuming it conforms to the enforced complexity rules.
  const resetPassword = (
    email: string,
    password: string,
    token: string,
  ): Promise<IAuthenticationResult> => {
    return new Promise((s, f) => {
      const payload = { email, password: SHA256(password), token };

      LocalState.transitionConnectionState();

      axios
        .post('/auth/passwords/reset', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(({ data }) => {
          const res = data;
          if (res.user) {
            const user = EJSON.parse(res.user) as AuthorizedUser;
            signIn(user);
          }
          // TODO: Peter: The lines above indicate that the value coming back has a 'user' attribute but that it is not actually an AuthorizedUser
          //  object, per se, but a JSON representation of it. We should probably do the following to honor the contact of the function:
          //   s({ ...res, user: EJSON.parse(res.user) as AuthorizedUser } as IAuthenticationResult);
          s(res);
        })
        .catch((resetError) => {
          const message = `[Password Reset] Request processing failed: ${resetError.response.data.message}.`;
          f(new EmbueError(message));
        });
    });
  };

  // signOut logs the current user/session out and clears the server tokens associated
  //   with the current session and user. It does not clear the endpoint ID token.
  const signOut = useCallback(
    (logoutMessage = 'You have been logged out.'): Promise<boolean> => {
      // TODO: Security: Peter: Should we clear the ApolloClient cache when logging out???

      return new Promise((s, f) => {
        const currentlyLoggedIn = LocalState.itemExists(LOCAL_AUTH_USER_KEY);
        Log.silly(
          'executing logout',
          { currentlyLoggedIn, connected },
          LogCategory.TIMEOUT,
        );

        LocalState.removeItems([
          AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
          AUTH_SESSION_TOKEN_NAME,
          AUTH_SESSION_EXPIRATION_TOKEN_NAME,
        ]);

        LocalState.transitionConnectionState();

        const headers: Record<string, string> = getAuthHeaders(
          {
            'Content-Type': 'application/json',
          },
          [AUTH_SESSION_TOKEN_NAME],
          true,
        );

        axios
          .post(
            '/auth/users/logout',
            {},
            {
              headers,
            },
          )
          .then((res) => {
            if (res.status !== 200) {
              Log.error(
                `[Logout] Logout response indicated a failure: ${res.status}`,
              );
            }

            clear();
            setTimeout(() => {
              if (currentlyLoggedIn) {
                Notifier.info(logoutMessage, {}, { autoClose: 2000 });
                if (user) {
                  setUser(undefined);
                }
              }
              refreshConnection();

              s(currentlyLoggedIn);
            });
          })
          .catch((logoutError) => {
            f(
              new EmbueError(
                `[Logout] Unable to remove session: ${logoutError.message}.`,
              ),
            );
          });
      });
    },
    [SessionStateManager, user, refreshConnection],
  );

  // linkAccounts takes the supplied authorized user token along with the linking info
  // provided by the account linking request and, on the server, issues an authorization
  // code for use by amazon to redeem it for an authorization token.
  const linkAccounts = useCallback(
    (
      sessionToken: string,
      linkParams: AccountLinkingRequestData,
    ): Promise<string> => {
      return new Promise((s, f) => {
        Log.silly('Linking accounts', { linkParams }, LogCategory.TOKENS);

        try {
          axios
            .post(
              '/smart/link-accounts',
              {
                sessionToken,
                linkParams,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((res) => {
              if (res.status !== 200) {
                Log.error(
                  `[Account Linking] Attempt response indicated a failure: ${res.status}`,
                );
                f('Account linking failed');
              } else {
                s(res.data as string);
              }
            })
            .catch((linkError) => {
              Log.error(
                `[Account Linking] Unable to link accounts: ${linkError.message}`,
              );
              f(new EmbueError('Error Linking accounts.'));
            });
        } catch (postError) {
          Log.error(JSON.stringify(postError));
          f('Error linking accounts.');
        }
      });
    },
    // TODO: Peter: since this takes no dependencies, do we even need to use a callback here?
    [],
  );

  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////
  //
  // Monitors, handlers and controllers
  //
  //////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////

  // Defines and registers system-wide hooks on the axios HTTP wrapper
  // to dynamically set the server host address (to 'fix' CORS issues)
  // and to auto-update the tokens whenever a response from the server
  // is received via an axios-brokered API call to the server.
  useEffect(() => {
    const service = new Service(axios);

    service.register({
      onRequest(config) {
        config.baseURL = addresses?.serverUrl;
        return config;
      },
      // TODO: Peter: should we 'hook' this callback as well?
      onResponse(response) {
        Object.keys(TokenFactoryMap).forEach((key) => {
          const newVal = (response.headers ?? {})[key];
          if (newVal) {
            const token = EJSON.parse(newVal);
            if (token.remove) {
              LocalState.removeItem(key);
            } else {
              LocalState.setItem(key, token);
            }
          }
        });

        SessionStateManager.updateTokens();
        return response;
      },
    });
    // Note that we are ignoring the rules of hooks here as this *should* have
    // 'SessionStateManager' as a dependency but, as it is only using the updateTokens
    // member of that context which is simply a function and doesn't change from
    // one context instance/version to the next, there is no danger by not including
    // it here.
  }, []);

  // Monitors and updates the current session expiration date shared via the context
  useEffect(() => {
    setSessionExpiresAt(
      new Date(
        SessionStateManager[AUTH_SESSION_EXPIRATION_TOKEN_NAME] as number,
      ),
    );
  }, [SessionStateManager]);

  // Monitors and sets the 'active session' flag for publishing via the context.

  useEffect(() => {
    setActiveSession(hasValidSession());
  }, [user, sessionExpiresAt, setActiveSession]);

  // Monitors and caches the current user in system storage.
  useEffect(() => {
    if (user) {
      LocalState.setItem(LOCAL_AUTH_USER_KEY, user);
    } else {
      LocalState.removeItem(LOCAL_AUTH_USER_KEY);
    }
  }, [user]);

  // TODO: Peter: possibly collapse this into the 'useEffect' that utilizes it?
  const triggerSessionExpired = useCallback(() => {
    Notifier.warn(
      'Session Expired.<br />Please log in again.',
      {},
      { autoClose: 4000, closeOnClick: true },
    );
    setSessionTimeoutTimer(undefined);
    setSessionExpired(true);
  }, []);

  const triggerSessionWarning = useCallback(() => {
    setSessionWarningTimer(undefined);
    setExpirationWarning(true);
  }, []);

  // Session expiration monitoring and auto-logout handling.
  useEffect(() => {
    let expiresIn: number | undefined = undefined;
    let warningIn: number | undefined = undefined;

    if (sessionExpiresAt !== undefined) {
      const now = Date.now();
      expiresIn = sessionExpiresAt.valueOf() - now;
      warningIn = expiresIn - AUTH_SESSION_WARNING_MINUTES * 60 * 1000;
    }

    if (sessionTimeoutTimer) {
      clearTimeout(sessionTimeoutTimer);
      setSessionTimeoutTimer(undefined);
    }

    if (sessionWarningTimer) {
      clearTimeout(sessionWarningTimer);
      setSessionWarningTimer(undefined);
    }

    setSessionExpired(expiresIn === undefined ? undefined : expiresIn <= 0);
    setExpirationWarning(warningIn === undefined ? undefined : warningIn <= 0);

    let newExpirationTimer: ReturnType<typeof setTimeout> | undefined =
      undefined;
    let newWarningTimer: ReturnType<typeof setTimeout> | undefined = undefined;

    if (expiresIn !== undefined) {
      Log.silly('expiresIn is NOT undefined', expiresIn, LogCategory.TIMEOUT);
      if (expiresIn > 0) {
        if (activeSession) {
          newExpirationTimer = setTimeout(() => {
            triggerSessionExpired();
          }, expiresIn);
          setSessionTimeoutTimer(newExpirationTimer);

          if (warningIn !== undefined) {
            if (warningIn > 0) {
              setExpirationWarning(false);
              Log.silly(
                'setting warning timer to',
                warningIn,
                LogCategory.TIMEOUT,
              );
              newWarningTimer = setTimeout(() => {
                triggerSessionWarning();
              }, warningIn);
              setSessionWarningTimer(newWarningTimer);
            } else {
              triggerSessionWarning();
            }
          } else {
            // no session token present ... this case is covered by the case when the expiresIn is undefined.
          }
        }

        return () => {
          Log.silly(
            'clearing session and warning timers',
            null,
            LogCategory.TIMEOUT,
          );
          clearTimeout(newExpirationTimer);
          clearTimeout(newWarningTimer);

          if (sessionTimeoutTimer) {
            clearTimeout(sessionTimeoutTimer);
            setSessionTimeoutTimer(undefined);
          }
          if (sessionWarningTimer) {
            clearTimeout(sessionWarningTimer);
            setSessionWarningTimer(undefined);
          }
        };
      } else {
        // TODO: Peter: Currently a no-op but ... should we do the stuff below? --- also, adding activeSession as a dependency may be hurting us
        // triggerSessionExpired();
        // triggerSessionWarning();
      }
    } else {
      // no session token present.
      // TODO: Peter: should we do something here?
      Log.silly('expiresIn is undefined', null, LogCategory.TIMEOUT);
    }
  }, [sessionExpiresAt, activeSession, SessionStateManager]);

  // Monitor for and react to any incoming system commands.
  subscribeToCommands('authCommands', (command: SystemCommand) => {
    Notifier.info(
      `Got Command! ${
        command ? command.message ?? 'No message' : 'Blank command'
      }`,
    );
  });

  // Monitor the current active user and update pendo anytime it changes
  const pendo = usePendoClient();
  useEffect(() => {
    if (pendo) {
      if (user) {
        // User authenticated - identify them in pendo
        pendo.identify({
          visitor: {
            id: user._id,
            email: user.email,
            ...user.name,
            isSuper: user.isSuper,
            isManager: user.isManager,
            isInstaller: user.isInstaller,
            isResident: user.isResident,
            isViewer: user.isViewer,
          },
        });
      } else {
        // No authenticated user - register an anonymous visitor
        pendo.identify({
          visitor: {
            id: 'VISITOR-UNIQUE-ID',
          },
        });
      }
    }
  }, [user, pendo]);

  // TODO: Peter: should we memoize this?
  // const value = useMemo(
  //   (): IAuthenticator => ({
  //     activeSession,
  //       expirationWarning,
  //       sessionExpired,
  //       sessionExpiresAt,
  //       user,
  //
  //       hasActiveSession,
  //       initiatePasswordReset,
  //       resetPassword,
  //       signOut,
  //   }),
  //   [user],
  // );

  const value: IAuthenticator = {
    activeSession,
    expirationWarning,
    sessionExpired,
    sessionExpiresAt,
    user,
    userName,

    authenticate,
    extendSessionTime,
    getPasskeyCreationOptions,
    getPasskeyAuthenticationOptions,
    validatePasskeyAuthentication,
    resetAuthState,
    hasActiveSession,
    initiatePasswordReset,
    resetPassword,
    signOut,
    linkAccounts,
  };

  Log.silly('rendering authentication context', null, LogCategory.RENDERING);

  return (
    <AuthenticationContext.Provider value={value}>
      {value.user ? (
        <ObserveCurrentUser
          user={value.user}
          handleChange={(updatedUser) => setUser(updatedUser)}
        />
      ) : null}
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticator = (): IAuthenticator => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthenticationProvider');
  }
  return context;
};
