import React, { useEffect } from 'react';
import {
  Dialog,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  Grid,
  FormControlLabel,
  Checkbox,
  useTheme,
  capitalize,
} from '@mui/material';
import { ApolloError } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useAuthenticator } from '../../../../../auth/AuthenticationContext';
import { ThermostatScheduleTemplateRowData } from './thermostat-schedule';
import { VerifyPropertyUnitThermostatScheduleUpdate } from '../../../../shared/verify-property-unit-thermostat-schedule-update';
import BorderedSection from '../../../../shared/borderedSection';
import { Notifier } from '../../../../../system/services/notificationManager';
import { useThermostatScheduleTemplateUpdateVerificationQuery } from '../../../../../../types/generated-types';
import { ThermostatScheduleStatistics } from '../../types/thermostat-schedule-statistics';
import { ThermostatScheduleUpdateStatistics } from '../../types/thermostat-schedule-update-statistics';

interface ThermostatScheduleVerificationDialogProps {
  open: boolean;
  action: string;
  propertyId?: string;
  unitId?: string;
  forceUpdate: boolean;
  template: ThermostatScheduleTemplateRowData;
  handleClose: () => void;
  handleSubmit: (submitAction: string) => void;
  setForceUpdate: (forceUpdate: boolean) => void;
  cancelForceUpdate: () => void;
  handleOpenPropagationStatusView?: () => void;
  level: string;
}

export function ThermostatScheduleVerificationDialog(
  props: ThermostatScheduleVerificationDialogProps,
) {
  const {
    open,
    action,
    unitId,
    template,
    propertyId,
    forceUpdate,
    handleClose,
    handleSubmit,
    setForceUpdate,
    handleOpenPropagationStatusView,
    cancelForceUpdate,
    level,
  } = props;

  const { user } = useAuthenticator();
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();

  const [scheduleStats, setScheduleStats] =
    React.useState<ThermostatScheduleStatistics>();
  const [
    thermostatScheduleUpdateStatistics,
    setThermostatScheduleUpdateStatistics,
  ] = React.useState<ThermostatScheduleUpdateStatistics>();
  const [queryError, setQueryError] = React.useState<ApolloError>();

  const {
    data: scheduleStatsData,
    loading: scheduleStatsDataLoading,
    refetch: refetchThermostatScheduleTemplateUpdateVerification,
  } = useThermostatScheduleTemplateUpdateVerificationQuery({
    variables: {
      unitId: unitId ?? '',
      propertyId: id,
    },
    fetchPolicy: 'network-only',
    onError(error) {
      console.error(error);
      setQueryError(error);
      Notifier.error(
        'An error occurred requesting verification data. Please retry.',
      );
    },
  });

  useEffect(() => {
    if (scheduleStatsData?.thermostatScheduleTemplateUpdateVerification) {
      const queryData: ThermostatScheduleUpdateStatistics = {
        netThermostatsToBeUpdated: [],
        thermostatsUsingCompatibleScheduleTemplate: [],
        thermostatsUsingIncompatibleScheduleTemplate: [],
      };
      switch (level) {
        case 'unit':
          switch (action) {
            case 'remove':
              queryData.netThermostatsToBeUpdated = forceUpdate
                ? scheduleStatsData.thermostatScheduleTemplateUpdateVerification
                    .installedOnlineThermostats
                : scheduleStatsData.thermostatScheduleTemplateUpdateVerification
                    .thermostatsUsingUnitLevelScheduleTemplate;
              break;
            case 'apply':
              queryData.netThermostatsToBeUpdated = forceUpdate
                ? scheduleStatsData
                    ?.thermostatScheduleTemplateUpdateVerification
                    .installedOnlineThermostats
                : scheduleStatsData?.thermostatScheduleTemplateUpdateVerification.installedOnlineThermostats
                    .filter((x) => {
                      return !scheduleStatsData?.thermostatScheduleTemplateUpdateVerification?.installedOnlineThermostatsAndUsesCustomSchedule.find(
                        (y) => {
                          return x === y;
                        },
                      );
                    })
                    .filter((g) => {
                      return !scheduleStatsData?.thermostatScheduleTemplateUpdateVerification?.installedOnlineThermostatsAndUsesScheduleTemplate
                        .map((thermostat) => thermostat?._id)
                        .find((h) => {
                          return g === h;
                        });
                    });
              break;
          }

          break;
        case 'property':
          switch (action) {
            case 'remove':
              queryData.netThermostatsToBeUpdated = forceUpdate
                ? scheduleStatsData.thermostatScheduleTemplateUpdateVerification
                    .installedOnlineThermostats
                : scheduleStatsData.thermostatScheduleTemplateUpdateVerification
                    .thermostatsUsingPropertyLevelScheduleTemplate;
              break;
            case 'apply':
              queryData.netThermostatsToBeUpdated = forceUpdate
                ? scheduleStatsData.thermostatScheduleTemplateUpdateVerification
                    .installedOnlineThermostats
                : scheduleStatsData.thermostatScheduleTemplateUpdateVerification.installedOnlineThermostats
                    .filter((x) => {
                      return !scheduleStatsData?.thermostatScheduleTemplateUpdateVerification?.installedOnlineThermostatsAndUsesCustomSchedule.find(
                        (y) => {
                          return x === y;
                        },
                      );
                    })
                    .filter((a) => {
                      return !scheduleStatsData?.thermostatScheduleTemplateUpdateVerification?.thermostatsUsingUnitLevelScheduleTemplate.find(
                        (b) => {
                          return a === b;
                        },
                      );
                    })
                    .filter((g) => {
                      return !scheduleStatsData?.thermostatScheduleTemplateUpdateVerification?.installedOnlineThermostatsAndUsesScheduleTemplate
                        .map((thermostat) => thermostat?._id)
                        .find((h) => {
                          return g === h;
                        });
                    });
              break;
          }
          break;
        default:
      }
      setScheduleStats(
        scheduleStatsData.thermostatScheduleTemplateUpdateVerification,
      );
      setThermostatScheduleUpdateStatistics(queryData);
    }
  }, [scheduleStatsData, forceUpdate, action]);

  return (
    <Dialog
      sx={{
        width: '100%',
        '& .MuiDialog-paper': {
          width: '100vw',
          minWidth: '100vw - 20px',
        },
        '& .MuiPaper-root': {
          width: '100vw',
          minWidth: '100vw - 20px',
        },
      }}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {user && (propertyId || unitId) && template ? (
        <>
          <Typography variant="h4" sx={{ padding: '15px' }}>
            {`${
              action.charAt(0).toUpperCase() + action.slice(1)
            } this ${level} level schedule template?`}
          </Typography>
          <DialogContent sx={{ padding: '10px 10px' }}>
            <BorderedSection title="Template Information">
              <Typography>Title: {template?.name ?? 'N/A'}</Typography>
            </BorderedSection>
            {queryError ? (
              <Typography>Error: {queryError.name} </Typography>
            ) : scheduleStatsDataLoading ? (
              <Typography>Data loading ...</Typography>
            ) : scheduleStats ? (
              <VerifyPropertyUnitThermostatScheduleUpdate
                forceUpdate={forceUpdate}
                level={level}
                action={action}
                scheduleStats={scheduleStats}
                thermostatScheduleUpdateStats={
                  thermostatScheduleUpdateStatistics
                }
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Grid container rowSpacing={1} direction={'row'}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={`Force ${capitalize(action)} Schedule Template`}
                  sx={{ paddingLeft: '16px' }}
                  control={
                    <Checkbox
                      color="secondary"
                      onChange={(e) => {
                        setForceUpdate(!forceUpdate);
                      }}
                      checked={forceUpdate}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  color={action === 'apply' ? 'success' : 'error'}
                  sx={{ color: '#fff' }}
                  onClick={() => {
                    if (
                      thermostatScheduleUpdateStatistics?.netThermostatsToBeUpdated
                    ) {
                      if (
                        handleOpenPropagationStatusView &&
                        thermostatScheduleUpdateStatistics
                          .netThermostatsToBeUpdated.length > 0
                      ) {
                        handleOpenPropagationStatusView();
                      }
                    }
                    handleSubmit(action);
                    handleClose();
                  }}
                >
                  {`${action}`}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  sx={{ color: theme.embue.bad }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    cancelForceUpdate();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
}
