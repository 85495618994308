import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import {
  DayOfWeek,
  dayOfWeekLabels,
  dayOfWeekLabelsMobile,
  eventLabels,
  MyScheduleObject,
  MyScheduleSetting,
  MyThermostat,
  TimeOfDay,
  weekdayWeekendLabels,
} from '../../types';
import { WdTab, WdTabs } from './weekday-tabs';
import { ScheduleItem } from './schedule-item';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../../../system/models/temperatureUnits';
import {
  EnumScheduleType,
  Maybe,
  Scalars,
} from '../../../../../../types/generated-types';
import { useAuthenticator } from '../../../../../auth/AuthenticationContext';
import { defaultAbsoluteLimits } from '../../../../helpers';
import { convertToPreferredThermostatSetpointLimitUnits } from '../../../util/convert-to-preferred-thermostat-setpoint-limit-units';
import _ from 'lodash';

interface ScheduleProps {
  schedule?: MyScheduleObject;
  scheduleType?: EnumScheduleType;
  handleChange: (
    operation: 'add' | 'delete' | 'edit_setpoints' | 'edit_time' | 'delete_all',
    dayOfWeek: DayOfWeek,
    timeOfDay: TimeOfDay,
    newTimeOfDay?: TimeOfDay,
    scheduleSetting?: MyScheduleSetting,
  ) => void;
  handleTypeChange: (type: EnumScheduleType) => void;
  temperatureUnit: TemperatureUnit;
  lastModified?: number;
  thermostat: MyThermostat;
}

export function Schedule(props: ScheduleProps) {
  const {
    schedule,
    scheduleType,
    handleChange,
    handleTypeChange,
    temperatureUnit,
    lastModified,
    thermostat,
  } = props;

  const selectedScheduleType = scheduleType ?? EnumScheduleType.SameEveryDay;
  // const [selectedScheduleType, setSelectedScheduleType] = useState(
  //   scheduleType ?? EnumScheduleType.SameEveryDay,
  // );
  const [defaultSchedule, setDefaultSchedule] = useState();
  const [thermostatSetpointLimits, setThermostatSetpointLimits] = useState<{
    maxCoolSetpointLimit: Maybe<Scalars['Float']> | undefined;
    minCoolSetpointLimit: Maybe<Scalars['Float']> | undefined;
    maxHeatSetpointLimit: Maybe<Scalars['Float']> | undefined;
    minHeatSetpointLimit: Maybe<Scalars['Float']> | undefined;
  }>();

  const { user } = useAuthenticator();

  const preferredUnits: TemperatureUnit = user?.preferredUnits ?? Fahrenheit;

  // If the schedule type is weekday-weekend, select Monday (the weekday) by default, otherwise select Sunday
  const [selectedDay, setSelectedDay] = useState(
    selectedScheduleType === EnumScheduleType.WeekdayWeekend
      ? DayOfWeek.MO
      : DayOfWeek.SU,
  );

  useEffect(() => {
    if (thermostat) {
      // Format the thermostat's setpoint limits to preferred units
      const formattedThermostatSetpointLimitValueUnits =
        convertToPreferredThermostatSetpointLimitUnits(
          {
            maxCoolSetpointLimit:
              thermostat?.maxCoolSetpointLimit?.value ?? null,
            minCoolSetpointLimit:
              thermostat?.minCoolSetpointLimit?.value ?? null,
            maxHeatSetpointLimit:
              thermostat?.maxHeatSetpointLimit?.value ?? null,
            minHeatSetpointLimit:
              thermostat?.minHeatSetpointLimit?.value ?? null,
          },
          preferredUnits,
        );

      setThermostatSetpointLimits(formattedThermostatSetpointLimitValueUnits);
    }
  }, [thermostat]);

  useEffect(() => {
    if (!scheduleType) {
      // Set the initial schedule type if the thermostat does not currently have a schedule
      handleTypeChange(selectedScheduleType);
    }
  }, [scheduleType, selectedScheduleType]);

  // useEffect(() => {
  //   if (schedule) {
  //     if (_.isEqual(schedule.value, EmptySchedule)) {
  //       setSelectedScheduleType(EnumScheduleType.None);
  //     }
  //   }
  // }, [schedule]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Get label for the given day of the week, which differs depending on the schedule type
  function getTabLabel(dayOfWeek: DayOfWeek): string {
    const labels = isSmall ? dayOfWeekLabelsMobile : dayOfWeekLabels;
    if (selectedScheduleType === EnumScheduleType.Custom) {
      return labels[dayOfWeek];
    } else if (selectedScheduleType === EnumScheduleType.WeekdayWeekend) {
      return weekdayWeekendLabels[dayOfWeek];
    }
    return '';
  }

  // Get days of the week to display tabs for, depending on the schedule type
  function daysToDisplay(): DayOfWeek[] {
    if (selectedScheduleType === EnumScheduleType.Custom) {
      return Object.values(DayOfWeek);
    } else if (selectedScheduleType === EnumScheduleType.WeekdayWeekend) {
      return [DayOfWeek.MO, DayOfWeek.SU];
    } else {
      return [DayOfWeek.SU];
    }
  }

  const handleDaySelect = (
    event: React.SyntheticEvent,
    newValue: DayOfWeek,
  ) => {
    setSelectedDay(newValue);
  };

  const handleTypeSelect = (type: EnumScheduleType) => {
    handleTypeChange(type);
    // If the new schedule type is weekday-weekend, select Monday (the weekday) by default, otherwise select Sunday
    if (type === EnumScheduleType.WeekdayWeekend) {
      setSelectedDay(DayOfWeek.MO);
    } else {
      setSelectedDay(DayOfWeek.SU);
    }
  };

  const handleDelete = (day: DayOfWeek) => {
    return (time: TimeOfDay) => {
      handleChange('delete', day, time);
    };
  };

  const handleEdit = (day: DayOfWeek) => {
    return (setting: MyScheduleSetting) => {
      return (oldTime: TimeOfDay, newTime?: TimeOfDay) => {
        if (newTime && parseInt(oldTime) - parseInt(newTime) !== 0) {
          handleChange('edit_time', day, oldTime, newTime, { ...setting });
        } else {
          handleChange('edit_setpoints', day, oldTime, undefined, {
            ...setting,
          });
        }
      };
    };
  };

  const dailySchedule = schedule?.value[selectedDay];
  const showTabs = daysToDisplay().length > 1;

  return (
    <>
      <Box sx={{ p: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="schedule-type-label">Schedule Type</InputLabel>
          <Select
            labelId="schedule-type-label"
            id="schedule-type"
            value={selectedScheduleType}
            label="Schedule Type"
            onChange={(e) =>
              handleTypeSelect(e.target.value as EnumScheduleType)
            }
          >
            <MenuItem value={EnumScheduleType.None}>None</MenuItem>
            <MenuItem value={EnumScheduleType.SameEveryDay}>
              Same Every Day
            </MenuItem>
            <MenuItem value={EnumScheduleType.WeekdayWeekend}>
              Weekday, Weekend
            </MenuItem>
            <MenuItem value={EnumScheduleType.Custom}>Custom</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {showTabs ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <WdTabs
            variant="fullWidth"
            value={selectedDay}
            onChange={handleDaySelect}
            sx={{ width: '100%' }}
          >
            {schedule &&
              daysToDisplay().map((dayOfWeek) => {
                return (
                  <WdTab
                    sx={{ minWidth: '45px' }}
                    key={dayOfWeek}
                    value={dayOfWeek}
                    label={getTabLabel(dayOfWeek)}
                  />
                );
              })}
          </WdTabs>
        </div>
      ) : null}
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        dense
      >
        {dailySchedule &&
          Object.entries(dailySchedule).map(
            ([timeOfDay, scheduleSetting], index) => {
              return (
                <Card
                  sx={{ marginBottom: '20px', backgroundColor: '#f5f5f5' }}
                  key={index}
                >
                  <CardHeader
                    title={
                      <Typography sx={{ fontSize: '1rem' }}>
                        {eventLabels[index]}
                      </Typography>
                    }
                  />
                  <ScheduleItem
                    preferredUnits={preferredUnits}
                    key={selectedDay + '_' + timeOfDay}
                    time={timeOfDay}
                    setpointLimitSettings={{
                      maxHeatSetpointLimit:
                        thermostatSetpointLimits?.maxHeatSetpointLimit ??
                        defaultAbsoluteLimits[preferredUnits].heat.max,
                      maxCoolSetpointLimit:
                        thermostatSetpointLimits?.maxCoolSetpointLimit ??
                        defaultAbsoluteLimits[preferredUnits].cool.max,
                      minHeatSetpointLimit:
                        thermostatSetpointLimits?.minHeatSetpointLimit ??
                        defaultAbsoluteLimits[preferredUnits].heat.min,
                      minCoolSetpointLimit:
                        thermostatSetpointLimits?.minCoolSetpointLimit ??
                        defaultAbsoluteLimits[preferredUnits].cool.min,
                    }}
                    setting={scheduleSetting}
                    handleDelete={handleDelete(selectedDay)}
                    handleEdit={handleEdit(selectedDay)}
                  />
                </Card>
              );
            },
          )}
      </List>
      {lastModified && (
        <Box
          sx={{
            fontSize: 12,
            textAlign: 'center',
            color: theme.palette.grey[700],
            pb: 1,
          }}
        >
          Last modified {moment(lastModified).fromNow()}
        </Box>
      )}
    </>
  );
}
