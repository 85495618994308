import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import DataGrid, { type Column, RowsChangeData } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../../system/services/injectableComponentsManager';

import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Fade, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import { Edit } from '@mui/icons-material';

import {
  AlertSubscription,
  AlertSubscriptionSpecification,
  Property,
  useUserProfileAlertSubscriptionsQuery,
  useUserProfileAlertSubscriptionUpdatesSubscription,
  useUpdateUserAlertSubscriptionsMutation,
  useUserProfileAlertChangesSubscription,
} from '../../../../../types/generated-types';

import { FormValues } from '../../types/userProfileFormValues';
import BorderedSection from '../../../shared/borderedSection';
import {
  handleModelUpdateEvent,
  updateCacheFromSubscriptionEvent,
} from '../../../../../helpers/subscriptionUtils';

import { CellExpanderFormatter } from './table-cell-expander-formatter';
import {
  PropertyRow,
  PropertySubscriptionRow,
  SubscriptionPropertyTriggerMap,
  SubscriptionRow,
  SubscriptionTriggerMap,
} from './types';
import { UpdateSubscriptionsDialog } from './update-subscriptions-dialog';
import { AlertDeliveryMethods } from './alert-delivery-methods';
import { AlertSubscriptionGrid } from './alert-subscription-grid';

import './alert-management-tab.css';

function rowKeyGetter(row: PropertyRow | PropertySubscriptionRow) {
  return row.id;
}

export function AlertManagementTab(props: {
  userId: string;
  properties: Partial<Property>[];
  // subscriptions: Array<Maybe<AlertSubscription>>;
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  // const { formik, onChange, userId } = props;
  const [subscriptionTriggerMap, setSubscriptionTriggerMap] =
    React.useState<SubscriptionPropertyTriggerMap>({});
  const [subscriptions, setSubscriptions] = React.useState<
    Partial<AlertSubscription>[]
  >([]);
  const [subscriptionPropertyMap, setSubscriptionPropertyMap] = React.useState<
    Record<string, PropertySubscriptionRow[]>
  >({});
  const [openUpdateSubscriptionDialog, setOpenUpdateSubscriptionDialog] =
    React.useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { setContextMenuItems } = useInjectableComponents();

  const [updateUserAlertSubscriptions] =
    useUpdateUserAlertSubscriptionsMutation();

  const { data: subscriptionData } = useUserProfileAlertSubscriptionsQuery({
    variables: {
      userId: props.userId,
    },
    fetchPolicy: 'network-only',
  });

  useUserProfileAlertChangesSubscription({
    variables: { userId: props.userId ?? '' },
    fetchPolicy: 'no-cache',
    onData: handleModelUpdateEvent((options) => {
      const newOptions = (options.data?.data ?? {}) as any;
      const newData = newOptions ? newOptions.userEventsByIds : null;

      if (newData) {
        props.formik.resetForm({
          values: {
            ...props.formik.values,
            smsOptInStatusTimestamp: newData.smsOptInStatusTimestamp ?? 0,
            smsOptInStatus: newData.smsOptInStatus ?? 'unsent',
            smsOptInConsent: ['pending', 'subscribed'].includes(
              newData.smsOptInStatus,
            ),
            emailOptInStatusTimestamp: newData.emailOptInStatusTimestamp ?? 0,
            emailOptInStatus: newData.emailOptInStatus ?? 'unsent',
          },
        });
      }
    }),
  });

  useUserProfileAlertSubscriptionUpdatesSubscription({
    variables: { userId: props.userId },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (subscriptionData?.alertSubscriptionsByUserId) {
      setSubscriptions(
        subscriptionData.alertSubscriptionsByUserId as Array<
          Partial<AlertSubscription>
        >,
      );
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptions) {
      const subPropMap: SubscriptionPropertyTriggerMap = {};
      for (const sub of subscriptions) {
        if (sub === null || !sub.property?._id || !sub.alertTrigger?._id) {
          break;
        }
        let subMap: SubscriptionTriggerMap = subPropMap[sub.property._id];

        if (!subMap) {
          subMap = {};
          subPropMap[sub.property._id] = subMap;
        }

        subMap[sub.alertTrigger._id] = {
          sms: !!sub.viaSms,
          email: !!sub.viaEmail,
        };
      }
      setSubscriptionTriggerMap(subPropMap);
    }
  }, [subscriptions]);

  useEffect(() => {
    const newSubscriptionPropertyMap: Record<
      string,
      PropertySubscriptionRow[]
    > = {};

    if (subscriptions?.length) {
      for (const sub of subscriptions) {
        if (sub === null || !sub.property?._id || !sub.alertTrigger?._id) {
          break;
        }

        let propSubs: PropertySubscriptionRow[] | undefined =
          newSubscriptionPropertyMap[sub.property._id];

        if (propSubs === undefined) {
          propSubs = [] as PropertySubscriptionRow[];
        }

        propSubs.push({
          id: sub._id,
          name: sub.alertTrigger.name,
          email: sub.viaEmail ? 'subscribed' : '',
          sms: sub.viaSms ? 'subscribed' : '',
        } as PropertySubscriptionRow);

        if (propSubs) {
          newSubscriptionPropertyMap[sub.property._id] = propSubs;
        }
      }
    }

    setSubscriptionPropertyMap(newSubscriptionPropertyMap);
  }, [subscriptions]);

  const updateSubscriptions = async (
    propertyId: string,
    updatedSubscriptionSpecs: SubscriptionRow[],
  ) => {
    const newSubscriptionSpecs: AlertSubscriptionSpecification[] =
      updatedSubscriptionSpecs
        .filter((spec) => spec.email || spec.sms)
        .map((s) => {
          return {
            id: s.id,
            viaSms: s.sms,
            viaEmail: s.email,
          } as AlertSubscriptionSpecification;
        });

    await updateUserAlertSubscriptions({
      variables: {
        userId: props.userId,
        propertyId: propertyId,
        alertSubscriptions: newSubscriptionSpecs,
      },
    });
  };

  useEffect(() => {
    const enableEditing =
      props.formik.values.smsOptInStatus === 'subscribed' ||
      props.formik.values.emailOptInStatus === 'subscribed';
    const bottomMenu: BottomMenuItems = [];
    if (enableEditing) {
      bottomMenu.push({
        items: [
          {
            id: 'menu-item-update-subscriptions',
            label: 'Update Subscriptions',
            icon: <Edit fontSize="small" />,
            action: () => {
              setOpenUpdateSubscriptionDialog(true);
            },
          },
        ],
      });
    }
    setContextMenuItems(bottomMenu.length ? bottomMenu : undefined);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [
    props.formik.values.emailOptInStatus,
    props.formik.values.smsOptInStatus,
    setContextMenuItems,
  ]);

  const [rows, setRows] = useState<PropertyRow[]>([]);
  const [propertyColumns, setPropertyColumns] = useState<Column<PropertyRow>[]>(
    [],
  );

  useEffect(() => {
    const newRows: PropertyRow[] = [];
    if (subscriptionPropertyMap) {
      for (const p of props.properties) {
        const subscriptionCount = (subscriptionPropertyMap[p?._id ?? ''] ?? [])
          .length;
        if (p?._id && subscriptionCount > 0) {
          newRows.push({
            type: 'MASTER',
            id: p._id,
            title: p.title ?? 'Untitled Property',
            expanded: false,
          });
        }
      }
    }
    setRows(newRows);
  }, [props.properties, subscriptionPropertyMap]);

  useEffect(() => {
    setPropertyColumns([
      {
        key: 'expanded',
        name: '',
        minWidth: 30,
        width: 30, // 'max-content',
        colSpan(args) {
          return args.type === 'ROW' && args.row.type === 'DETAIL'
            ? 3
            : undefined;
        },
        cellClass(row) {
          return row.type === 'DETAIL'
            ? isSmall
              ? 'subscribed-alerts-list-expando-expanded-small'
              : 'subscribed-alerts-list-expando-expanded'
            : 'subscribed-alerts-list-expando';
        },
        renderHeaderCell: (_params) => {
          const newValue = !!rows.find(
            (r) => r.type === 'MASTER' && !r.expanded,
          );

          return (
            <div
              onClick={() => {
                const newRows: PropertyRow[] = [];
                let skipRow = false;
                for (const row of rows) {
                  if (!skipRow) {
                    if (row.type === 'MASTER') {
                      if (row.expanded !== newValue) {
                        if (!row.expanded) {
                          newRows.push({ ...row, expanded: true });
                          const height =
                            (subscriptionPropertyMap[row.id]?.length ?? 0) *
                              35 +
                            35 +
                            (isSmall ? 2 : 2);
                          newRows.push({
                            type: 'DETAIL',
                            id: row.id + 100,
                            parentId: row.id,
                            height,
                          });
                        } else {
                          newRows.push({ ...row, expanded: false });
                          skipRow = true;
                        }
                      } else {
                        newRows.push({ ...row });
                      }
                    } else {
                      newRows.push({ ...row });
                    }
                  } else {
                    skipRow = false;
                  }
                }
                setRows(newRows);
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flex: '1 0 100%',
                  height: '44px',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!newValue ? (
                  <UnfoldLessDoubleIcon
                    style={{ fontSize: '25px', color: 'grey' }}
                  />
                ) : (
                  <UnfoldMoreDoubleIcon
                    style={{ fontSize: '25px', color: 'grey' }}
                  />
                )}
              </div>
            </div>
          );
        },
        renderCell({ row, tabIndex, onRowChange }) {
          if (row.type === 'DETAIL') {
            return (
              <div
                style={{
                  width: `calc(100vw - ${isSmall ? 41 : 180}px)`,
                }}
              >
                <AlertSubscriptionGrid
                  parentId={row.parentId}
                  isSmall={isSmall}
                  smsSubscribed={
                    props.formik.values.smsOptInStatus === 'subscribed'
                  }
                  emailSubscribed={
                    props.formik.values.emailOptInStatus === 'subscribed'
                  }
                  propertyRows={rows}
                  subscriptionPropertyMap={subscriptionPropertyMap}
                />
              </div>
            );
          }

          return (
            <CellExpanderFormatter
              expanded={row.expanded}
              tabIndex={tabIndex}
              onCellExpand={() => {
                onRowChange({ ...row, expanded: !row.expanded });
              }}
            />
          );
        },
      },
      { key: 'title', name: 'Property' },
      {
        key: 'subscriptions',
        name: 'Subscriptions',
        width: 120,
        renderCell({ row }) {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: '1 0 100%',
                height: '34px',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {(subscriptionPropertyMap[row.id] ?? []).length}
            </div>
          );
        },
      },
    ]);
  }, [
    subscriptionPropertyMap,
    rows,
    isSmall,
    props.formik.values.smsOptInStatus,
    props.formik.values.emailOptInStatus,
  ]);

  const onRowsChange = useCallback(
    (rows: PropertyRow[], { indexes }: RowsChangeData<PropertyRow>) => {
      const row = rows[indexes[0]];
      if (row.type === 'MASTER') {
        if (row.expanded) {
          const height =
            (subscriptionPropertyMap[row.id]?.length ?? 0) * 35 +
            35 +
            (isSmall ? 2 : 2);
          rows.splice(indexes[0] + 1, 0, {
            type: 'DETAIL',
            id: row.id + 100,
            parentId: row.id,
            height,
          });
        } else {
          rows.splice(indexes[0] + 1, 1);
        }
        setRows(rows);
      }
    },
    [isSmall, subscriptionPropertyMap],
  );

  return props.formik ? (
    <div
      style={{
        border: '1px solid gray',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        margin: '-10px 4px 0px 3px',
        width: isSmall ? 'calc(100vw - 8px)' : 'calc(100vw - 80px)',
        height: '100%',
      }}
    >
      <Fade in={true} style={{ transitionDuration: '1100ms', height: '100%' }}>
        <div
          style={{
            padding: isSmall ? '3px' : '10px',
            border: '1px solid grey',
            borderRadius: '4px',
          }}
        >
          <h2 style={{ marginTop: '-4px', marginBottom: '10px' }}>
            Alert Management
          </h2>
          <AlertDeliveryMethods
            userId={props.userId}
            formik={props.formik}
            alertSubscriptions={subscriptions}
            onChange={props.onChange}
          />
          {props.formik.values.emailOptInStatus === 'subscribed' ||
          props.formik.values.smsOptInStatus === 'subscribed' ? (
            <BorderedSection
              title="Alert Subscriptions"
              style={{
                marginTop: '16px',
                marginBottom: '12px',
              }}
              actionable={
                props.formik.values.smsOptInStatus === 'subscribed' ||
                props.formik.values.emailOptInStatus === 'subscribed'
              }
              actionIcon={<EditIcon style={{ fontSize: '20px' }} />}
              clickAction={() => {
                setOpenUpdateSubscriptionDialog(true);
              }}
              ccStyle={
                isSmall
                  ? {
                      paddingTop: '8px',
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      paddingBottom: '2px',
                    }
                  : { paddingTop: '8px' }
              }
            >
              {subscriptions?.length ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <DataGrid
                    style={{
                      height: `${
                        rows.reduce(
                          (height, row) =>
                            height +
                            (row.type === 'DETAIL'
                              ? row.height + (isSmall ? 20 : 72)
                              : 48),
                          0,
                        ) + 45
                      }px`,
                    }}
                    rowKeyGetter={rowKeyGetter}
                    columns={propertyColumns}
                    rows={rows}
                    onRowsChange={onRowsChange}
                    headerRowHeight={45}
                    rowHeight={(row) =>
                      row.type === 'DETAIL'
                        ? row.height + (isSmall ? 20 : 72)
                        : 45
                    }
                    className="rdg-light fill-grid"
                    enableVirtualization={false}
                    onCellKeyDown={(_, event) => {
                      if (event.isDefaultPrevented()) {
                        // skip parent grid keyboard navigation if nested grid handled it
                        event.preventGridDefault();
                      }
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    marginBottom: '8px',
                    textAlign: 'center',
                  }}
                >
                  Click the pencil icon to subscribe to alerts.
                </div>
              )}
            </BorderedSection>
          ) : (
            <div
              style={{
                width: '100%',
                marginTop: '8px',
                marginBottom: '8px',
                textAlign: 'center',
              }}
            >
              To begin, enable receipt of alerts <br />
              via SMS or Email above
            </div>
          )}
          <UpdateSubscriptionsDialog
            open={openUpdateSubscriptionDialog}
            handleClose={() => setOpenUpdateSubscriptionDialog(false)}
            subscriptions={subscriptionTriggerMap}
            updateSubscriptions={updateSubscriptions}
            properties={props.properties}
            smsEnabled={props.formik.values.smsOptInStatus === 'subscribed'}
            emailEnabled={props.formik.values.emailOptInStatus === 'subscribed'}
          />
        </div>
      </Fade>
    </div>
  ) : null;
}
