import React, { useEffect } from 'react';
import './modbus-air-handler.css';

/* MUI */
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

/* Icons */
import { AirHandlerIcon } from './components/status-icons/air-handler-icon';

/* Sub Components */
import {
  DisplayScreen,
  MenuButtons,
  SystemEdit,
  UpdatePending,
} from './components';

/* Types */
import {
  CurrentAttributes,
  DeviceInfo,
  ModbusAirHandlerComponentProps,
  ModbusAirHandlerUpdateFields,
  MyModbusAirHandler,
  useModbusAirHandlerDetailQuery,
  useModbusAirHandlerDetailUpdateSubscription,
  useUpdateModbusAirHandlerMutation,
} from './types';

/* Lib Imports (component specific functions) */
import { updateInfoBox, updateSubscribedValues } from './lib';

import { Notifier } from '../../../system/services/notificationManager';
import {
  CubicFeetPerMinute,
  Fahrenheit,
  LitersPerSecond,
} from '../../../system/models/temperatureUnits';

import { DeviceInfo as DeviceInfoBox } from '../shared-ui/device-info';
import {
  DeviceAlerts,
  ToggleAlertDetailsButton,
} from '../shared-ui/alerts-info';

import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';

import { BLANK } from '../shared-ui/constants';
import { ModbusAirHandlerUpdateInput } from '../../../../types/generated-types';
import { Avatar } from '@mui/material';
import { convertToAirFlowUnits } from '../util/convert-air-flow-units';
import { useAuthorizer } from '../../../auth/AuthorizationContext';

export enum ViewMode {
  NORMAL = 'normal',
  EDIT_UNCHANGED = 'edit_unchanged',
  EDIT = 'edit',
  UPDATING = 'updating',
  AWAITING = 'awaiting',
  OFFLINE = 'offline',
}

export enum MenuAction {
  EDIT_VALUES = 'edit_values',
  SAVE_CHANGES = 'save_changes',
  REVERT_CHANGES = 'revert_changes',
  CANCEL = 'cancel',
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return (
    <div
      style={{
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        height: '100%',
        display: 'flex',
        padding: expand ? '0px 0px 0px 0px' : '0px 0px 0px 5px',
        margin: expand ? '0px 12px 0px 0px' : '0px 6px 0px 0px',
      }}
    >
      <IconButton
        style={{
          padding: expand ? '0px 6px 0px 3px' : '0px 9px 0px 1px',
          margin: '0px 0px 0px 4px',
        }}
        {...other}
      />
    </div>
  );
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function closestValidInteger(pattern: string, input: number): number {
  const regex = /\[|\(|\]|\)/g;
  const [leftValue, rightValue] = pattern
    .split(regex)
    .filter(Boolean)[0]
    .split(',')
    .filter(Boolean)
    .map((x) => x.trim());
  const [leftBracket, rightBracket] = pattern.match(regex) ?? [];

  const leftNumber = parseInt(leftValue);
  const rightNumber = parseInt(rightValue);

  const isLeftInclusive = leftBracket === '[';
  const isRightInclusive = rightBracket === ']';

  if (isNaN(leftNumber) || isNaN(rightNumber) || leftNumber >= rightNumber) {
    throw new Error('Invalid pattern');
  }

  if (isLeftInclusive && isRightInclusive) {
    if (input < leftNumber) {
      return leftNumber;
    } else if (input > rightNumber) {
      return rightNumber;
    } else {
      return Math.round(input);
    }
  } else if (!isLeftInclusive && isRightInclusive) {
    if (input <= leftNumber) {
      return leftNumber + 1;
    } else if (input > rightNumber) {
      return rightNumber;
    } else {
      return Math.round(input);
    }
  } else if (isLeftInclusive && !isRightInclusive) {
    if (input < leftNumber) {
      return leftNumber;
    } else if (input >= rightNumber) {
      return rightNumber - 1;
    } else {
      return Math.round(input);
    }
  } else if (input <= leftNumber) {
    return leftNumber + 1;
  } else if (input >= rightNumber) {
    return rightNumber - 1;
  } else {
    return Math.round(input);
  }
}

function isWithinLimits(pattern: string, input: number): boolean {
  const regex = /\[|\(|\]|\)/g;
  const [leftValue, rightValue] = pattern
    .split(regex)
    .filter(Boolean)[0]
    .split(',')
    .filter(Boolean)
    .map((x) => x.trim());
  const [leftBracket, rightBracket] = pattern.match(regex) ?? [];

  const leftNumber = parseFloat(leftValue);
  const rightNumber = parseFloat(rightValue);

  const isLeftInclusive = leftBracket === '[';
  const isRightInclusive = rightBracket === ']';

  if (isNaN(leftNumber) || isNaN(rightNumber)) {
    throw new Error('Invalid pattern');
  }

  if (isLeftInclusive && isRightInclusive) {
    return input >= leftNumber && input <= rightNumber;
  } else if (isLeftInclusive && !isRightInclusive) {
    return input >= leftNumber && input < rightNumber;
  } else if (!isLeftInclusive && isRightInclusive) {
    return input > leftNumber && input <= rightNumber;
  } else {
    return input > leftNumber && input < rightNumber;
  }
}

export function ModbusAirHandlerComponent(
  props: ModbusAirHandlerComponentProps,
) {
  const {
    id,
    pairedThermostat,
    preferredUnits = Fahrenheit,
    preferredAirFlowUnits = CubicFeetPerMinute,
  } = props;

  const {
    data,
    loading,
    // TODO: Loading_error: should we handle error here for this query?
    // error,
  } = useModbusAirHandlerDetailQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [updateModbusAirHandler] = useUpdateModbusAirHandlerMutation();

  useModbusAirHandlerDetailUpdateSubscription({
    variables: { ids: [id] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  /* State */
  const [device, setDevice] = React.useState<MyModbusAirHandler>();
  const [rhLimits, setRHLimits] = React.useState<string>('[30, 50]');
  const [freshAirLimits, setFreshAirLimits] =
    React.useState<string>('[10, 85]');
  const [deviceInfo, setDeviceInfo] =
    React.useState<Record<string, DeviceInfo>>();
  const [statusMessage, setStatusMessage] = React.useState(BLANK);
  const [originalValues, setOriginalValues] =
    React.useState<CurrentAttributes>();
  const [newValues, setNewValues] = React.useState<CurrentAttributes>();
  const [viewMode, setViewMode] = React.useState(ViewMode.NORMAL);
  const [expanded, setExpanded] = React.useState(false);
  const [alertsExpanded, setAlertsExpanded] = React.useState(false);
  const [isOffline, setIsOffline] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);

  const { can } = useAuthorizer();
  useEffect(() => {
    if (device) {
      setIsEditable(can('update', device));
    }
  }, [can, device]);

  /* Effects */

  /**
   * Respond to changes of query & subscription 'data', as well as component parameters
   * of 'preferredUnits', 'propertySetpointProfile', 'unitSetpointProfile'.
   *
   * Initialize or Update Device Readings
   * - Create a map of current values that can be edited within this interface.
   * - Apply unit or property level setpoint limit profiles.
   * - Apply preferred temperature units to temperature data.
   * - Initialize schedule information.
   * - Determine the viewmode for the interface based on updates from query data.
   *
   * Initialize or Update Schedule
   * - create a schedule object matching the structure of the incoming schedule data
   * - use a new object and initialize set temperatures using the preferred unit of measure
   * - store the schedule object in local state
   *
   **/
  React.useEffect(() => {
    const myDevice = data && (data.modbusAirHandlerById as MyModbusAirHandler);

    if (myDevice) {
      setDevice(myDevice);
      setRHLimits(myDevice.meta?.metrics?.rhSetpoint?.value ?? '[30, 50]');

      const newValue: string =
        myDevice.meta?.metrics?.freshAirSetpoint?.value ?? '[10, 85]';
      const regex = /\[|\(|\]|\)/g;
      const [leftValue, rightValue] = newValue
        .split(regex)
        .filter(Boolean)[0]
        .split(',')
        .filter(Boolean)
        .map((x: string) => x.trim());
      const [leftBracket, rightBracket] = newValue.match(regex) ?? [];

      const leftNumber = Math.floor(
        convertToAirFlowUnits(
          parseFloat(leftValue as string) ?? 0,
          preferredAirFlowUnits,
        ) ?? 0,
      );
      const rightNumber = Math.ceil(
        convertToAirFlowUnits(
          parseFloat(rightValue as string) ?? 0,
          preferredAirFlowUnits,
        ) ?? 0,
      );
      setFreshAirLimits(
        `${leftBracket}${leftNumber}, ${rightNumber}${rightBracket}`,
      );

      const initialValues = updateSubscribedValues(
        myDevice,
        preferredUnits,
        preferredAirFlowUnits,
      );

      setIsOffline(() => {
        if (myDevice.alertTypes === null || myDevice.alertTypes === undefined) {
          return false;
        } else {
          return (
            myDevice.alertTypes.deviceOffline ||
            myDevice.alertTypes.sourceOffline ||
            false
          );
        }
      });

      if (myDevice.hasPendingUpdates) {
        if (viewMode === ViewMode.UPDATING) {
          setViewMode(ViewMode.AWAITING);
        } else if (viewMode !== ViewMode.AWAITING) {
          Notifier.warn('ModbusAirHandler being updated on another device');
          setViewMode(ViewMode.AWAITING);
          setNewValues(undefined);
        }
      } else {
        if (ViewMode.AWAITING === viewMode) {
          setOriginalValues(initialValues);
          setNewValues(initialValues);
          setTimeout(() => {
            setNewValues(undefined);
            Notifier.info('ModbusAirHandler update completed');
            setViewMode(ViewMode.NORMAL);
          }, 1000);
        } else {
          setOriginalValues(initialValues);
        }
      }
    }
  }, [data, preferredUnits]);

  React.useEffect(() => {
    if (
      viewMode !== ViewMode.NORMAL &&
      originalValues !== undefined &&
      newValues === undefined
    ) {
      setNewValues({ ...originalValues });
    }
  }, [viewMode]);

  /**
   * Respond to changes in the 'isOffline' state flag
   */
  React.useEffect(() => {
    isOffline ? setViewMode(ViewMode.OFFLINE) : setViewMode(ViewMode.NORMAL);
  }, [isOffline]);

  /* Update information displayed in the info box */
  React.useEffect(() => {
    if (device !== undefined) {
      const deviceInfo = updateInfoBox(props, device, {
        ...originalValues,
        ...newValues,
        temperatureUnits: preferredUnits,
      } as CurrentAttributes);
      setDeviceInfo(deviceInfo);
    }
  }, [props, device, setDeviceInfo, newValues, originalValues]);

  const changeTarget = (
    targetMode: 'rhSetpoint' | 'freshAirSetpoint',
    delta: number,
  ) => {
    const myValues = { ...newValues };

    const newSetpoint =
      (targetMode === 'rhSetpoint'
        ? (myValues.rhSetpoint ?? 40) + delta
        : (myValues.freshAirSetpoint ??
            convertToAirFlowUnits(10, preferredAirFlowUnits) ??
            0) + delta) ?? 0;
    let warning;

    if (
      !isWithinLimits(
        targetMode === 'rhSetpoint' ? rhLimits : freshAirLimits,
        newSetpoint,
      )
    ) {
      warning = `Setpoint must be in range: ${
        targetMode === 'rhSetpoint' ? rhLimits : freshAirLimits
      }.`;
      Notifier.warn(warning, `${targetMode} setpoint limit exceeded`);
    } else {
      // Ok, the new setpoint value is within the limits -- save and update
      const changes: Record<string, number | string> = {};
      changes[targetMode] = newSetpoint;

      // Check whether the hasChanges flag needs to be updated
      handleChange(changes, isValid);
    }
  };

  /**
   * handleChange: make changes to setTemperature values
  /* - handle edits of setpoints, operating mode, fan mode 
   * - use a copy of current values to avoid being overwritten by subscription updates
   */
  const handleChange = (
    changes: Record<string, number | string | boolean>,
    isValid: boolean,
  ) => {
    const myChanges: Record<
      keyof CurrentAttributes,
      number | string | boolean
    > = changes;
    const myValues = { ...newValues, ...myChanges } as CurrentAttributes;

    setNewValues((current) => {
      return { ...current, ...myChanges } as CurrentAttributes;
    });

    let changesDetected = false;
    if (newValues && originalValues) {
      const changedKeys = Object.keys(originalValues).some((key) => {
        const myKey: keyof CurrentAttributes =
          key as unknown as keyof CurrentAttributes;
        return myValues[myKey] !== originalValues[myKey];
      });
      if (changedKeys) {
        changesDetected = true;
      }
    }

    setIsValid(isValid);

    if (changesDetected) {
      setViewMode(ViewMode.EDIT);
    } else {
      setViewMode(ViewMode.EDIT_UNCHANGED);
    }
  };

  function handleDeviceUpdate(
    updates: ModbusAirHandlerUpdateInput,
    fields: ModbusAirHandlerUpdateFields,
    updateTimer: NodeJS.Timeout,
  ) {
    updateModbusAirHandler({
      variables: {
        id: device?.deviceId || '',
        updates,
        fields,
        options: {},
      },
    })
      .then((result) => {
        if (result.errors) {
          // An error occurred, throw it and handle it below
          throw new Error(result.errors[0].message);
        } else if (result.data?.updateModbusAirHandler.hasPendingUpdates) {
          // ModbusAirHandler has pending updates, clear the timer and wait for
          // those updates to complete
          clearTimeout(updateTimer);
        } else {
          // ModbusAirHandler has no pending updates. This is rare, but can happen
          // if the server deems that all given attributes are already in the
          // correct state, in which case it will return without error, but
          // skip sending any request to configure the thermostat. In this case,
          // we can consider the update to have completed successfully.
          Notifier.info('ModbusAirHandler update completed');
          clearTimeout(updateTimer);
          setNewValues(undefined);
          setViewMode(ViewMode.NORMAL);
        }
      })
      .catch((error) => {
        console.log(
          '[Update ModbusAirHandler Values] Got error during update:',
          error,
        );
        Notifier.error(
          'Received Error while updating ModbusAirHandler. Please try again or contact support if the problem persists.',
        );
        clearTimeout(updateTimer);
        setNewValues(undefined);
        setViewMode(ViewMode.NORMAL);
      });
  }

  const handleFilterResetRequest = (key: keyof CurrentAttributes) => {
    setViewMode(ViewMode.UPDATING);
    const updateTimer = setTimeout(() => setViewMode(ViewMode.NORMAL), 30000);
    handleDeviceUpdate(
      { [key as keyof CurrentAttributes]: 'Reset' },
      {},
      updateTimer,
    );
  };

  /**
   * handleMenuClick: set the ViewMode based on a given MenuAction
   */
  const handleMenuClick = (action: MenuAction) => {
    switch (action) {
      case MenuAction.CANCEL:
        setViewMode(ViewMode.NORMAL);
        setNewValues(undefined);
        break;
      case MenuAction.EDIT_VALUES:
        const changes: Partial<CurrentAttributes> = {};
        const newRHSetpoint = closestValidInteger(
          rhLimits,
          originalValues?.rhSetpoint || 40,
        );
        if (newRHSetpoint !== originalValues?.rhSetpoint) {
          changes.rhSetpoint = newRHSetpoint;
        }
        const newFreshAirSetpoint = closestValidInteger(
          freshAirLimits,
          originalValues?.freshAirSetpoint || 10,
        );
        if (newFreshAirSetpoint !== originalValues?.freshAirSetpoint) {
          changes.freshAirSetpoint = newFreshAirSetpoint;
        }
        if (Object.keys(changes ?? {}).length > 0) {
          Notifier.warn(
            'Current values have been adjusted in the editor to comply with pre-set limits and rules.',
          );
          setViewMode(ViewMode.EDIT);
        } else {
          setViewMode(ViewMode.EDIT_UNCHANGED);
        }
        setNewValues({ ...originalValues, ...changes } as CurrentAttributes);
        break;
      case MenuAction.REVERT_CHANGES:
        setViewMode(ViewMode.NORMAL);
        setNewValues(undefined);
        break;
      case MenuAction.SAVE_CHANGES:
        if (device && originalValues && newValues) {
          setViewMode(ViewMode.UPDATING);
          setTimeout(() => {
            // Revert to normal view mode if update doesn't complete after 30s.
            // While most thermostat updates should complete in a matter of seconds,
            // occasionally the zigbee network is slow and the update takes longer.
            // If we revert too soon while in AWAITING view mode, this might trigger
            // the "ModbusAirHandler being updated on another device" warning, because the
            // `hasPendingUpdates` flag hasn't been cleared on the server yet.
            const updateTimer = setTimeout(
              () => setViewMode(ViewMode.NORMAL),
              30000,
            );

            const updates: ModbusAirHandlerUpdateInput = {};

            if (newValues.ahuModbusId !== originalValues.ahuModbusId) {
              updates.ahuModbusId = newValues.ahuModbusId;
            }

            if (newValues.altitude !== originalValues.altitude) {
              updates.altitude = newValues.altitude;
            }

            if (
              newValues.freshAirSetpoint !== originalValues.freshAirSetpoint
            ) {
              updates.freshAirSetpoint = convertToAirFlowUnits(
                newValues.freshAirSetpoint,
                LitersPerSecond,
                preferredAirFlowUnits,
              );
            }

            if (newValues.ventilationMode !== originalValues.ventilationMode) {
              updates.ventilationMode = newValues.ventilationMode;
            }

            if (newValues.rhSetpoint !== originalValues.rhSetpoint) {
              updates.rhSetpoint = newValues.rhSetpoint;
            }

            if (
              newValues.zmbPollingFrequency !==
              originalValues.zmbPollingFrequency
            ) {
              updates.zmbPollingFrequency = newValues.zmbPollingFrequency;
            }

            if (
              newValues.zmbModbusRequestDelay !==
              originalValues.zmbModbusRequestDelay
            ) {
              updates.zmbModbusRequestDelay = newValues.zmbModbusRequestDelay;
            }

            if (
              newValues.auxCoolingEnabled !== originalValues.auxCoolingEnabled
            ) {
              updates.auxCoolingEnabled = newValues.auxCoolingEnabled;
            }

            if (
              newValues.winterRhAdjustEnabled !==
              originalValues.winterRhAdjustEnabled
            ) {
              updates.winterRhAdjustEnabled = newValues.winterRhAdjustEnabled;
            }

            if (
              newValues.showerDetectorEnabled !==
              originalValues.showerDetectorEnabled
            ) {
              updates.showerDetectorEnabled = newValues.showerDetectorEnabled;
            }

            // Fields that can be modified in the DB, but are not attributes
            // that can be configured on the physical thermostat
            const fields: ModbusAirHandlerUpdateFields = {};

            // if (newValues.airflowUnits !== originalValues.airflowUnits) {
            //   fields.airflowUnits = newValues.airflowUnits;
            // }

            if (device?.deviceId) {
              handleDeviceUpdate(updates, fields, updateTimer);
            }
          }, 500);
        } else {
          setViewMode(ViewMode.NORMAL);
        }
        break;
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const hasAlerts =
    (device?.deviceAlerts?.length && device?.deviceAlerts?.length > 0) ||
    (device?.sourceAlerts?.length && device?.sourceAlerts?.length > 0);

  return originalValues && device ? (
    loading ? (
      <Card className="air-handler-loading-container">
        <CardContent className="air-handler-loading-2">
          <CircularProgress />
        </CardContent>
      </Card>
    ) : (
      <Card
        sx={{
          margin: '3px 0px 1px 0px',
          backgroundColor: hasAlerts ? '#ffd5d5' : '#edfcf4',
          // backgroundColor: hasAlerts ? '#ffd5d5' : '#5eb85ead',
          border: hasAlerts ? '2px solid red' : '1px solid lightgray',
          boxShadow: 2,
          p: 0,
          width: '100%',
        }}
      >
        <CardHeader
          style={{
            // backgroundColor: hasAlerts ? '#ffd5d5' : '#ebf7f6',
            // backgroundColor: hasAlerts ? '#ffd5d5' : '#5eb85ead',
            padding: '0px',
          }}
          avatar={
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                padding: '22px 0px',
                width: '20px',
                height: '20px',
              }}
            >
              <AirHandlerIcon />
            </Avatar>
          }
          action={
            <div
              style={{
                margin: '0px',
                padding: '0px',
                display: 'flex',
              }}
            >
              <MenuButtons
                viewMode={viewMode}
                handleClick={handleMenuClick}
                isValid={isValid}
                hasErrors={!!hasAlerts}
                isEditable={!!isEditable}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '40px',
                }}
                className="MuiButtonGroup-root MuiButtonGroup-text"
              >
                <ToggleAlertDetailsButton
                  device={device}
                  showDivider={true}
                  expand={alertsExpanded}
                  buttonLabel="Show alerts"
                  onClick={(event) => {
                    event.preventDefault();
                    setAlertsExpanded(!alertsExpanded);
                  }}
                />
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>
            </div>
          }
          title={
            <Typography
              variant="h5"
              sx={{ color: '#1E3D1D', marginLeft: '10px' }}
            >
              {originalValues.name}
            </Typography>
          }
          sx={{
            borderBottom: '1px solid lightgray',
            padding: '0px',
            backgroundColor: '#ebf7f6',
          }}
        />
        <CardContent
          sx={{
            px: 0,
            py: 0,
            '&.MuiCardContent-root:last-child': { pb: 1 },
            ...(expanded || alertsExpanded
              ? {
                  paddingBottom: '10px',
                  borderBottom: '1px solid lightgray',
                  marginBottom: '10px',
                }
              : {}),
          }}
        >
          <DisplayScreen
            device={device}
            values={{ ...originalValues, ...newValues }}
            preferredTemperatureUnits={preferredUnits}
            preferredAirflowUnits={preferredAirFlowUnits}
            viewMode={viewMode}
            editable={isEditable}
            handleChange={handleChange}
            handleTargetChange={changeTarget}
            handleFilterResetRequest={handleFilterResetRequest}
          />
          {/*<hr*/}
          {/*  style={{*/}
          {/*    padding: '0px',*/}
          {/*    margin: '0px 0px 0px 0px',*/}
          {/*    border: '0.5px solid lightgray',*/}
          {/*  }}*/}
          {/*/>*/}
          {((viewMode === ViewMode.EDIT ||
            viewMode === ViewMode.EDIT_UNCHANGED) && (
            <SystemEdit
              device={device}
              currentValues={newValues ? newValues : originalValues}
              handleChange={handleChange}
            />
          )) ||
            (viewMode === ViewMode.UPDATING && (
              <UpdatePending status={'submitted'} />
            )) ||
            (viewMode === ViewMode.AWAITING && (
              <UpdatePending status={'awaiting'} />
            ))}
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <DeviceInfoBox infoEntries={deviceInfo} />
        </Collapse>
        <DeviceAlerts device={device} alertsExpanded={alertsExpanded} />
      </Card>
    )
  ) : (
    <Card className="air-handler-loading-container">
      <CardContent className="air-handler-loading-2">
        <CircularProgress />
      </CardContent>
    </Card>
  );
}
