/**
 * SmartPowerOutletComponent
 * - display smart power outlet readings
 * - display 'pairing' information
 * - display device info
 */

import React, { useEffect, useState } from 'react';

/* MUI */
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

/* Icons */
import PowerIcon from '@mui/icons-material/Power';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* Types */
import {
  SmartPowerOutlet,
  Thermostat,
  useSmartPowerOutletDetailQuery,
  useSmartPowerOutletDetailUpdateSubscription,
} from '../../../../types/generated-types';
import Divider from '@mui/material/Divider';
import { formatVoltage } from '../../../ui/shared/battery';
import { DisplayValue } from '../shared-ui';
import { Collapse } from '@mui/material';

/* Shared */
import { DeviceInfo, DeviceInformation } from '../shared-ui/device-info';
import {
  DeviceAlerts,
  ToggleAlertDetailsButton,
} from '../shared-ui/alerts-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import moment from 'moment/moment';

/* Utilities */
export function humanize(str: string) {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')),
  );
}

interface SmartPowerOutletProps {
  deviceId: string;
  pairedThermostat?: Partial<Thermostat>;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function SmartPowerOutletIcon() {
  return <PowerIcon sx={{ fontSize: '1rem', margin: '0px -10px 0px -6px' }} />;
}

export function formatPower(
  value: number | null | undefined,
  digits = 2, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const power = value.toPrecision(digits);

  // Continuous voltage measurement case
  return `${power} kWh`;
}

export function formatCurrent(
  value: number | null | undefined,
  digits = 2, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const current = Math.round(value);

  // Continuous current measurement case
  return `${current} A`;
}

export function formatActivePower(
  value: number | null | undefined,
  digits = 1, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const activePower = Math.round(value);

  // Continuous Active Power measurement case
  return `${activePower} W`;
}

export function formatPowerFactor(
  value: number | null | undefined,
  digits = 1, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const powerFactor = Math.round(value);

  // Continuous Power Factor measurement case
  return `${powerFactor}%`;
}

// TODO: Testing_required: Peter: convert temps to preferred units.
export function SmartPowerOutletComponent({
  deviceId,
  pairedThermostat,
}: SmartPowerOutletProps) {
  const [device, setDevice] = useState<Partial<SmartPowerOutlet>>();
  const { data, loading, error } = useSmartPowerOutletDetailQuery({
    variables: { id: deviceId },
  });
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);

  useEffect(() => {
    if (data?.smartPowerOutletById) {
      setDevice(data.smartPowerOutletById as any);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Zone: device.zone?.name ?? 'Unnamed',
        'Zone ID': device.zoneId ?? 'Unidentified',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          firmwareInfo: {
            Model:
              device.meta?.model || device.meta?.modelNumber || 'Unknown model',
            Version: device.meta?.version || 'Unknown version',
          },
          State: device.isOn ? 'On' : 'Off',
          Voltage: formatVoltage(device.voltage?.value),
          Current: formatCurrent(device.current?.value),
          'Active Power': formatActivePower(device.activePower?.value),
          'Power Factor': formatPowerFactor(device.powerFactor?.value),
          'Cumulative Power': formatPower(device.cumulativePower?.value),
          'Last Updated': moment(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  useSmartPowerOutletDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const [expanded, setExpanded] = useState(false);
  const [alertsExpanded, setAlertsExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const hasAlerts =
    (device?.deviceAlerts?.length && device?.deviceAlerts?.length > 0) ||
    (device?.sourceAlerts?.length && device?.sourceAlerts?.length > 0);

  return error ? (
    <div>Error: {JSON.stringify(error)}</div>
  ) : loading ? (
    <DeviceLoadingSkeleton />
  ) : device ? (
    <Card
      sx={{
        backgroundColor: hasAlerts ? '#ffd5d5' : '#ebf7f6',
        boxShadow: 1,
        p: 0,
        width: '100%',
        border: hasAlerts ? '1px solid red' : '',
        marginTop: '4px',
      }}
    >
      <CardHeader
        sx={{
          paddingTop: '0px',
          paddingLeft: '10px',
          paddingRight: '10ox',
          paddingBottom: '0px',
          border: '1px solid rgba(0,0,0, 0.1)',
        }}
        title={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flex: '0 0 auto',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {device?.name}
              </div>
            </div>
            <div style={{ flex: '1 1 auto', flexDirection: 'column' }} />
            <div style={{ flex: '0 0 auto', flexDirection: 'column' }}>
              <Box sx={{ p: 0 }}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <div style={{ marginLeft: '6px' }}>&nbsp;</div>
                  <div
                    style={{
                      paddingTop: '12px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  >
                    {device.isOn?.value ? 'On' : 'Off'}
                  </div>
                  <DisplayValue
                    value={device.voltage?.value}
                    units={device.voltage?.units}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <DisplayValue
                    value={device.current?.value}
                    units={device.current?.units}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <DisplayValue
                    value={device.activePower?.value}
                    units={device.activePower?.units}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <DisplayValue
                    value={device.cumulativePower?.value}
                    units={device.cumulativePower?.units}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <DisplayValue
                    value={device.powerFactor?.value}
                    units={device.powerFactor?.units}
                    size={'h3'}
                    unitsSize={'14px'}
                    style={{
                      paddingTop: '8px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  />
                  <div style={{ marginRight: '6px' }}>&nbsp;</div>
                </Stack>
              </Box>
            </div>
          </div>
        }
        avatar={SmartPowerOutletIcon()}
        action={
          <div
            style={{
              display: 'inline-flex',
              marginRight: '-14px',
              marginLeft: '-16px',
            }}
            className="MuiButtonGroup-root MuiButtonGroup-text"
          >
            <ToggleAlertDetailsButton
              device={device}
              showDivider={true}
              expand={alertsExpanded}
              buttonLabel="Show alerts"
              onClick={(event) => {
                event.preventDefault();
                setAlertsExpanded(!alertsExpanded);
              }}
            />
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <DeviceInfo infoEntries={deviceInfo} />
      </Collapse>
      <DeviceAlerts device={device} alertsExpanded={alertsExpanded} />
    </Card>
  ) : null;
}
