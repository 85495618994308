/**
 * ScheduleItem
 * - displays a ListItem populated with timestamp and schedule settings
 * - consumes the TimePicker and EditSettings components
 */

import React, { useState, useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { TimePicker } from './time-picker';
import { EditSettings } from './edit-settings';
import { MyScheduleSetting, MyThermostat, TimeOfDay } from '../../types';
import { defaultAbsoluteLimits } from '../../../../helpers';
import {
  Celsius,
  TemperatureUnit,
} from '../../../../../system/models/temperatureUnits';
import { SetpointLimitSettings } from '../../../../units/types/setpointLimitSettings';
import { convertToTempUnits } from '../../../util';

interface ScheduleItemProps {
  time?: TimeOfDay;
  setting?: MyScheduleSetting;
  setpointLimitSettings: SetpointLimitSettings; // TODO: Calvin ask Peter if all thermostats contain max, min setpoint limit values
  handleDelete: (time: TimeOfDay) => void;
  preferredUnits: TemperatureUnit;
  handleEdit: (
    setting: MyScheduleSetting,
  ) => (oldTime: TimeOfDay, newTime?: TimeOfDay) => void;
}

export function ScheduleItem(props: ScheduleItemProps) {
  const {
    time,
    setting,
    handleDelete,
    handleEdit,
    preferredUnits,
    setpointLimitSettings,
  } = props;

  const [newHeatValue, setHeatValue] = useState(
    setting?.minimum ?? defaultAbsoluteLimits['C'].heat.max,
  );
  const [newCoolValue, setCoolValue] = useState(
    setting?.maximum ?? defaultAbsoluteLimits['C'].cool.max,
  );

  const [anchorEl, setAnchorEl] = useState<
    Record<string, HTMLButtonElement | null>
  >({ heating: null, cooling: null });

  const theme = useTheme();

  const open = (mode: string) => Boolean(anchorEl[mode]);
  const id = (mode: string) => (open(mode) ? 'simple-popover' : undefined);

  // When setting is updated with "Revert changes" method
  // Capture this change and update the min and max values
  useEffect(() => {
    if (setting?.maximum && setting?.minimum) {
      setHeatValue(setting.minimum);
      setCoolValue(setting.maximum);
    }
  }, [setting]);

  const handleClick = (
    mode: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl({ [mode]: event.currentTarget });
  };

  const handleClose = (mode: string, newSetting: MyScheduleSetting) => {
    setAnchorEl({ [mode]: null });
    if (time) {
      handleEdit({ ...setting, ...newSetting })(time);
    }
  };

  const handleDeadBandAdjustedTemperature = (
    leftValue: number,
    rightValue: number,
    direction?: number,
  ) => {
    let deadband = preferredUnits === Celsius ? 2 : 4;
    let adjustedValue: number = rightValue;
    const myDirection = direction ?? 1;
    if (Math.abs(leftValue - rightValue) < deadband) {
      if (myDirection > 0) {
        adjustedValue = rightValue + (leftValue - rightValue + deadband);
      } else {
        const rightAndLeftDiff = rightValue - leftValue;
        adjustedValue = rightValue - (rightAndLeftDiff + deadband);
      }
      return adjustedValue;
    }

    return adjustedValue;
  };

  const handleChange = (mode: string, value: number) => {
    let direction = 0;

    switch (mode) {
      case 'heating':
        if (newHeatValue === value) {
          return {};
        } else if (newHeatValue < value) {
          direction = 1;
        } else {
          direction = -1;
        }
        setHeatValue(value);
        const adjustedDeadBandCoolValue = handleDeadBandAdjustedTemperature(
          value,
          newCoolValue,
          direction,
        );
        if (newCoolValue !== adjustedDeadBandCoolValue) {
          setCoolValue(adjustedDeadBandCoolValue);
        }
        break;
      case 'cooling':
        if (newCoolValue === value) {
          return {};
        } else if (newCoolValue < value) {
          direction = 1;
        } else {
          direction = -1;
        }
        setCoolValue(value);
        const adjustedDeadBandHeatValue = handleDeadBandAdjustedTemperature(
          value,
          newHeatValue,
          direction,
        );
        if (newCoolValue !== adjustedDeadBandHeatValue) {
          setHeatValue(adjustedDeadBandHeatValue);
        }
        break;
      default:
    }
  };

  return (
    (time && (
      <>
        <ListItem
          key={time}
          sx={{
            pl: 1,
            justifyContent: 'space-evenly',
          }}
        >
          {/* <Material Time Picker - TODO: Calvin: Need alternate IOS style version */}
          <TimePicker time={time} handleEdit={handleEdit({ ...setting })} />
          <EditSettings
            id={id('heating')}
            mode={'heating'}
            handleChange={handleChange}
            maxHeatSetpointLimit={
              setpointLimitSettings?.maxHeatSetpointLimit ??
              defaultAbsoluteLimits['C'].heat.max
            }
            minHeatSetpointLimit={
              setpointLimitSettings?.minHeatSetpointLimit ??
              defaultAbsoluteLimits['C'].heat.min
            }
            value={newHeatValue}
            open={open('heating')}
            anchorEl={anchorEl.heating}
            handleClick={handleClick}
            handleClose={handleClose}
            theme={theme}
          />
          <EditSettings
            id={id('cooling')}
            mode={'cooling'}
            handleChange={handleChange}
            maxCoolSetpointLimit={
              setpointLimitSettings?.maxCoolSetpointLimit ??
              defaultAbsoluteLimits['C'].cool.max
            }
            minCoolSetpointLimit={
              setpointLimitSettings?.minCoolSetpointLimit ??
              defaultAbsoluteLimits['C'].cool.min
            }
            value={newCoolValue}
            open={open('cooling')}
            anchorEl={anchorEl.cooling}
            handleClick={handleClick}
            handleClose={handleClose}
            theme={theme}
          />
        </ListItem>
        <Divider />
      </>
    )) || (
      <ListItem key={'SCHEDULE_ERROR'}>
        <ListItemText primary={'Error - Invalid Timestamp'} />
      </ListItem>
    )
  );
}
