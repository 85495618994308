import React, { useEffect } from 'react';

/* MUI */
import {
  CardHeader,
  FormControl,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';

/* TYPES */
import {
  EnumScheduleType,
  ThermostatScheduleTemplate,
  ThermostatScheduleTemplateValue,
} from '../../../../../types/generated-types';
import { FormValues } from '../../types/scheduleDetailFormValues';
import {
  dailyScheduleDefault,
  DayOfWeek,
  dayOfWeekLabels,
  dayOfWeekLabelsMobile,
  EmptySchedule,
  eventLabels,
  MyScheduleSetting,
  MyWeeklySchedule,
  TimeOfDay,
} from '../../../devices/thermostat/types';
import {
  Celsius,
  Fahrenheit,
  TemperatureUnit,
} from '../../../../system/models/temperatureUnits';

/* FORMIK */
import { useFormik } from 'formik';

/* HELPERS */
import _ from 'lodash';

/* COMPONENTS */
import {
  WdTab,
  WdTabs,
} from '../../../devices/thermostat/components/schedule/weekday-tabs';
import { AddScheduleItem } from '../../../devices/thermostat/components/schedule/add-item';
import BorderedSection from '../../../shared/borderedSection';
import { useAuthenticator } from '../../../../auth/AuthenticationContext';
import { ScheduleItem } from '../../../devices/thermostat/components/schedule/schedule-item';
import { defaultAbsoluteLimits } from '../../../helpers';
import { SetpointLimitSettings } from '../../types/setpointLimitSettings';

interface ScheduleDetailFormProps {
  template: Partial<ThermostatScheduleTemplate> | null;
  formik: ReturnType<typeof useFormik<FormValues>>;
  scheduleValues: ThermostatScheduleTemplateValue;
  onChange: StandardInputProps['onChange'];
  setScheduleValues: (schedule: ThermostatScheduleTemplateValue) => void;
  scheduleTemplateSetpointProfileSettings?: SetpointLimitSettings;
}

const label: string[] = ['Weekday', 'Weekend'];

export function ScheduleTemplateDetailForm(props: ScheduleDetailFormProps) {
  const {
    template,
    setScheduleValues,
    scheduleValues,
    scheduleTemplateSetpointProfileSettings,
  } = props;

  const [dailySchedule, setDailySchedule] = React.useState();
  const [scheduleType, setScheduleType] = React.useState<EnumScheduleType>(
    EnumScheduleType.SameEveryDay,
  );

  const selectedScheduleType = scheduleType ?? EnumScheduleType.SameEveryDay;
  // If the schedule type is weekday-weekend, select Monday (the weekday) by default, otherwise select Sunday
  const [selectedDay, setSelectedDay] = React.useState(
    selectedScheduleType === EnumScheduleType.WeekdayWeekend
      ? DayOfWeek.MO
      : DayOfWeek.SU,
  );

  const theme = useTheme();

  const { user } = useAuthenticator();

  const preferredUnits: TemperatureUnit = user?.preferredUnits ?? Fahrenheit;

  /**
   * HANDLERS
   */

  const handleTypeSelect = (type: EnumScheduleType) => {
    handleScheduleTypeChange(type);
    // If the new schedule type is weekday-weekend, select Monday (the weekday) by default, otherwise select Sunday
    if (type === EnumScheduleType.WeekdayWeekend) {
      setSelectedDay(DayOfWeek.MO);
    } else {
      setSelectedDay(DayOfWeek.SU);
    }
  };

  const handleScheduleTypeChange = (scheduleType: EnumScheduleType) => {
    const _newSchedule = _.cloneDeep(scheduleValues);
    if (scheduleType === EnumScheduleType.SameEveryDay) {
      // Set all daily schedules to the same value
      const dailySchedule = _.isEqual(scheduleValues.value, EmptySchedule)
        ? dailyScheduleDefault
        : scheduleValues.value[DayOfWeek.SU];
      _newSchedule.value[DayOfWeek.SU] = dailySchedule;
      _newSchedule.value[DayOfWeek.MO] = dailySchedule;
      _newSchedule.value[DayOfWeek.TU] = dailySchedule;
      _newSchedule.value[DayOfWeek.WE] = dailySchedule;
      _newSchedule.value[DayOfWeek.TH] = dailySchedule;
      _newSchedule.value[DayOfWeek.FR] = dailySchedule;
      _newSchedule.value[DayOfWeek.SA] = dailySchedule;
    } else if (scheduleType === EnumScheduleType.WeekdayWeekend) {
      // Set all weekday and weekend schedules to the same values
      const weekendSchedule = _.isEqual(scheduleValues.value, EmptySchedule)
        ? dailyScheduleDefault
        : scheduleValues.value[DayOfWeek.SU];
      const weekdaySchedule = _.isEqual(scheduleValues.value, EmptySchedule)
        ? dailyScheduleDefault
        : scheduleValues.value[DayOfWeek.MO];

      _newSchedule.value[DayOfWeek.MO] = weekdaySchedule;
      _newSchedule.value[DayOfWeek.TU] = weekdaySchedule;
      _newSchedule.value[DayOfWeek.WE] = weekdaySchedule;
      _newSchedule.value[DayOfWeek.TH] = weekdaySchedule;
      _newSchedule.value[DayOfWeek.FR] = weekdaySchedule;
      _newSchedule.value[DayOfWeek.SA] = weekendSchedule;
      _newSchedule.value[DayOfWeek.SU] = weekendSchedule;
    } else if (scheduleType === EnumScheduleType.Custom) {
      if (_.isEqual(scheduleValues.value, EmptySchedule)) {
        _newSchedule.value[DayOfWeek.SU] = dailyScheduleDefault;
        _newSchedule.value[DayOfWeek.MO] = dailyScheduleDefault;
        _newSchedule.value[DayOfWeek.TU] = dailyScheduleDefault;
        _newSchedule.value[DayOfWeek.WE] = dailyScheduleDefault;
        _newSchedule.value[DayOfWeek.TH] = dailyScheduleDefault;
        _newSchedule.value[DayOfWeek.FR] = dailyScheduleDefault;
        _newSchedule.value[DayOfWeek.SA] = dailyScheduleDefault;
      }
    }
    setScheduleValues(_newSchedule);
    setScheduleType(scheduleType);
  };

  const handleDaySelect = (
    event: React.SyntheticEvent,
    newValue: DayOfWeek,
  ) => {
    setSelectedDay(newValue);
  };

  const handleScheduleChange = (
    operation: 'add' | 'delete' | 'edit_setpoints' | 'edit_time' | 'delete_all',
    dayOfWeek: DayOfWeek,
    timeOfDay: TimeOfDay,
    newTimeOfDay?: TimeOfDay,
    scheduleSetting?: MyScheduleSetting,
  ) => {
    // Create a copy of the current schedule object, or a new schedule object if none exists
    const _newSchedule = { ...scheduleValues };
    if (scheduleValues && scheduleValues.value) {
      _newSchedule.value = _.cloneDeep(scheduleValues.value);
    } else {
      _newSchedule.value = _.cloneDeep(EmptySchedule);
    }

    // Perform the specified update, for the specified day of the week
    switch (operation) {
      case 'edit_setpoints':
        _newSchedule.value[dayOfWeek] = {
          ..._newSchedule.value[dayOfWeek],
          [timeOfDay]: scheduleSetting ?? {},
        };
        break;
      case 'edit_time':
        delete _newSchedule.value[dayOfWeek][timeOfDay];
        _newSchedule.value[dayOfWeek] = {
          ..._newSchedule.value[dayOfWeek],
          [newTimeOfDay ?? '']: scheduleSetting ?? {},
        };
        break;
      case 'delete':
        delete _newSchedule.value[dayOfWeek][timeOfDay];
        break;
      case 'delete_all':
        _newSchedule.value = _.cloneDeep(EmptySchedule);
        break;
    }

    // Ensure the update is applied to all appropriate days of the week, according to the schedule type
    const updatedDailySchedule = _newSchedule.value[dayOfWeek];
    if (scheduleType === EnumScheduleType.SameEveryDay) {
      // Apply update to Sunday's schedule to all other days of the week
      _newSchedule.value[DayOfWeek.MO] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.TU] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.WE] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.TH] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.FR] = updatedDailySchedule;
      _newSchedule.value[DayOfWeek.SA] = updatedDailySchedule;
    } else if (scheduleType === EnumScheduleType.WeekdayWeekend) {
      if (dayOfWeek === DayOfWeek.SU) {
        // Apply update to Sunday's schedule to the rest of the weekend
        _newSchedule.value[DayOfWeek.SA] = updatedDailySchedule;
      } else if (dayOfWeek === DayOfWeek.MO) {
        // Apply update to Monday's schedule to the rest of the weekdays
        _newSchedule.value[DayOfWeek.TU] = updatedDailySchedule;
        _newSchedule.value[DayOfWeek.WE] = updatedDailySchedule;
        _newSchedule.value[DayOfWeek.TH] = updatedDailySchedule;
        _newSchedule.value[DayOfWeek.FR] = updatedDailySchedule;
      }
    }

    setScheduleValues(_newSchedule);
  };

  /**
   * handleAdd
   * - call initially with the selected day-of-the-week and time of day
   * - returns a function that calls update function on the parent component
   */
  const handleAdd = (day: DayOfWeek, timeOfDay: number) => {
    const defaultValue = {
      minimum:
        scheduleTemplateSetpointProfileSettings?.minHeatSetpointLimit ??
        defaultAbsoluteLimits[preferredUnits].heat.min,
      maximum:
        scheduleTemplateSetpointProfileSettings?.maxCoolSetpointLimit ??
        defaultAbsoluteLimits[preferredUnits].cool.max,
    };
    return () => {
      handleScheduleChange(
        'add',
        day,
        String(timeOfDay),
        undefined,
        defaultValue,
      );
    };
  };

  const handleDelete = (day: DayOfWeek) => {
    return (time: TimeOfDay) => {
      handleScheduleChange('delete', day, time);
    };
  };

  const handleEdit = (day: DayOfWeek) => {
    return (setting: MyScheduleSetting) => {
      return (oldTime: TimeOfDay, newTime?: TimeOfDay) => {
        if (newTime && parseInt(oldTime) - parseInt(newTime) !== 0) {
          handleScheduleChange('edit_time', day, oldTime, newTime, {
            ...setting,
          });
        } else {
          handleScheduleChange('edit_setpoints', day, oldTime, undefined, {
            ...setting,
          });
        }
      };
    };
  };

  // Get days of the week to display tabs for, depending on the schedule type
  function daysToDisplay(): DayOfWeek[] {
    if (selectedScheduleType === EnumScheduleType.Custom) {
      return Object.values(DayOfWeek);
    } else if (selectedScheduleType === EnumScheduleType.WeekdayWeekend) {
      return [DayOfWeek.MO, DayOfWeek.SU];
    } else {
      return [DayOfWeek.SU];
    }
  }

  useEffect(() => {
    if (scheduleValues?.value) {
      setDailySchedule(scheduleValues.value[selectedDay]);
    }
  }, [scheduleValues, selectedDay]);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Get tab labels by schedule type
  // Example: If schedule type is 'SameEveryDay' then only display one tab label 'Every day'
  function getTabLabel(dayOfWeek: DayOfWeek): string {
    const labels = isSmall ? dayOfWeekLabelsMobile : dayOfWeekLabels;
    if (selectedScheduleType === EnumScheduleType.SameEveryDay) {
      return 'Everyday';
    } else {
      return labels[dayOfWeek];
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <TextField
        fullWidth
        required
        autoFocus
        id="name"
        name="name"
        placeholder="Template Name"
        label="Template Name"
        value={props.formik.values.name}
        onChange={props.onChange}
      />
      <br />
      <br />
      <FormControl fullWidth>
        <InputLabel id="schedule-type-label">Schedule Type</InputLabel>
        <Select
          labelId="schedule-type-label"
          id="schedule-type"
          value={scheduleType}
          required
          label="Schedule Type"
          onChange={(e) => {
            handleTypeSelect(e.target.value as EnumScheduleType);
          }}
        >
          <MenuItem value={EnumScheduleType.SameEveryDay}>
            Same Every Day
          </MenuItem>
          <MenuItem value={EnumScheduleType.WeekdayWeekend}>
            Weekday, Weekend
          </MenuItem>
          <MenuItem value={EnumScheduleType.Custom}>Custom</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <BorderedSection title="Details">
        <Typography>
          Property Default:{' '}
          {template?.isPropertyDefault ? (
            <span style={{ color: theme.embue.bad }}>Yes</span>
          ) : (
            <span style={{ color: theme.embue.off }}>No</span>
          )}
        </Typography>
        <Typography>
          Assigned Unit Count:{' '}
          <span style={{ color: theme.embue.bad }}>
            {template?.assignedUnitCount ?? ''}
          </span>
        </Typography>
      </BorderedSection>
      <br />
      <BorderedSection
        title="Thermostat Schedule"
        style={{ marginBottom: '10px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
              width: '100%',
            }}
          >
            <WdTabs
              variant="fullWidth"
              value={selectedDay}
              sx={{ width: '100%' }}
              onChange={handleDaySelect}
            >
              {scheduleValues &&
                daysToDisplay().map((dayOfWeek, index) => {
                  return (
                    <WdTab
                      key={dayOfWeek}
                      value={dayOfWeek}
                      sx={{ minWidth: '45px' }}
                      label={
                        daysToDisplay().length === 2 &&
                        selectedScheduleType === EnumScheduleType.WeekdayWeekend
                          ? label[index]
                          : getTabLabel(dayOfWeek)
                      }
                    />
                  );
                })}
            </WdTabs>
          </div>
        </div>
        <br />
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          dense
        >
          {dailySchedule &&
            Object.entries(dailySchedule).map(
              ([timeOfDay, scheduleSetting], index) => {
                return (
                  <Card
                    key={index}
                    sx={{ marginBottom: '20px', backgroundColor: '#f5f5f5' }}
                  >
                    <CardHeader
                      title={
                        <Typography sx={{ fontSize: '1rem' }}>
                          {eventLabels[index]}
                        </Typography>
                      }
                    />
                    <ScheduleItem
                      key={selectedDay + '_' + timeOfDay}
                      time={timeOfDay}
                      preferredUnits={preferredUnits}
                      setting={scheduleSetting as MyScheduleSetting}
                      handleDelete={handleDelete(selectedDay)}
                      handleEdit={handleEdit(selectedDay)}
                      setpointLimitSettings={{
                        maxHeatSetpointLimit:
                          scheduleTemplateSetpointProfileSettings?.maxHeatSetpointLimit ??
                          defaultAbsoluteLimits['C'].heat.max,
                        maxCoolSetpointLimit:
                          scheduleTemplateSetpointProfileSettings?.maxCoolSetpointLimit ??
                          defaultAbsoluteLimits['C'].cool.max,
                        minHeatSetpointLimit:
                          scheduleTemplateSetpointProfileSettings?.minHeatSetpointLimit ??
                          defaultAbsoluteLimits['C'].heat.min,
                        minCoolSetpointLimit:
                          scheduleTemplateSetpointProfileSettings?.minCoolSetpointLimit ??
                          defaultAbsoluteLimits['C'].cool.min,
                      }}
                    />
                  </Card>
                );
              },
            )}
        </List>
      </BorderedSection>
    </div>
  );
}
