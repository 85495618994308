import _ from 'lodash';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';
import {
  DayOfWeek,
  EmptySchedule,
  MyWeeklySchedule,
} from '../thermostat/types';
import { convertToTempUnits } from './convert-temp-units';

// Formats the current schedule with preferred units
export const convertToPreferredScheduleUnits = (
  schedule: MyWeeklySchedule,
  targetUnit: TemperatureUnit,
  currentUnits: TemperatureUnit,
) => {
  let scheduleUpdate: MyWeeklySchedule = _.cloneDeep(EmptySchedule);
  if (schedule) {
    for (const day in schedule) {
      const dailySchedule = schedule[day as DayOfWeek];
      for (const transition in dailySchedule) {
        const scheduleSetting = dailySchedule[transition];
        scheduleUpdate[day as DayOfWeek][transition] = {};
        if (scheduleSetting.maximum !== undefined) {
          scheduleUpdate[day as DayOfWeek][transition].maximum =
            convertToTempUnits(
              scheduleSetting.maximum,
              targetUnit,
              currentUnits,
              2,
            );
        }

        if (scheduleSetting.minimum !== undefined) {
          scheduleUpdate[day as DayOfWeek][transition].minimum =
            convertToTempUnits(
              scheduleSetting.minimum,
              targetUnit,
              currentUnits,
              2,
            );
        }
      }
    }
  }
  return scheduleUpdate;
};
