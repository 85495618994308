import React, { useEffect, useState } from 'react';

/* MUI */
import {
  List,
  FormControl,
  NativeSelect,
  FormLabel,
  Popover,
} from '@mui/material';

/* COMPONENTS */
import { Schedule } from './schedule';

/* ICONS */
import BorderedSection from '../../../../shared/borderedSection';

/* TYPES */
import {
  EnumScheduleType,
  ThermostatScheduleTemplate,
  ThermostatScheduleTemplateValue,
  Unit,
  Property,
  usePropertyScheduleTemplateListQuery,
} from '../../../../../../types/generated-types';
import {
  MyScheduleObject,
  DayOfWeek,
  TimeOfDay,
  MyScheduleSetting,
  MyThermostat,
} from '../../types';
import { TemperatureUnit } from '../../../../../system/models/temperatureUnits';
import { ThermostatScheduleTemplateDetail } from './thermostat-schedule-template-detail';
import { ThermostatScheduleDetailDialog } from './thermostat-schedule-detail-dialog';

/* NAVIGATION */
import { useParams } from 'react-router-dom';
import _ from 'lodash';

/* NOTIFICATIONS */
import { Notifier } from '../../../../../system/services/notificationManager';
import { ThermostatScheduleAction } from './thermostat-schedule-action';
import { systemChoices } from '../helpers/choices';
import { findAndSetTemplateById } from '../helpers/setters';
import { ThermostatAction } from '../../../../properties/types/thermostatAction';
import { ViewMode } from '../../thermostat';

export function formatData(
  data: Partial<ThermostatScheduleTemplate>[],
): ThermostatScheduleTemplateRowData[] {
  const formattedData: ThermostatScheduleTemplateRowData[] = [];
  data.forEach((row) => {
    if (row?._id) {
      formattedData.push({
        _id: row._id,
        name: row.name ?? 'N/A',
        propertyId: row.propertyId ?? '',
        value: row.value ?? {},
        setpointLimitProfileId: row?.setpointLimitProfileId ?? '',
      });
    }
  });
  return formattedData;
}

export interface ThermostatScheduleTemplateRowData {
  _id: string;
  name: string;
  propertyId: string;
  value: ThermostatScheduleTemplateValue;
  setpointLimitProfileId?: string | null;
}

interface ThermostatScheduleProps {
  schedule?: MyScheduleObject;
  scheduleType?: EnumScheduleType;
  handleChange: (
    operation: 'add' | 'delete' | 'edit_setpoints' | 'edit_time' | 'delete_all',
    dayOfWeek: DayOfWeek,
    timeOfDay: TimeOfDay,
    newTimeOfDay?: TimeOfDay,
    scheduleSetting?: MyScheduleSetting,
  ) => void;
  handleTypeChange: (type: EnumScheduleType) => void;
  temperatureUnit: TemperatureUnit;
  lastModified?: number;
  thermostat: MyThermostat;
  setViewMode: (mode: ViewMode) => void;
  unit: Partial<Unit>;
  property: Partial<Property>;
}

export function ThermostatSchedule(props: ThermostatScheduleProps) {
  const {
    schedule,
    scheduleType,
    thermostat,
    handleChange,
    handleTypeChange,
    setViewMode,
    temperatureUnit,
    lastModified,
    property,
    unit,
  } = props;

  // Schedule Category
  const handleThermostatScheduleCategory = () => {
    if (thermostat?.thermostatScheduleTemplateId) {
      return systemChoices.scheduleCategories.template;
    }
    if (thermostat?.useCustomSchedule) {
      return systemChoices.scheduleCategories.custom;
    } else {
      return systemChoices.scheduleCategories.default;
    }
  };

  /* State */
  const { id } = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [action, setAction] = useState<ThermostatAction>('apply');
  const [template, setTemplate] = useState<ThermostatScheduleTemplateRowData>();
  const [defaultTemplate, setDefaultTemplate] =
    useState<ThermostatScheduleTemplateRowData>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [templates, setTemplates] =
    useState<ThermostatScheduleTemplateRowData[]>();
  const [incompatibleTemplates, setIncompatibleTemplates] =
    useState<ThermostatScheduleTemplateRowData[]>();
  const [selectedScheduleCategory, setSelectedScheduleCategory] =
    useState<string>(handleThermostatScheduleCategory());

  /* Data */
  const {
    data: templateList,
    loading: templateListLoading,
    error: templateListError,
    refetch: refetchtemplateList,
  } = usePropertyScheduleTemplateListQuery({
    variables: { propertyId: id || '' },
    fetchPolicy: 'network-only',
  });

  /* USE EFFECTS */

  /* Property Schedule Template List */
  useEffect(() => {
    if (templateList?.thermostatScheduleTemplates) {
      const incompatibleTemplates: ThermostatScheduleTemplateRowData[] = [];
      const compatibleTemplates: ThermostatScheduleTemplateRowData[] = [];
      let compatibleSetpointProfileId: string = '';
      if (
        unit?.setpointProfileId &&
        unit?.setpointProfileId !== null &&
        unit?.setpointProfileId !== ''
      ) {
        compatibleSetpointProfileId = unit.setpointProfileId;
      } else if (
        property?.setpointProfileId &&
        property?.setpointProfileId !== null &&
        property?.setpointProfileId !== ''
      ) {
        compatibleSetpointProfileId = property.setpointProfileId;
      }
      templateList.thermostatScheduleTemplates.forEach((template, index) => {
        if (compatibleSetpointProfileId === '') {
          compatibleTemplates.push(template);
        } else if (
          template?.setpointLimitProfileId &&
          template?.setpointLimitProfileId !== null &&
          template?.setpointLimitProfileId !== ''
        ) {
          if (compatibleSetpointProfileId !== template.setpointLimitProfileId) {
            incompatibleTemplates.push(template);
            // templateList.thermostatScheduleTemplates.splice(index, 1);
          } else {
            compatibleTemplates.push(template);
          }
        } else {
          incompatibleTemplates.push(template);
          // templateList.thermostatScheduleTemplates.splice(index, 1);
        }
      });
      setIncompatibleTemplates(incompatibleTemplates);
      setTemplates(compatibleTemplates);
    }
  }, [selectedTemplateId, templateList]);

  /* 
  If thermostat includes a thermostat schedule template id 
  then preselect the template
  */
  useEffect(() => {
    let selectedTemplateId = '';
    if (templateList?.thermostatScheduleTemplates) {
      if (thermostat?.thermostatScheduleTemplateId) {
        selectedTemplateId = thermostat.thermostatScheduleTemplateId;
      } else if (unit?.thermostatScheduleTemplateId) {
        selectedTemplateId = unit.thermostatScheduleTemplateId;
      } else if (property?.thermostatScheduleTemplateId) {
        selectedTemplateId = property.thermostatScheduleTemplateId;
      }
      const t = findAndSetTemplateById(
        selectedTemplateId,
        templateList.thermostatScheduleTemplates,
      );
      if (t) {
        if (thermostat?.thermostatScheduleTemplateId) {
          setSelectedTemplateId(selectedTemplateId ?? '0');
          setTemplate(t);
        } else if (unit?.thermostatScheduleTemplateId) {
          setDefaultTemplate(t);
        } else if (property?.thermostatScheduleTemplateId) {
          setDefaultTemplate(t);
        }
      }
    }
  }, [templateList, thermostat, unit, property]);

  /* Sets the Apply button to enabled or disabled if changes are applicable */
  useEffect(() => {
    if (selectedTemplateId === thermostat?.thermostatScheduleTemplateId) {
      setShowApply(false);
    } else {
      setShowApply(true);
    }
  }, [selectedTemplateId]);

  /* HANDLERS */

  const handleResetSelection = () => {
    setTemplate(undefined);
    setSelectedTemplateId('0');
  };

  const handleSelectedTemplateId = (templateId: string) => {
    if (templateId === '-1') {
      setTemplate(undefined);
      setSelectedTemplateId('-1');
      setTimeout(() => {
        setOpen(true);
      }, 100);
    } else if (templateId === '0') {
      setSelectedTemplateId(templateId);
      setTemplate(undefined);
    } else {
      const t = findAndSetTemplateById(
        templateId,
        templateList?.thermostatScheduleTemplates ?? [],
      );
      if (t) {
        setSelectedTemplateId(templateId ?? '0');
        setTemplate(t);
      }
    }
  };

  const handleSelectedScheduleCategory = (selectedScheduleCategory: string) => {
    setSelectedScheduleCategory(selectedScheduleCategory);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSetAction = (a: ThermostatAction) => {
    setAction(a);
  };

  const handleDisplayThermostatTemplateLevel = () => {
    let levelText = 'Default';
    if (!thermostat?.useCustomSchedule) {
      if (thermostat?.thermostatScheduleTemplateId) {
        levelText = systemChoices.scheduleLevels.device;
      } else if (unit?.thermostatScheduleTemplateId) {
        levelText = systemChoices.scheduleLevels.unit;
      } else if (property?.thermostatScheduleTemplateId) {
        levelText = systemChoices.scheduleLevels.property;
      }
    } else {
      levelText = systemChoices.scheduleLevels.custom;
    }
    return levelText;
  };

  const handleClose = async (newTemplateId?: string, update?: boolean) => {
    if (newTemplateId && update) {
      await refetchtemplateList()
        .then((response) => {
          const t = findAndSetTemplateById(
            newTemplateId,
            response.data.thermostatScheduleTemplates,
          );
          if (t) {
            setSelectedTemplateId(newTemplateId ?? '0');
            setTemplate(t);
          }
        })
        .catch((err) => {
          Notifier.error(
            'A problem occurred while updating the template list. Please refresh the page and try again',
          );
          console.log('ERROR Refetching Thermostat Schedule Templates: ', err);
        });
    }
    setOpen(false);
  };

  return thermostat ? (
    <BorderedSection
      title="Thermostat Schedule"
      style={{ margin: '8px 4px 4px 4px', padding: '0px' }}
    >
      <FormControl fullWidth sx={{ marginBottom: '15px' }}>
        <FormLabel id="schedule-categories">
          Schedule Source: {`Using ${handleDisplayThermostatTemplateLevel()}`}
        </FormLabel>
        <NativeSelect
          // disabled={creating || editing || verifying}
          style={{ width: '100%', marginTop: '5px' }}
          value={selectedScheduleCategory}
          onChange={(event) => {
            handleSelectedScheduleCategory(event.target.value);
          }}
        >
          <option
            key="0"
            value="default-schedule"
            disabled={Boolean(thermostat.useCustomSchedule)}
          >
            Use: Default
          </option>
          <option key="1" value="custom-schedule">
            Use: Custom Schedule
          </option>
          <option
            key="2"
            value="template-schedule"
            disabled={Boolean(thermostat.useCustomSetpointLimits) ?? false}
          >
            {`Use: Schedule Template${
              thermostat.useCustomSetpointLimits
                ? ' Disabled by Custom Setpoint Limits'
                : ''
            }`}
          </option>
        </NativeSelect>
      </FormControl>
      <List sx={{ paddingTop: 0 }}>
        {selectedScheduleCategory ===
        systemChoices.scheduleCategories.template ? (
          <>
            <FormControl fullWidth>
              <FormLabel id="schedule-templates">Templates:</FormLabel>
              <NativeSelect
                // disabled={creating || editing || verifying}
                style={{ width: '100%', marginTop: '5px' }}
                value={selectedTemplateId}
                onChange={(event) => {
                  handleSelectedTemplateId(event.target.value);
                }}
              >
                <option key="0" value="0">
                  Select a Template
                </option>
                <optgroup label="Compatible Templates:">
                  {templates
                    ? templates.map(
                        (template: ThermostatScheduleTemplateRowData) => (
                          <option key={template._id} value={template._id}>
                            {template.name}
                          </option>
                        ),
                      )
                    : []}
                </optgroup>
                <optgroup label="Incompatible Templates:">
                  {incompatibleTemplates
                    ? incompatibleTemplates.map(
                        (template: ThermostatScheduleTemplateRowData) => (
                          <option
                            key={template._id}
                            value={template._id}
                            disabled
                          >
                            {template.name}
                          </option>
                        ),
                      )
                    : []}
                </optgroup>
                <optgroup label="Template Actions">
                  <option key="-1" value="-1">
                    Create a new Template
                  </option>
                </optgroup>
              </NativeSelect>
            </FormControl>
            {template && !thermostat.useCustomSetpointLimits ? (
              <>
                <ThermostatScheduleTemplateDetail template={template} />
                <ThermostatScheduleAction
                  targetLevel="thermostat"
                  handleOpenThermostatScheduleDetailDialog={handleOpen}
                  handleResetSelection={handleResetSelection}
                  handleSelectedTemplateId={handleSelectedTemplateId}
                  showApply={showApply}
                  action={action}
                  unitId={unit._id}
                  propertyId={property._id}
                  setViewMode={setViewMode}
                  handleSetAction={handleSetAction}
                  setShowApply={setShowApply}
                  thermostat={thermostat ?? {}}
                  template={template ?? {}}
                />
              </>
            ) : null}
          </>
        ) : null}
        {selectedScheduleCategory ===
        systemChoices.scheduleCategories.custom ? (
          <Schedule
            thermostat={thermostat}
            schedule={schedule}
            scheduleType={scheduleType}
            handleChange={handleChange}
            handleTypeChange={handleTypeChange}
            temperatureUnit={temperatureUnit}
            lastModified={lastModified}
          />
        ) : null}
        {selectedScheduleCategory ===
        systemChoices.scheduleCategories.default ? (
          <>
            {defaultTemplate ? (
              <ThermostatScheduleTemplateDetail template={defaultTemplate} />
            ) : null}
          </>
        ) : null}
      </List>
      <ThermostatScheduleDetailDialog
        templateId={template?._id ?? ''}
        open={open}
        disableSetpointProfileSelection={true}
        unit={unit}
        thermostat={thermostat}
        handleClose={handleClose}
      />
    </BorderedSection>
  ) : (
    <div>No Thermostat Device Found</div>
  );
}
