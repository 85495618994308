import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ThermostatScheduleStatistics } from '../devices/thermostat/types/thermostat-schedule-statistics';
import { ThermostatScheduleUpdateStatistics } from '../devices/thermostat/types/thermostat-schedule-update-statistics';
import BorderedSection from './borderedSection';

interface VerifyPropertyUnitThermostatScheduleUpdate {
  forceUpdate: boolean;
  level: string;
  action: string;
  scheduleStats: ThermostatScheduleStatistics;
  thermostatScheduleUpdateStats?: ThermostatScheduleUpdateStatistics;
}

export const VerifyPropertyUnitThermostatScheduleUpdate = (
  props: VerifyPropertyUnitThermostatScheduleUpdate,
) => {
  const {
    forceUpdate,
    level,
    action,
    scheduleStats,
    thermostatScheduleUpdateStats,
  } = props;

  const theme = useTheme();

  let StatisticsComponent = <div>N/A</div>;

  switch (action) {
    case 'apply':
      StatisticsComponent = (
        <ApplyStatisticsComponent
          forceUpdate={forceUpdate}
          level={level}
          action={action}
          scheduleStats={scheduleStats}
          thermostatScheduleUpdateStats={thermostatScheduleUpdateStats}
        />
      );
      break;
    case 'remove':
      StatisticsComponent = (
        <RemoveStatisticsComponent
          forceUpdate={forceUpdate}
          level={level}
          action={action}
          scheduleStats={scheduleStats}
          thermostatScheduleUpdateStats={thermostatScheduleUpdateStats}
        />
      );
      break;
    default:
  }

  return (
    <div>
      <h3>Should you proceed, your update will have the following impact:</h3>
      <div style={{ margin: '16px 0px' }}>
        <BorderedSection title="Thermostats">
          {StatisticsComponent}
        </BorderedSection>
      </div>
      {level !== 'unit' ? (
        <BorderedSection title="Units">
          <ul style={{ paddingLeft: '16px', margin: 0 }}>
            <li>
              <strong>All:</strong> {scheduleStats.totalUnits}
            </li>
            <li>
              <strong>Using a Schedule Template:</strong>{' '}
              {scheduleStats.unitsWithAppliedThermostatScheduleTemplate}
            </li>
            <li>
              <strong>Net Units to be Updated:</strong>{' '}
              {(forceUpdate && (scheduleStats.totalUnits ?? 0)) ||
                (scheduleStats.unitsWithoutAppliedThermostatScheduleTemplate ??
                  0)}
            </li>
          </ul>
        </BorderedSection>
      ) : null}
    </div>
  );
};

function RemoveStatisticsComponent({
  scheduleStats,
  forceUpdate,
  thermostatScheduleUpdateStats,
  action,
  level,
}: {
  forceUpdate: boolean;
  level: string;
  action: string;
  scheduleStats: ThermostatScheduleStatistics;
  thermostatScheduleUpdateStats?: ThermostatScheduleUpdateStatistics;
}) {
  const theme = useTheme();

  return (
    <ul style={{ paddingLeft: '16px', margin: 0 }}>
      <li>
        <strong>All:</strong> {scheduleStats.totalThermostats}
      </li>
      <li>
        <strong>Installed:</strong> {scheduleStats.installedThermostats}
      </li>
      <li>
        <strong>Installed and Online:</strong>{' '}
        {forceUpdate && scheduleStats.installedOnlineThermostats.length > 0 ? (
          <span style={{ color: theme.embue.good }}>
            {scheduleStats.installedOnlineThermostats.length}
          </span>
        ) : (
          <span>{scheduleStats.installedOnlineThermostats.length}</span>
        )}
      </li>
      <li>
        <strong>Online and Using Custom Schedule:</strong>{' '}
        {scheduleStats.installedOnlineThermostatsAndUsesCustomSchedule.length}
      </li>
      <li>
        <strong>Online and Using Schedule Template:</strong>{' '}
        {
          <span>
            {
              scheduleStats.installedOnlineThermostatsAndUsesScheduleTemplate
                .length
            }
          </span>
        }
      </li>
      <li>
        <strong>Online and Using Property-Level Template:</strong>{' '}
        {scheduleStats.thermostatsUsingPropertyLevelScheduleTemplate?.length >
          0 &&
        level === 'property' &&
        !forceUpdate ? (
          <span style={{ color: theme.embue.good }}>
            {
              scheduleStats.thermostatsUsingPropertyLevelScheduleTemplate
                ?.length
            }
          </span>
        ) : (
          <span>
            {
              scheduleStats.thermostatsUsingPropertyLevelScheduleTemplate
                ?.length
            }
          </span>
        )}
      </li>
      <li>
        <strong>Online and Using Unit-Level Template:</strong>{' '}
        {scheduleStats.thermostatsUsingUnitLevelScheduleTemplate?.length > 0 &&
        level === 'unit' ? (
          <span style={{ color: theme.embue.good }}>
            {scheduleStats.thermostatsUsingUnitLevelScheduleTemplate?.length}
          </span>
        ) : (
          <span>
            {scheduleStats.thermostatsUsingUnitLevelScheduleTemplate?.length}
          </span>
        )}
      </li>
      <li>
        <strong>Net Thermostats to be Updated:</strong>{' '}
        <span style={{ color: theme.embue.cooling }}>
          {thermostatScheduleUpdateStats?.netThermostatsToBeUpdated.length}
        </span>
      </li>
    </ul>
  );
}

function ApplyStatisticsComponent({
  scheduleStats,
  forceUpdate,
  thermostatScheduleUpdateStats,
  action,
  level,
}: {
  forceUpdate: boolean;
  level: string;
  action: string;
  scheduleStats: ThermostatScheduleStatistics;
  thermostatScheduleUpdateStats?: ThermostatScheduleUpdateStatistics;
}) {
  const theme = useTheme();

  return (
    <ul style={{ paddingLeft: '16px', margin: 0 }}>
      <li>
        <strong>All:</strong> {scheduleStats.totalThermostats}
      </li>
      <li>
        <strong>Installed:</strong> {scheduleStats.installedThermostats}
      </li>
      <li>
        <strong>Installed and Online:</strong>{' '}
        <span style={{ color: theme.embue.good }}>
          {scheduleStats.installedOnlineThermostats.length}
        </span>
      </li>
      <li>
        <strong>Online and Using Custom Schedule:</strong>{' '}
        {scheduleStats.installedOnlineThermostatsAndUsesCustomSchedule.length}
      </li>
      <li>
        <strong>Online and Using Schedule Template:</strong>{' '}
        {forceUpdate ||
        scheduleStats.installedOnlineThermostatsAndUsesScheduleTemplate
          .length <= 0 ? (
          <span>
            {
              scheduleStats.installedOnlineThermostatsAndUsesScheduleTemplate
                .length
            }
          </span>
        ) : (
          <span style={{ color: theme.embue.bad }}>
            {`-(${scheduleStats.installedOnlineThermostatsAndUsesScheduleTemplate.length})`}
          </span>
        )}
      </li>
      <li>
        <strong>Online and Using Property-Level Template:</strong>{' '}
        <span>
          {scheduleStats.thermostatsUsingPropertyLevelScheduleTemplate?.length}
        </span>
      </li>
      <li>
        <strong>Online and Using Unit-Level Template:</strong>{' '}
        {level === 'unit' ||
        forceUpdate ||
        scheduleStats.thermostatsUsingUnitLevelScheduleTemplate?.length ===
          0 ? (
          <span>
            {scheduleStats.thermostatsUsingUnitLevelScheduleTemplate?.length}
          </span>
        ) : (
          <span style={{ color: theme.embue.bad }}>
            {`-(${scheduleStats.thermostatsUsingUnitLevelScheduleTemplate?.length})`}
          </span>
        )}
      </li>
      <li>
        <strong>Net Thermostats to be Updated:</strong>{' '}
        <span style={{ color: theme.embue.cooling }}>
          {thermostatScheduleUpdateStats?.netThermostatsToBeUpdated.length}
        </span>
      </li>
    </ul>
  );
}
