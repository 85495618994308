/**
 * Thermostat Display Screen
 * - prominently display the three main readings of the thermostat
 * - if offline display a warning lable with a timestamp
 */
import React, { ChangeEvent, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { SetPoints } from '..';
import { DisplayValue } from '../../../shared-ui';
import { ViewMode } from '../../modbus-air-handler';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { CurrentAttributes, ModbusAirHandler } from '../../types';
import { TemperatureUnit } from '../../../../../system/models/temperatureUnits';
import BorderedSection from '../../../../shared/borderedSection';
import { useTheme } from '@mui/material/styles';
import { Switch, useMediaQuery } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { displayUnits } from '../../../shared-ui/display-value';

interface DetectedAlarm {
  key: string;
  label: string;
}
interface DisplayScreenProps {
  device: Partial<ModbusAirHandler>;
  values: CurrentAttributes;
  viewMode?: ViewMode;

  preferredTemperatureUnits?: TemperatureUnit;
  preferredAirflowUnits?: string;

  editable: boolean;

  handleTargetChange: (
    targetMode: 'rhSetpoint' | 'freshAirSetpoint',
    delta: number,
  ) => void;
  handleChange: (
    changes: Record<string, number | string | boolean>,
    isValid: boolean,
  ) => void;
  handleFilterResetRequest: (key: keyof CurrentAttributes) => void;
}

const alarmKeys = [
  'alarmGlobalAlarm',
  'alarmLowPressure',
  'alarmHighPressure',
  'alarmChangeFilters',
  'alarmChangeHepa',
  'alarmAirRestriction',
  'alarmSupplyFanStopped',
  'alarmExhaustFanStopped',
  'alarmRefrigerantLeak',
  'alarmCompressorFail',
  'alarmFanANegPressureOpt1',
  'alarmFanANegPressureOpt2',
  'alarmFanABReversed',
  'alarmClockBoard',
  'alarmExtendedMemory',
  'alarmB1SensorFail',
  'alarmB2SensorFail',
  'alarmB3SensorFail',
  'alarmB4SensorFail',
  'alarmB6SensorFail',
  'zmbGlobalAlarm',
  'zmbThermostatAlarm',
  'zmbAirHandlerAlarm',
  'zmbLeakDetected',
];

export function DisplayScreen(props: DisplayScreenProps) {
  const {
    device,
    values,
    viewMode,

    preferredTemperatureUnits,
    preferredAirflowUnits,

    editable,

    handleTargetChange,
    handleChange,
    handleFilterResetRequest,
  } = props;

  const {
    systemOnOff,

    freshAirSetpoint,
    rhSetpoint,

    indoorTemperature,
    outsideTemperature,
    indoorRH,

    auxCoolingEnabled,
    winterRhAdjustEnabled,
    showerDetectorEnabled,

    wTerminalOn,
    yTerminalOn,
  } = values;

  const { timestamp } = device;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [alarms, setAlarms] = React.useState<DetectedAlarm[]>([]);

  useEffect(() => {
    if (values && device) {
      const newAlarms = [];
      for (const key of Object.keys(values)) {
        if (alarmKeys.includes(key) && values[key as keyof CurrentAttributes]) {
          const label =
            device.deviceMetrics?.find((metric) => metric?.key === key)?.value
              .description ?? key;

          newAlarms.push({
            key,
            label: label.startsWith('Alarm - ') ? label.slice(8) : label,
          });
        }
      }
      setAlarms(newAlarms);
    }
  }, [device, values]);

  const border = (viewMode === ViewMode.OFFLINE && '5px solid red') || '';
  let formatTimestamp = '';

  if (timestamp && timestamp > 0) {
    formatTimestamp = dayjs(timestamp).format('LLLL');
  }

  return (
    <Box sx={{ border, px: 0, py: 0 }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        divider={<Divider orientation="vertical" flexItem />}
      ></Stack>
      {/* Display current temp, set temperatures, and current humidity */}
      {viewMode === ViewMode.NORMAL ? (
        <BorderedSection
          title="Alarms"
          style={{
            margin: '12px 6px 0px 6px',
            padding: '0px',
            backgroundColor: '#f4f5e6',
          }}
          ccStyle={{
            backgroundColor: alarms.length ? '#ffd5d5' : 'inherit',
            borderRadius: '3px',
            padding: '0px 4px 0px 0px',
            margin: '-6px 0px 0px 0px',
          }}
          error={alarms.length > 0}
        >
          {alarms.length > 0 ? (
            <ul>
              {alarms.map((alarm) => (
                <li key={alarm.key}>{alarm.label}</li>
              ))}
            </ul>
          ) : (
            <Typography
              sx={{ m: 0 }}
              variant="h5"
              style={{ margin: '3px 6px', color: 'darkgreen' }}
            >
              None Detected
            </Typography>
          )}
        </BorderedSection>
      ) : null}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        // divider={<Divider orientation="vertical" flexItem />}
      >
        <BorderedSection
          title="Readings"
          style={{
            margin: '6px',
            marginTop: '10px',
            backgroundColor: '#f4f5e6',
          }}
          ccStyle={{ margin: '4px', padding: '0px', marginTop: '-6px' }}
        >
          <DisplayValue
            value={indoorTemperature}
            label={isSmall ? 'Indoor Temp' : 'Indoor Temperature'}
            labelStyle={{ marginTop: '-6px' }}
            units={preferredTemperatureUnits}
            mode={wTerminalOn ? 'Heat' : yTerminalOn ? 'Cool' : 'Off'}
            valueSize="24px"
            unitsSize="16px"
          />
          <DisplayValue
            value={outsideTemperature}
            label={isSmall ? 'Outside Temp' : 'Outside Temperature'}
            labelStyle={{ marginTop: '-6px' }}
            units={preferredTemperatureUnits}
            mode={'Off'}
            valueSize="24px"
            unitsSize="16px"
          />
          <DisplayValue
            value={indoorRH}
            units={'%'}
            label={
              isSmall ? 'Indoor Rel. Humidity' : 'Indoor Relative Humidity'
            }
            labelStyle={{ marginTop: '-6px' }}
            mode="Humidity"
            valueSize="24px"
            unitsSize="16px"
          />
        </BorderedSection>
        {(systemOnOff?.toUpperCase() ?? '') === 'OFF' ? (
          <BorderedSection
            style={{
              margin: '11px 6px 8px 0px',
              backgroundColor: '#f4f5e6',
            }}
            ccStyle={{
              margin: '4px',
              padding: '10px 0px 5px 0px',
              maxHeight: '146px',
            }}
            title={'Setpoints'}
          >
            <Stack alignItems={'center'} justifyContent={'center'}>
              {/*<div>&nbsp;</div>*/}
              <div>AHU Device is</div>
              <div
                style={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'row',
                  fontSize: '35px',
                  color: 'gray',
                  textAlign: 'center',
                }}
              >
                Off
              </div>
              <div style={{ flex: 1 }}>&nbsp;</div>
              <div style={{ fontSize: 'xx-small' }}>
                <span>
                  Fresh Air: {freshAirSetpoint}{' '}
                  {displayUnits(preferredAirflowUnits)}
                </span>
              </div>
              <div style={{ fontSize: 'xx-small' }}>
                <span>
                  {isSmall ? 'RH' : 'Relative Humidity'}: {freshAirSetpoint}
                  {displayUnits('%')}
                </span>
              </div>
            </Stack>
          </BorderedSection>
        ) : (
          <SetPoints
            freshAirSetpoint={freshAirSetpoint}
            rhSetpoint={rhSetpoint}
            units={preferredAirflowUnits}
            editable={
              viewMode === ViewMode.EDIT || viewMode === ViewMode.EDIT_UNCHANGED
            }
            handleTargetChange={handleTargetChange}
          />
        )}
      </Stack>
      {viewMode === ViewMode.OFFLINE ? (
        <Box
          sx={{
            margin: '0px',
            marginTop: '4px',
            marginY: '4px',
            padding: '0px',
            width: '100%',
          }}
          height="auto"
          textAlign={'center'}
        >
          <Button
            disabled
            variant={'contained'}
            sx={{
              width: '95%',
              fontSize: '12px',
              '&.Mui-disabled': {
                backgroundColor: 'red',
                color: 'white',
              },
            }}
          >
            <Stack>
              <Typography variant={'h6'}>Device Offline</Typography>
              <Typography variant={'h6'}>{formatTimestamp}</Typography>
            </Stack>
          </Button>
        </Box>
      ) : null}
      {viewMode === ViewMode.NORMAL ? (
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'space-evenly'}
          // divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack
            direction={'row'}
            // alignItems={'center'}
            // justifyContent={'space-evenly'}
            style={{ width: '100%' }}
            // divider={<Divider orientation="vertical" flexItem />}
          >
            <BorderedSection
              title="Hardware Settings"
              style={{
                margin: '6px',
                width: '220px',
                backgroundColor: '#f4f5e6',
              }}
            >
              <FormGroup style={{ padding: '0px' }}>
                {[
                  { key: 'auxCoolingEnabled', label: 'Auxiliary Cooling' },
                  {
                    key: 'winterRhAdjustEnabled',
                    label: 'Winter RH Adjustment',
                  },
                  { key: 'showerDetectorEnabled', label: 'Shower Detector' },
                ].map(({ key, label }) => (
                  <div
                    key={key}
                    style={{
                      border: '1px solid black',
                      borderRadius: '4px',
                      padding: '2px',
                      margin: '2px 4px',
                      backgroundColor: values[key as keyof CurrentAttributes]
                        ? 'green'
                        : 'lightgray',
                      textAlign: 'center',
                    }}
                  >
                    {label}
                  </div>
                ))}
              </FormGroup>
            </BorderedSection>
            {viewMode === ViewMode.NORMAL ? (
              <BorderedSection
                title="Terminals"
                style={{
                  margin: '6px',
                  marginTop: '6px',
                  backgroundColor: '#f4f5e6',
                }}
                ccStyle={{
                  margin: '0px',
                  padding: '3px 4px 4px 4px',
                  marginTop: '-4px',
                }}
              >
                {[
                  { key: 'gTerminalOn', label: 'Fan' },
                  { key: 'hTerminalOn', label: 'Humidifier' },
                  { key: 'wTerminalOn', label: 'Heat' },
                  { key: 'yTerminalOn', label: 'Cool' },
                ].map(({ key, label }) => (
                  <div
                    key={key}
                    style={{
                      border: '1px solid black',
                      borderRadius: '4px',
                      padding: '2px',
                      margin: '2px 4px',
                      backgroundColor: values[key as keyof CurrentAttributes]
                        ? 'green'
                        : 'lightgray',
                      textAlign: 'center',
                    }}
                  >
                    {label}
                  </div>
                ))}
              </BorderedSection>
            ) : null}
          </Stack>

          <BorderedSection
            title="Filter Status"
            style={{
              margin: '6px',
              backgroundColor: '#f4f5e6',
            }}
            ccStyle={
              editable ||
              values.resetFiltersServiceTime ||
              values.resetHepaServiceTime
                ? {}
                : {
                    paddingLeft: isSmall ? '55px' : '60px',
                  }
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 auto',
                  padding: '4px',
                  width: '180px',
                  textAlign: 'right',
                }}
              >
                MERV8 filtration hours:
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                  // textAlign: 'right',
                  backgroundColor: values.alarmChangeFilters
                    ? 'red'
                    : 'transparent',
                  padding: '4px 4px',
                }}
              >
                {values.filtersServiceTime}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 auto',
                }}
              >
                {editable ? (
                  <Button
                    disabled={values.resetFiltersServiceTime}
                    variant={'contained'}
                    sx={{
                      width: '95%',
                      fontSize: '12px',
                      '&.Mui-disabled': {
                        backgroundColor: 'lightgray',
                        color: 'white',
                      },
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleFilterResetRequest('resetFiltersServiceTime');
                    }}
                  >
                    {values.resetFiltersServiceTime ? 'Resetting' : 'Reset'}
                  </Button>
                ) : values.resetFiltersServiceTime ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '0 1 auto',
                      padding: '4px 0px',
                    }}
                  >
                    Resetting ...
                  </div>
                ) : null}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 auto',
                  padding: '4px',
                  width: '180px',
                  textAlign: 'right',
                }}
              >
                HEPA filtration hours:
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                  // textAlign: 'right',
                  backgroundColor: values.alarmChangeHepa
                    ? 'red'
                    : 'transparent',
                  padding: '4px 4px',
                }}
              >
                {values.hepaServiceTime}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 auto',
                }}
              >
                {editable ? (
                  <Button
                    disabled={values.resetHepaServiceTime}
                    variant={'contained'}
                    sx={{
                      width: '95%',
                      fontSize: '12px',
                      '&.Mui-disabled': {
                        backgroundColor: 'lightgray',
                        color: 'white',
                      },
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleFilterResetRequest('resetHepaServiceTime');
                    }}
                  >
                    {values.resetHepaServiceTime ? 'Resetting' : 'Reset'}
                  </Button>
                ) : values.resetHepaServiceTime ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '0 1 auto',
                      padding: '4px 0px',
                    }}
                  >
                    Resetting ...
                  </div>
                ) : null}
              </div>
            </div>
          </BorderedSection>
        </Stack>
      ) : null}
    </Box>
  );
}
