import React, { FC, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { App } from '@capacitor/app';

import { SystemShell } from './SystemShell';
import { useSystemConnection } from './ConnectionManager';
import { Log, LogCategory } from './services/logger';
import { isAndroid } from 'react-device-detect';

interface ConnectedApolloProviderProps {
  children: React.ReactNode;
}

export const ConnectedApolloProvider: FC<ConnectedApolloProviderProps> = ({
  children,
}) => {
  const { client, connected, loading } = useSystemConnection();

  Log.silly('rendering apollo provider', null, LogCategory.RENDERING);

  // On mobile devices, reload the page whenever the app returns to the foreground. This is required in order to
  // reset the websocket client connection and resume graphql subscriptions (at least on iOS). Without calling this,
  // the app will stop receiving subscription updates anytime it enters the background.
  const isMobile = Capacitor.isNativePlatform();
  useEffect(() => {
    let listener: PluginListenerHandle | undefined;
    async function addResumeHandler() {
      listener = await App.addListener('resume', () => {
        if (
          !isAndroid ||
          (isAndroid &&
            !window.location.pathname.endsWith('/passkeys') &&
            window.location.pathname !== '/login')
        ) {
          window.location.reload();
        }
      });
    }

    if (isMobile) {
      addResumeHandler().then(() => {
        // intentionally do nothing ... satisfies typescript checking.
      });
    }

    return () => {
      listener?.remove();
    };
  }, [isMobile]);

  return client ? (
    <ApolloProvider client={client}>
      {!loading && connected ? children : <SystemShell />}
    </ApolloProvider>
  ) : (
    <div>System loading ...</div>
  );
};
