import React, { ReactElement, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Form, Formik, useFormik } from 'formik';
import * as yup from 'yup';

import { useAuthenticator } from '../../../auth/AuthenticationContext';
import { Notifier } from '../../../system/services/notificationManager';
import { useInjectableComponents } from '../../../system/services/injectableComponentsManager';

import { SHA256 } from '../../../auth/helpers/sha';

import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
// import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  AuthenticationProfileFactors,
  AuthenticatorDevice,
  EnumAuthenticationProfileFactorsType,
  TemperatureUnit,
  AirFlowUnit,
  useChangePasswordMutation,
  User,
  useUpdateUserIdentificationMutation,
} from '../../../../types/generated-types';

import { FormValues } from '../types/userProfileFormValues';
import { ColoredLinearProgress } from '../../shared/linear-progress';
import { UserIdentificationTab } from '../components/user-identification-tab';
import { FactorPasswordTab } from '../components/factor-password-tab';
import { FactorPasskeys } from '../components/factor-passkeys';

import { AlertManagementTab } from '../components/alert-management/alert-management-tab';
import { formatPhoneNumber } from '../../../../common/helpers';

const ViewIdToValueMap: Record<string, number> = {
  identification: 0,
  password: 1,
  passkeys: 2,
  alerts: 3,
};

const ValueToViewIdMap: Record<number, string> = {
  0: 'identification',
  1: 'password',
  2: 'passkeys',
  3: 'alerts',
};

export const SimpleUserForm = ({
  user,
  viewId,
}: {
  user: Partial<User>;
  viewId?: string;
}): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const auth = useAuthenticator();
  const [updateUser] = useUpdateUserIdentificationMutation();

  const [updatePassword] = useChangePasswordMutation();

  const theme = useTheme();
  const _isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSmall, setIsSmall] = React.useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (_isSmall) {
        setIsSmall(true);
      } else if (window.innerWidth < 710) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [_isSmall]);

  const initialValues: FormValues = {
    credentialsAreExternal: false,
    enforceUserVerification: true,
    selectedAttachment: 'platform',
    name: user?.name || '',
    email: user?.email || '',
    hasPassword: !!user?.editableAuthenticationProfile?.factors?.some(
      (factor) =>
        factor && factor.type === EnumAuthenticationProfileFactorsType.Password,
    ),
    mobile: formatPhoneNumber(user?.mobile) || '',
    smsMobile: formatPhoneNumber(user?.mobile) || '',
    changePassword: false,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    usesPassword: true,
    hasPasskey: false,
    validPasswordFactor: false,
    preferredUnits: user?.preferredUnits ?? TemperatureUnit.F,
    preferredAirFlowUnits: user?.preferredAirFlowUnits ?? AirFlowUnit.Cfm,
    authenticatorDevices: (user?.editableAuthenticationProfile
      ?.authenticatorDevices ?? []) as Partial<AuthenticatorDevice>[],
    passkeys: (user?.editableAuthenticationProfile?.factors ?? []).filter(
      (factor) =>
        factor &&
        factor.type &&
        factor.type === EnumAuthenticationProfileFactorsType.Passkey,
    ) as Partial<AuthenticationProfileFactors>[],
    passkeyName: '',
    passkeyType: '',
    passkeyDeviceName: '',
    preferredPasskey: false,
    selectedPasskeyId: undefined,
    linkAlexa: false,
    validAlerts: false,
    emailOptInStatus: user?.emailOptInStatus ?? 'unsent',
    emailOptInStatusTimestamp: user?.emailOptInStatusTimestamp ?? 0,
    smsOptInConsent:
      user?.smsOptInStatus !== undefined &&
      user?.smsOptInStatus !== '' &&
      !['unsent', 'rejected', 'opted_out', 'removed'].includes(
        user?.smsOptInStatus ?? 'unsent',
      ),
    smsOptInStatus: user?.smsOptInStatus || 'unsent',
    smsOptInStatusTimestamp: user?.smsOptInStatusTimestamp ?? undefined,
  };

  const onSubmit = (values: FormValues) => {
    if (user) {
      if (value === 0) {
        if (
          user._id &&
          values.email &&
          values.mobile &&
          values.name &&
          values.preferredUnits &&
          values.preferredAirFlowUnits
        ) {
          updateUser({
            variables: {
              userId: user._id,
              name: values.name,
              mobile: values.mobile,
              email: values.email,
              preferredUnits: values.preferredUnits,
              preferredAirFlowUnits: values.preferredAirFlowUnits,
            },
          })
            .then((_res) => {
              Notifier.success('Your profile has been updated.');
              formik.setSubmitting(false);
              formik.resetForm({
                values: {
                  ...formik.values,
                  smsMobile: formatPhoneNumber(values.mobile),
                },
              });
            })
            .catch((userUpdateError) => {
              formik.setSubmitting(false);
              Notifier.error(userUpdateError.message);
            });
        } else {
          Notifier.error(
            'Unable to process request. Please contact Embue support.',
          );
        }
      } else if (value === 1) {
        if (
          user._id &&
          values.oldPassword &&
          values.newPassword &&
          values.confirmPassword
        ) {
          const hashedNewPassword = SHA256(values.newPassword);
          updatePassword({
            variables: {
              userId: user._id,
              oldPassword: SHA256(values.oldPassword),
              newPassword: hashedNewPassword,
              confirmPassword: hashedNewPassword,
            },
          })
            .then((_res) => {
              Notifier.success('Your password has been updated.');
              formik.resetForm({
                values: {
                  ...formik.values,
                  newPassword: '',
                  confirmPassword: '',
                  oldPassword: '',
                  changePassword: false,
                },
              });
              formik.setSubmitting(false);
            })
            .catch((updatePasswordError: any) => {
              formik.setSubmitting(false);
              Notifier.error(updatePasswordError.message);
            });
        }
      }
    }
  };

  const validationSchema = yup
    .object({
      name: yup.string().required('Name is required'),
      mobile: yup
        .string()
        .matches(/^(\(\d{3}\) \d{3}-\d{4})$|^\d{10}$/, 'Invalid mobile number')
        .required('Mobile number is required'),
      smsMobile: yup
        .string()
        .matches(/^(\(\d{3}\) \d{3}-\d{4})$|^\d{10}$/, 'Invalid mobile number'),
      // .required('Mobile number is required'),
      email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
      oldPassword: yup.string().when(['changePassword', 'hasPassword'], {
        is: (changePassword: boolean, hasPassword: boolean) =>
          changePassword && hasPassword,
        then: yup
          .string()
          .required('You must provide your old password.')
          .not(
            [''],
            'You must provide your old password in order to update it to a new value.',
          ),
      }),
      newPassword: yup.string().when(['changePassword'], {
        is: (changePassword: boolean) => changePassword,
        then: yup
          .string()
          .required('You must provide a new password value.')
          .not(
            [''],
            'You must provide a new password value (cannot be blank).',
          ),
      }),
      confirmPassword: yup.string().when(['changePassword'], {
        is: (changePassword: boolean) => changePassword,
        then: yup
          .string()
          .required(
            'You must confirm your new password value (type it a second time).',
          )
          .not(
            [''],
            'You must confirm your new password value (cannot be blank).',
          ),
      }),
      validPasswordFactor: yup.boolean(),
      changePassword: yup.boolean(),
      hasPassword: yup.boolean(),
      usesPassword: yup.boolean(),
      linkAlexa: yup.boolean(),
      validAlerts: yup.boolean(),
      emailOptInStatus: yup.string(),
      emailOptInStatusTimestamp: yup.number(),
      smsOptInConsent: yup.boolean(),
      smsOptInStatus: yup.string(),
      smsOptInStatusTimestamp: yup.number(),
    })
    .test(
      'mustProvideMobileNumberToSubscribeToSMSAlerts',
      'You must provide a mobile number to subscribe to SMS alerts.',
      (obj) => {
        if (
          obj.smsOptInStatus !== 'unsent' &&
          (!obj.smsMobile || formik.errors.smsMobile)
        ) {
          return new yup.ValidationError(
            'You must provide a mobile number to subscribe to SMS alerts.',
            null,
            'validAlerts',
          );
        } else {
          return true;
        }
      },
    )
    .test(
      'mustProvideDoublePassword',
      'Must provide a new password and confirm it',
      (obj) => {
        if (
          obj.changePassword &&
          !obj.hasPassword &&
          (!obj.newPassword || !obj.confirmPassword)
        ) {
          return new yup.ValidationError(
            'Provide both a new password and confirm it',
            null,
            'validPasswordFactor',
          );
        } else {
          return true;
        }
      },
    )
    .test(
      'mustExistingPassword',
      'Must provide your existing password, a new password and confirm it',
      (obj) => {
        if (
          obj.changePassword &&
          obj.hasPassword &&
          (!obj.oldPassword || !obj.newPassword || !obj.confirmPassword)
        ) {
          return new yup.ValidationError(
            'Provide your existing password as well as both a new password and confirm it',
            null,
            'validPasswordFactor',
          );
        } else {
          return true;
        }
      },
    )
    .test(
      'passwordsMustMatch',
      'New password must match the password confirmation value',
      (obj) => {
        if (obj.newPassword !== obj.confirmPassword) {
          return new yup.ValidationError(
            'New password must match password confirmation value',
            null,
            'validPasswordFactor',
          );
        } else {
          return true;
        }
      },
    );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit,
  });

  const ActivityIndicator = (props: { activityInProgress: boolean }) => {
    return props.activityInProgress ? (
      <ColoredLinearProgress />
    ) : (
      <Box style={{ height: '22px' }}>&nbsp;</Box>
    );
  };

  const [value, setValue] = React.useState<number>(0);

  useEffect(() => {
    if (viewId) {
      const initialTabId: number = ViewIdToValueMap[viewId];
      setValue(initialTabId);
    } else {
      navigate('identification', { replace: true });
    }
  }, [viewId, navigate]);

  const isFormEmpty =
    formik.values.oldPassword +
    formik.values.newPassword +
    formik.values.confirmPassword;
  const updateButtonOptions =
    value === 0
      ? 'Update'
      : value === 1
        ? formik.values.hasPassword
          ? 'Update'
          : 'Set Password'
        : 'Unknown';
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    const pathParts = pathname.split('/');
    pathParts.pop();
    pathParts.push(ValueToViewIdMap[newValue]);
    const newPath = pathParts.join('/');
    if (formik.dirty && isFormEmpty && formik.values.changePassword) {
      formik.resetForm();
      navigate(newPath);
    } else if (formik.dirty) {
      Notifier.warn('Please save your changes before switching tabs.');
    } else {
      navigate(newPath);
    }
  };

  const tabStyles = {
    error: {
      color: 'red',
      fontWeight: '600',
    },
    normal: {
      fontWeight: 'inherit',
    },
  };

  const { setTitle, setTitlePath, setContextMenuItems } =
    useInjectableComponents();

  useEffect(() => {
    setTitle('Update User Profile');
    setTitlePath('/properties');
    // setContextMenuItems([
    //   {
    //     label: 'Help',
    //     items: [
    //       {
    //         id: 'pendo-help-profile-passkeys',
    //         label: 'Help with Passkeys',
    //         icon: <HelpIcon fontSize="small" />,
    //         permit: {
    //           action: 'updateProfile',
    //           subject: user as User,
    //         },
    //       },
    //     ],
    //   },
    // ]);

    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setContextMenuItems(undefined);
    };
  }, [setContextMenuItems, setTitle, setTitlePath]);

  return auth.user && user ? (
    <div
      style={{
        // margin: '0px 4px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      {/* <h2 style={{ margin: '16px 6px 0px 20px' }}>User Profile Settings</h2> */}
      <Typography
        variant="h3"
        sx={{ fontSize: '1.4rem', margin: '1rem', textAlign: 'center' }}
      >
        {formik.initialValues.name}
      </Typography>
      <Formik initialValues={formik.initialValues} onSubmit={onSubmit}>
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            alignItems: 'center',
            fontSize: '16px',
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              flex: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              centered
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { color: '#000' } }}
              indicatorColor="secondary"
              textColor="secondary"
              sx={{
                '& .MuiButtonBase-root': {
                  padding: '12px 9px',
                },
              }}
            >
              <Tab
                label={isSmall ? 'Profile' : 'User Identification'}
                style={
                  formik.errors.email ||
                  formik.errors.name ||
                  formik.errors.mobile
                    ? tabStyles.error
                    : tabStyles.normal
                }
                id="user-profile-tab"
              />
              <Tab
                disabled={!formik.values.usesPassword}
                label={
                  isSmall
                    ? 'Password'
                    : formik.values.hasPassword
                      ? 'Change Password'
                      : 'Set Password'
                }
                style={
                  formik.errors.oldPassword ||
                  formik.errors.newPassword ||
                  formik.errors.confirmPassword
                    ? tabStyles.error
                    : tabStyles.normal
                }
                id="factor-password-tab"
              />
              <Tab
                // disabled={!formik.values.usesWebauthn}
                label={isSmall ? 'Passkeys' : 'Manage Passkeys'}
                style={{
                  ...(formik.errors.passkeyType || formik.errors.passkeyName
                    ? tabStyles.error
                    : tabStyles.normal),
                }}
                id="factor-passkeys-tab"
              />
              <Tab
                // disabled={!formik.values.usesWebauthn}
                label={isSmall ? 'Alerts' : 'Manage Alerts'}
                style={{
                  ...(formik.errors.validAlerts
                    ? tabStyles.error
                    : tabStyles.normal),
                }}
                id="alert-management-tab"
              />
            </Tabs>

            <ActivityIndicator activityInProgress={formik.isSubmitting} />
            {value === 0 ? (
              <UserIdentificationTab
                currentUser={auth.user}
                formik={formik}
                apiToken={user.authorizationProfile?.apiToken}
                onChange={(e) => {
                  formik.setFieldTouched((e.target as any).id, true);
                  formik.handleChange(e);
                }}
              />
            ) : value === 1 ? (
              <FactorPasswordTab
                formik={formik}
                onChange={(e) => {
                  formik.setFieldTouched((e.target as any).id, true);
                  formik.handleChange(e);
                }}
              />
            ) : value === 2 ? (
              <FactorPasskeys
                userId={auth.user._id}
                formik={formik}
                onChange={(e) => {
                  formik.setFieldTouched((e.target as any).id, true);
                  formik.handleChange(e);
                }}
              />
            ) : (
              <AlertManagementTab
                userId={auth.user._id}
                formik={formik}
                properties={user?.alertableProperties ?? []}
                onChange={(e) => {
                  formik.setFieldTouched((e.target as any).id, true);
                  formik.handleChange(e);
                }}
              />
            )}
          </Box>
          <div
            style={{
              margin: '20px 0px',
              width: _isSmall ? 'unset' : 'calc(100vw - 110px)',
              alignItems: _isSmall ? 'unset' : 'left',
            }}
          >
            {value !== 2 && value !== 3 ? (
              <Button
                disabled={!formik.isValid || !formik.dirty}
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ color: '#fff' }}
              >
                {updateButtonOptions}
              </Button>
            ) : null}
            <Button
              color={!formik.isValid || !formik.dirty ? 'secondary' : 'primary'}
              sx={{ marginLeft: '10px' }}
              onClick={() => navigate('/properties')}
            >
              {value < 2 ? 'Cancel' : 'Close'}
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  ) : (
    <div>Unauthorized</div>
  );
};
