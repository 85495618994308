import React from 'react';

import { useFormik } from 'formik';

import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Fade, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import BorderedSection from '../../shared/borderedSection';
import { FormValues } from '../types/userProfileFormValues';
import { AuthorizedUser } from '../../../auth/models';

import {
  Celsius,
  CubicFeetPerMinute,
  Fahrenheit,
  LitersPerSecond,
} from '../../../system/models/temperatureUnits';

import { FormikPhoneInput } from '../../shared/formikPhoneInput';

export function UserIdentificationTab(props: {
  currentUser: AuthorizedUser;
  formik: ReturnType<typeof useFormik<FormValues>>;
  apiToken: string | null | undefined;
  onChange: StandardInputProps['onChange'];
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div
        style={{
          margin: '-10px 8px 0px 8px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          border: '1px solid grey',
          borderRadius: '4px',
          padding: '10px',
          width: isSmall ? 'calc(100vw - 16px)' : 'calc(100vw - 80px)',
        }}
      >
        <TextField
          fullWidth
          autoFocus
          id="name"
          name="name"
          placeholder="User Name"
          label="Full Name"
          value={props.formik.values.name}
          onChange={props.onChange}
          error={props.formik.touched.name && Boolean(props.formik.errors.name)}
          helperText={props.formik.touched.name && props.formik.errors.name}
        />
        <br />
        <TextField
          disabled={true}
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={props.formik.values.email}
          onChange={props.onChange}
          error={
            props.formik.touched.email && Boolean(props.formik.errors.email)
          }
          helperText={props.formik.touched.email && props.formik.errors.email}
        />
        <br />
        <FormikPhoneInput formik={props.formik} fieldName="mobile" />
        {/*<TextField*/}
        {/*  fullWidth*/}
        {/*  id="mobile"*/}
        {/*  name="mobile"*/}
        {/*  label="Mobile Number"*/}
        {/*  value={props.formik.values.mobile}*/}
        {/*  onChange={props.onChange}*/}
        {/*  error={*/}
        {/*    props.formik.touched.mobile && Boolean(props.formik.errors.mobile)*/}
        {/*  }*/}
        {/*  helperText={props.formik.touched.mobile && props.formik.errors.mobile}*/}
        {/*/>*/}
        <br />
        <TextField
          id="preferredUnits"
          name="preferredUnits"
          fullWidth
          select
          label="Preferred Units"
          defaultValue="none"
          value={props.formik.values.preferredUnits}
          onChange={(e) => {
            props.formik.setFieldTouched('preferredUnits', true);
            props.formik.handleChange(e);
          }}
          error={
            !!props.formik.touched.preferredUnits &&
            !!props.formik.errors.preferredUnits
          }
          helperText={
            props.formik.touched.preferredUnits &&
            props.formik.errors.preferredUnits
          }
        >
          <MenuItem key="none" value="none">
            None
          </MenuItem>
          {[
            { _id: Celsius, name: 'Celsius' },
            { _id: Fahrenheit, name: 'Fahrenheit' },
          ].map((unitType) => (
            <MenuItem key={unitType._id} value={unitType._id}>
              {unitType.name}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <TextField
          id="preferredAirFlowUnits"
          name="preferredAirFlowUnits"
          fullWidth
          select
          label="Preferred Air Flow Units"
          defaultValue="none"
          value={props.formik.values.preferredAirFlowUnits}
          onChange={(e) => {
            props.formik.setFieldTouched('preferredAirFlowUnits', true);
            props.formik.handleChange(e);
          }}
          error={
            !!props.formik.touched.preferredAirFlowUnits &&
            !!props.formik.errors.preferredAirFlowUnits
          }
          helperText={
            props.formik.touched.preferredAirFlowUnits &&
            props.formik.errors.preferredAirFlowUnits
          }
        >
          <MenuItem key="none" value="none">
            None
          </MenuItem>
          {[
            { _id: CubicFeetPerMinute, name: 'CFM' },
            { _id: LitersPerSecond, name: 'L/s' },
          ].map((unitType) => (
            <MenuItem key={unitType._id} value={unitType._id}>
              {unitType.name}
            </MenuItem>
          ))}
        </TextField>
        <br />
        {props.apiToken && (
          <BorderedSection title="API Access">
            <div style={{ wordBreak: 'break-all' }}>{props.apiToken}</div>
          </BorderedSection>
        )}
      </div>
    </Fade>
  );
}
