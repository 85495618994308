import React, { useEffect, useState } from 'react';

/* MUI */
import { Button, Grid } from '@mui/material';

/* TYPES */
import {
  ThermostatUpdateFields,
  ThermostatUpdateInput,
  useUpdatePropertyThermostatScheduleTemplateOverrideMutation,
  UpdatePropertyThermostatScheduleTemplateOverrideMutationVariables,
  UpdateUnitThermostatScheduleTemplateOverrideMutationVariables,
  useUpdateThermostatMutation,
  useUpdateUnitThermostatScheduleTemplateOverrideMutation,
} from '../../../../../../types/generated-types';
import { Notifier } from '../../../../../system/services/notificationManager';
import _ from 'lodash';
import { EmptySchedule, MyThermostat } from '../../types';
import { ThermostatScheduleTemplateRowData } from './thermostat-schedule';
import { ViewMode } from '../../thermostat';
import { ThermostatScheduleVerificationDialog } from './thermostat-schedule-verification-dialog';
import { ThermostatAction } from '../../../../properties/types/thermostatAction';

const scheduleLevels: Array<string> = ['property', 'unit', 'thermostat'];

interface TemplateActionProps {
  handleOpenThermostatScheduleDetailDialog: () => void;
  unitId?: string;
  showApply: boolean;
  setShowApply: (showApply: boolean) => void;
  propertyId?: string;
  action: ThermostatAction;
  targetLevel: 'property' | 'unit' | 'thermostat';
  thermostat?: MyThermostat;
  setViewMode?: (mode: ViewMode) => void;
  template: ThermostatScheduleTemplateRowData;
  handleResetSelection: () => void;
  handleSetAction: (a: ThermostatAction) => void;
  handleOpenPropagationStatusView?: () => void;
  handleSelectedTemplateId: (templateId: string) => void;
}

export function ThermostatScheduleAction(props: TemplateActionProps) {
  const {
    handleOpenThermostatScheduleDetailDialog,
    unitId,
    action,
    propertyId,
    setShowApply,
    setViewMode,
    thermostat,
    targetLevel,
    handleSetAction,
    handleResetSelection,
    handleSelectedTemplateId,
    handleOpenPropagationStatusView,
    showApply,
    template,
  } = props;

  /* STATE */

  const [forceUpdate, setForceUpdate] = useState(false);
  const [scheduleLevel, setScheduleLevel] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  /* MUTATIONS */

  const [
    updateUnitThermostatScheduleTemplate,
    {
      data: unitData,
      error: unitError,
      // TODO: Loading_error: should we do something with loading status for this mutation?
      // loading: unitLoading,
    },
  ] = useUpdateUnitThermostatScheduleTemplateOverrideMutation();

  const [
    updatePropertyThermostatScheduleTemplate,
    {
      data: propertyData,
      error: propertyError,
      // TODO: Loading_error: should we do something with loading status for this mutation?
      // loading: propertyLoading,
    },
  ] = useUpdatePropertyThermostatScheduleTemplateOverrideMutation();

  const [updateThermostat, { data: thermostatData, error: thermostatError }] =
    useUpdateThermostatMutation();

  useEffect(() => {
    if (propertyId && template?._id && targetLevel === 'property') {
      setScheduleLevel(scheduleLevels[0]);
    } else if (unitId && template?._id && targetLevel === 'unit') {
      setScheduleLevel(scheduleLevels[1]);
    } else if (
      thermostat?.deviceId &&
      template?._id &&
      targetLevel === 'thermostat'
    ) {
      setScheduleLevel(scheduleLevels[2]);
    }
  }, [propertyId, unitId, thermostat]);

  /* HANDLERS */

  const handleSubmit = (submitAction: string) => {
    switch (scheduleLevel) {
      case scheduleLevels[0]:
        if (propertyId) {
          if (setViewMode) {
            setViewMode(ViewMode.UPDATING);
          }
          const propertyThermostatUpdateData: UpdatePropertyThermostatScheduleTemplateOverrideMutationVariables =
            {
              id: propertyId,
              thermostatScheduleTemplateId:
                submitAction === 'apply'
                  ? template?._id
                  : submitAction === 'remove'
                    ? '0'
                    : '',
              force: forceUpdate,
            };
          updatePropertyThermostatScheduleTemplate({
            variables: propertyThermostatUpdateData,
          })
            .then((result) => {
              if (submitAction === 'apply') {
                Notifier.success(
                  'Applied property level thermostat schedule template',
                );
                handleSelectedTemplateId(
                  result.data?.updatePropertyThermostatScheduleTemplateOverride
                    .thermostatScheduleTemplateId ?? '-1',
                );
                setShowApply(false);
              } else if (submitAction === 'remove') {
                Notifier.success(
                  'Removed property level thermostat schedule template',
                );
                handleResetSelection();
              }
              if (setViewMode) {
                setViewMode(ViewMode.NORMAL);
              }
              setForceUpdate(false);
            })
            .catch((error) => {
              if (submitAction === 'apply') {
                console.log(
                  'Failed to apply property level thermostat schedule template: ',
                  error,
                );
                Notifier.error(
                  'Failed to apply property level thermostat schedule template',
                );
              } else if (submitAction === 'remove') {
                console.log(
                  'Failed to remove property level thermostat schedule template: ',
                  error,
                );
                Notifier.error(
                  'Failed to remove property level thermostat schedule template',
                );
              } else {
                console.log(
                  'Failed to apply or remove property level thermostat schedule template: ',
                  error,
                );
                Notifier.error(
                  'Failed to apply or remove property level thermostat schedule template',
                );
              }
              if (setViewMode) {
                setViewMode(ViewMode.NORMAL);
              }
              setForceUpdate(false);
            });
        } else {
          console.log(
            'Failed to apply property level thermostat schedule template: No Property Found',
          );
          Notifier.error(
            'Failed to apply Property Level Thermostat Schedule Template. No Property Found',
          );
        }
        break;
      case scheduleLevels[1]:
        if (unitId) {
          if (setViewMode) {
            setViewMode(ViewMode.UPDATING);
          }
          const unitThermostatUpdateData: UpdateUnitThermostatScheduleTemplateOverrideMutationVariables =
            {
              id: unitId,
              thermostatScheduleTemplateId:
                submitAction === 'apply'
                  ? template?._id
                  : submitAction === 'remove'
                    ? '0'
                    : '',
              force: forceUpdate,
            };
          updateUnitThermostatScheduleTemplate({
            variables: unitThermostatUpdateData,
          })
            .then((result) => {
              if (submitAction === 'apply') {
                Notifier.success(
                  'Applied Unit Level Thermostat Schedule Template',
                );
                handleSelectedTemplateId(
                  result.data?.updateUnitThermostatScheduleTemplateOverride
                    .thermostatScheduleTemplateId ?? '-1',
                );
                setShowApply(false);
              } else if (submitAction === 'remove') {
                Notifier.success(
                  'Removed Unit Level Thermostat Schedule Template',
                );
                handleResetSelection();
              } else {
                Notifier.success(
                  'Updated Unit Level Thermostat Schedule Template',
                );
                setShowApply(false);
                handleResetSelection();
              }
              if (setViewMode) {
                setViewMode(ViewMode.NORMAL);
              }
              setForceUpdate(false);
            })
            .catch((error) => {
              if (submitAction === 'apply') {
                console.log(
                  'Failed to Apply Unit Level Thermostat Level Schedule Template: ',
                  error,
                );
                Notifier.error(
                  'Failed to Apply Thermostat Level Schedule Template',
                );
              } else if (submitAction === 'remove') {
                console.log(
                  'Failed to remove unit level schedule template: ',
                  error,
                );
                Notifier.error(
                  'Failed to Remove Unit Level Thermostat Schedule Template',
                );
              } else {
                console.log(
                  'Failed to Update Unit Level Thermostat Schedule Template: ',
                  error,
                );
                Notifier.error(
                  'Failed to Update Unit Level Thermostat Schedule Template',
                );
              }
              if (setViewMode) {
                setViewMode(ViewMode.NORMAL);
              }
              setForceUpdate(false);
            });
        } else {
          console.log(
            'Failed to update unit level thermostat schedule template: No Unit found',
          );
          Notifier.error(
            'Failed to Update Unit Level Thermostat Schedule Template: No Unit Found',
          );
          setForceUpdate(false);
        }
        break;
      case scheduleLevels[2]:
        const fields: ThermostatUpdateFields = {};
        const updates: ThermostatUpdateInput = {};
        if (submitAction === 'remove') {
          fields.thermostatScheduleTemplateId = '0';
          fields.useCustomSchedule = false;
          fields.propertyId = propertyId ?? '';
          fields.unitId = unitId ?? '';
          updates.schedule = EmptySchedule;
        } else if (
          submitAction === 'apply' &&
          template?._id &&
          template?.value
        ) {
          updates.schedule = template.value?.value ?? EmptySchedule;
          fields.useCustomSchedule = false;
          fields.thermostatScheduleTemplateId = template?._id;
        }
        if (thermostat?.deviceId) {
          if (setViewMode) {
            setViewMode(ViewMode.UPDATING);
          }
          updateThermostat({
            variables: {
              id: thermostat.deviceId,
              updates,
              fields,
              options: {},
            },
          })
            .then((result) => {
              if (submitAction === 'apply') {
                Notifier.success('Applied Thermostat Level Schedule Template');
                setShowApply(false);
              } else if (submitAction === 'remove') {
                Notifier.success('Removed Thermostat Level Schedule Template');
                handleResetSelection();
              } else {
                Notifier.success(
                  'Updated Thermostat Level Thermostat Schedule Template',
                );
                setShowApply(false);
                handleResetSelection();
              }
              setForceUpdate(false);
            })
            .catch((error) => {
              if (submitAction === 'apply') {
                console.log(
                  'Error Applying Thermostat Level Schedule Template: ',
                  error,
                );
                Notifier.error(
                  'Received Error while updating Thermostat Level Schedule Template. Please try again or contact support if the problem persists.',
                );
              } else if (submitAction === 'remove') {
                console.log(
                  'Error Removing Thermostat Level Schedule Template: ',
                  error,
                );
                Notifier.error(
                  'Received Error while removing Thermostat Level Schedule Template. Please try again or contact support if the problem persists.',
                );
              } else {
                console.log(
                  'Error Updating Thermostat Level Schedule Template: ',
                  error,
                );
                Notifier.error(
                  'Received Error while updating Thermostat Level Schedule Template. Please try again or contact support if the problem persists.',
                );
              }
              if (setViewMode) {
                setViewMode(ViewMode.NORMAL);
              }
              setForceUpdate(false);
            });
        } else {
          console.log(
            '[Error Updating Thermostat]: ',
            'No thermostat device found',
          );
          Notifier.error(
            'Error Updating Thermostat: No Thermostat Device Found',
          );
          setForceUpdate(false);
        }
        break;
      default:
        Notifier.error('No Property, Unit or Thermostat Selected');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelForceUpdate = () => {
    setForceUpdate(false);
    handleClose();
  };

  return (
    <>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            color="warning"
            sx={{ color: '#FFF', marginBottom: '14px' }}
            size="small"
            onClick={handleOpenThermostatScheduleDetailDialog}
          >
            Edit
          </Button>
        </Grid>
        <>
          {!showApply ? (
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  disabled={showApply}
                  fullWidth
                  color="error"
                  sx={{ color: '#FFF' }}
                  size="small"
                  onClick={() => {
                    handleSetAction('remove');
                    if (scheduleLevel !== 'thermostat') {
                      setOpen(true);
                    } else {
                      handleSubmit('remove');
                    }
                  }}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </>
        <>
          {showApply ? (
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Button
                  disabled={!showApply}
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ color: '#FFF' }}
                  size="small"
                  onClick={() => {
                    handleSetAction('apply');
                    if (scheduleLevel !== 'thermostat') {
                      setOpen(true);
                    } else {
                      handleSubmit('apply');
                    }
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </>
      </Grid>
      <ThermostatScheduleVerificationDialog
        action={action}
        open={open}
        forceUpdate={forceUpdate}
        setForceUpdate={(forceUpdate) => setForceUpdate(forceUpdate)}
        template={template}
        propertyId={propertyId}
        unitId={unitId}
        level={scheduleLevel}
        cancelForceUpdate={() => handleCancelForceUpdate()}
        handleSubmit={(submitAction: string) => handleSubmit(submitAction)}
        handleClose={() => handleClose()}
        handleOpenPropagationStatusView={handleOpenPropagationStatusView}
      />
    </>
  );
}
