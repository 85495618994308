import * as React from 'react';
/* MUI */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableContainer,
  Container,
} from '@mui/material';
/* HELPERS */
import dayjs from 'dayjs';
import { convertToTempUnits } from '../../../devices/util/convert-temp-units';
/* COMPONENTS */
import { DisplayValue } from '../../../devices/shared-ui';
/* TYPES */
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../../system/models/temperatureUnits';

interface ThermostatScheduleProps {
  dailySchedule: any;
  zoneId: string;
  preferredUnits: TemperatureUnit;
}

export function ThermostatScheduleTable(props: ThermostatScheduleProps) {
  const { dailySchedule, zoneId, preferredUnits } = props;
  const entries = Object.entries(dailySchedule);
  const thermostatScheduleRows: any[] = entries.map(
    ([timeOfDay, scheduleSetting]) => {
      const time = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(parseInt(timeOfDay))
        .format('LT');
      return {
        time,
        setting: scheduleSetting,
      };
    },
  );

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table
        sx={{ minWidth: 350, boxShadow: 0 }}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell align="center">Heat to</TableCell>
            <TableCell align="center">Cool to</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {thermostatScheduleRows.map((row, index) => (
            <TableRow
              key={`${zoneId}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ width: '20px' }}>
                <Typography noWrap>{row.time}</Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: '20px' }}>
                <DisplayValue
                  valueSize="20px"
                  value={convertToTempUnits(
                    row.setting.minimum,
                    preferredUnits,
                  )}
                  mode="Heat"
                  units={preferredUnits}
                />
              </TableCell>
              <TableCell align="center" sx={{ width: '20px' }}>
                <DisplayValue
                  valueSize="20px"
                  value={convertToTempUnits(
                    row.setting.maximum,
                    preferredUnits,
                  )}
                  mode="Cool"
                  units={preferredUnits}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
