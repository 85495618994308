/**
 * Thermostat Schedule Template Detail
 * - display a detailed overview of the schedule template
 */
import React, { useState } from 'react';
/* MUI */
import { Box } from '@mui/material';
/* HELPERS */
import { WdTab, WdTabs } from './weekday-tabs';
/* TYPES */
import { DayOfWeek } from '../../types/schedule-types';
import { EnumScheduleType } from '../../../../../../types/generated-types';
import { ThermostatScheduleTemplateOverview } from './thermostat-schedule-template-overview';
import { ThermostatScheduleTemplateRowData } from './thermostat-schedule';

const dayOfWeekLabels: Record<DayOfWeek, string> = {
  [DayOfWeek.SU]: 'SU',
  [DayOfWeek.MO]: 'MO',
  [DayOfWeek.TU]: 'TU',
  [DayOfWeek.WE]: 'WE',
  [DayOfWeek.TH]: 'TH',
  [DayOfWeek.FR]: 'FR',
  [DayOfWeek.SA]: 'SA',
};
const label: string[] = ['Weekday', 'Weekend'];

interface ThermostatScheduleTemplateDetailProps {
  template: ThermostatScheduleTemplateRowData;
}

const scheduleType = EnumScheduleType.SameEveryDay;

export function ThermostatScheduleTemplateDetail(
  props: ThermostatScheduleTemplateDetailProps,
) {
  const { template } = props;
  let selectedScheduleType = scheduleType ?? EnumScheduleType.SameEveryDay;
  const [selectedDay, setSelectedDay] = useState(DayOfWeek.SU);

  const dailySchedule = template?.value.value[selectedDay] ?? {};

  // Get days of the week to display tabs for, depending on the schedule type
  function daysToDisplay(): DayOfWeek[] {
    if (selectedScheduleType === EnumScheduleType.Custom) {
      return Object.values(DayOfWeek);
    } else if (selectedScheduleType === EnumScheduleType.WeekdayWeekend) {
      return [DayOfWeek.MO, DayOfWeek.SU];
    } else {
      return [DayOfWeek.SU];
    }
  }
  // Get tab labels by schedule type
  // Example: If schedule type is 'SameEveryDay' then only display one tab label 'Every day'
  function getTabLabel(dayOfWeek: DayOfWeek): string {
    if (selectedScheduleType === EnumScheduleType.SameEveryDay) {
      return 'Everyday';
    } else {
      return dayOfWeekLabels[dayOfWeek];
    }
  }

  // View change handler
  function handleViewChange(event: React.SyntheticEvent, newValue: DayOfWeek) {
    setSelectedDay(newValue);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        key={`${template?._id}`}
        style={{
          background: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
        }}
      >
        <WdTabs
          variant="fullWidth"
          value={selectedDay}
          onChange={handleViewChange}
        >
          {daysToDisplay().map((dayOfWeek, index) => {
            return (
              <WdTab
                key={dayOfWeek}
                value={dayOfWeek}
                label={
                  daysToDisplay().length === 2 &&
                  selectedScheduleType === EnumScheduleType.WeekdayWeekend
                    ? label[index]
                    : getTabLabel(dayOfWeek)
                }
              />
            );
          })}
        </WdTabs>
        <ThermostatScheduleTemplateOverview
          dailySchedule={dailySchedule}
          templateId={template?._id ?? ''}
        />
        <br />
      </div>
    </Box>
  );
}
