import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { Slider } from '../system-edit/slider';
import { MyScheduleSetting } from '../../types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useAuthenticator } from '../../../../../auth/AuthenticationContext';
import { Fahrenheit } from '../../../../../system/models/temperatureUnits';
import { convertToTempUnits } from '../../../util/convert-temp-units';
import { Stack } from '@mui/material';
import { DisplayValue } from '../../../shared-ui';

interface EditSettingsProps {
  id?: string;
  mode: 'heating' | 'cooling';
  open: boolean;
  handleChange: (mode: string, value: number) => void;
  value?: number;
  anchorEl: HTMLButtonElement | null;
  theme: Theme;
  handleClick: (
    mode: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleClose: (mode: string, setting: MyScheduleSetting) => void;
  maxCoolSetpointLimit?: Maybe<number> | undefined;
  maxHeatSetpointLimit?: Maybe<number> | undefined;
  minCoolSetpointLimit?: Maybe<number> | undefined;
  minHeatSetpointLimit?: Maybe<number> | undefined;
}

export function EditSettings(props: EditSettingsProps) {
  const {
    id,
    mode,
    open,
    anchorEl,
    theme,
    handleClick,
    value,
    handleChange,
    handleClose,
    maxCoolSetpointLimit,
    maxHeatSetpointLimit,
    minCoolSetpointLimit,
    minHeatSetpointLimit,
  } = props;
  const label = () => mode.split('ing')[0] + ' to';
  const key = mode === 'cooling' ? 'maximum' : 'minimum';

  const { user } = useAuthenticator();

  const preferredUnits = user?.preferredUnits ?? Fahrenheit;

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'object') {
      handleChange(mode, newValue[0]);
    } else {
      handleChange(mode, newValue);
    }
    // if (formattedValue !== undefined) {
    //   handleChange(mode, formattedValue);
    // } else {
    //   Notifier.error('An error occured while converting setpoint');
    // }
  };

  // TODO: Calvin Swap ListItemText for DisplayValue

  /***
     Thermostat setpoint profile limit settings are checked 
     And constrain the slider max and min values 
     If setpoint profile limits are applied
  ***/

  let sliderMinVal = convertToTempUnits(7, preferredUnits, 'C');
  let sliderMaxVal = convertToTempUnits(32, preferredUnits, 'C');

  switch (mode) {
    case 'cooling':
      if (minCoolSetpointLimit !== undefined && sliderMinVal !== undefined) {
        sliderMinVal = minCoolSetpointLimit as number;
      }
      if (maxCoolSetpointLimit !== undefined && sliderMaxVal !== undefined) {
        sliderMaxVal = maxCoolSetpointLimit as number;
      }
      break;
    case 'heating':
      if (minHeatSetpointLimit !== undefined && sliderMinVal !== undefined) {
        sliderMinVal = minHeatSetpointLimit as number;
      }
      if (maxHeatSetpointLimit !== undefined && sliderMaxVal !== undefined) {
        sliderMaxVal = maxHeatSetpointLimit as number;
      }
      break;
    default:
  }

  return (
    <>
      <Button
        onClick={(event) => handleClick(mode, event)}
        sx={{
          px: 1,
          color: theme.embue[mode],
        }}
      >
        <DisplayValue
          // value={formattedValue}
          value={value}
          label={label()}
          units={preferredUnits}
          size="h4"
          unitsSize="10px"
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={() => handleClose(mode, { [key]: formattedValue })}
        onClose={() => handleClose(mode, { [key]: value })}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          width: 400,
          height: 200,
        }}
      >
        <Box sx={{ px: 2, py: 1, width: 250, height: 100 }}>
          <Typography variant={'button'} gutterBottom>
            {label()}:
          </Typography>
          <Stack spacing={2.5} direction="row" sx={{ marginTop: '10px' }}>
            <DisplayValue
              value={sliderMinVal}
              units={preferredUnits}
              size="h5"
              unitsSize="10px"
            />
            <Slider
              aria-label={`${mode} Set Temperature`}
              value={value as number}
              // value={formattedValue as number}
              min={sliderMinVal as number}
              max={sliderMaxVal as number}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              sx={{
                color: theme.embue[mode],
              }}
            />
            <DisplayValue
              value={sliderMaxVal}
              units={preferredUnits}
              size="h5"
              unitsSize="10px"
            />
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
