import React, { createContext, FC, useContext, useMemo, useState } from 'react';

import {
  AUTH_IDENTIFY_USER_TOKEN_NAME,
  AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
  AUTH_STATE_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_ID_TOKEN_NAME,
  AUTH_REMEMBER_ME_TOKEN_NAME,
} from '../../helpers/config';
import { Log, LogCategory } from './services/logger';
import { IServerToken } from '../auth/models';

import { EnumAuthenticationProfileFactorsType } from '../../types/generated-types';
import { LocalState } from './services/localStateManager';

const parseJwt = (token: string) => {
  try {
    // TODO: Peter: replace the following call to atob with Buffer.from(data, 'base64') as the former is deprecated.
    // noinspection JSDeprecatedSymbols
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export interface IAuthState {
  exp: number;
  current: {
    factorId?: string;
    factorName?: string;
    factorType: EnumAuthenticationProfileFactorsType;
    issuedAt: number;
    context: {
      display?: string;
      challenge?: string;
    };
  };
  chain: {
    id: string;
    type: EnumAuthenticationProfileFactorsType;
    value?: string;
  }[];
}

// This type represents the valid set of token names for which our
// session state manager service will be able to provide values.
export declare type TokenName =
  | typeof AUTH_REMEMBER_ME_TOKEN_NAME
  | typeof AUTH_ID_TOKEN_NAME
  | typeof AUTH_STATE_TOKEN_NAME
  | typeof AUTH_SESSION_TOKEN_NAME
  | typeof AUTH_SESSION_EXPIRATION_TOKEN_NAME
  | typeof AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME
  | typeof AUTH_IDENTIFY_USER_TOKEN_NAME;

// Describes the actual types for the data represented by each of our tokens.
export interface ITokens extends Record<string, any> {
  [AUTH_REMEMBER_ME_TOKEN_NAME]: number | undefined;
  [AUTH_ID_TOKEN_NAME]: string | undefined;
  [AUTH_STATE_TOKEN_NAME]: IAuthState | undefined;
  [AUTH_SESSION_TOKEN_NAME]: string | undefined;
  [AUTH_SESSION_EXPIRATION_TOKEN_NAME]: number | undefined;
  [AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME]: string | undefined;
  [AUTH_IDENTIFY_USER_TOKEN_NAME]: string | undefined;
}

// This type describes the map of converter functions used to convert textual
// token values into the appropriate types for what our tokens are supposed
// to represent: Dates, numbers, strings, booleans.
type TokenFactories<
  T extends Record<string, any> = Record<string, any>,
  K extends keyof T = keyof T,
  R extends T[K] = T[K],
> = Record<K, (rawValue: R) => R | undefined>;

// This is the actual token value factory map with import functions for each
// token from its string representation.
export const TokenFactoryMap: TokenFactories = {
  [AUTH_REMEMBER_ME_TOKEN_NAME]: (
    raw: number | undefined,
  ): number | undefined => raw as number,
  [AUTH_ID_TOKEN_NAME]: (raw: string | undefined) => raw,
  [AUTH_STATE_TOKEN_NAME]: (raw: string | undefined) => {
    try {
      return raw?.length ? parseJwt(raw) : undefined;

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      return undefined;
    }
  },
  [AUTH_SESSION_TOKEN_NAME]: (raw: string | undefined) => raw,
  [AUTH_SESSION_EXPIRATION_TOKEN_NAME]: (
    raw: number | undefined,
  ): number | undefined => raw,
  [AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME]: (raw: string | undefined) => raw,
  [AUTH_IDENTIFY_USER_TOKEN_NAME]: (raw: string | undefined) => raw,
};

// These are the method signatures for operations supported by our session state manager.
export interface ISessionStateManager extends ITokens {
  updateTokens(): void;
}

// This is the data map provided by our server state manager.
export interface IRawTokens extends Record<string, IServerToken | undefined> {
  [AUTH_REMEMBER_ME_TOKEN_NAME]: IServerToken | undefined;
  [AUTH_ID_TOKEN_NAME]: IServerToken | undefined;
  [AUTH_STATE_TOKEN_NAME]: IServerToken | undefined;
  [AUTH_SESSION_TOKEN_NAME]: IServerToken | undefined;
  [AUTH_SESSION_EXPIRATION_TOKEN_NAME]: IServerToken | undefined;
  [AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME]: IServerToken | undefined;
  [AUTH_IDENTIFY_USER_TOKEN_NAME]: IServerToken | undefined;
}

const extractTokens = () => {
  try {
    const rawTokens: IRawTokens = {
      [AUTH_REMEMBER_ME_TOKEN_NAME]: undefined,
      [AUTH_ID_TOKEN_NAME]: undefined,
      [AUTH_STATE_TOKEN_NAME]: undefined,
      [AUTH_SESSION_TOKEN_NAME]: undefined,
      [AUTH_SESSION_EXPIRATION_TOKEN_NAME]: undefined,
      [AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME]: undefined,
      [AUTH_IDENTIFY_USER_TOKEN_NAME]: undefined,
    };
    Object.keys(TokenFactoryMap).forEach((key) => {
      // const val = LocalState.getItem<IServerToken>(key);
      // TODO: Peter: do we need this any longer?
      // if (val?.value) {
      //   if (typeof val.value === 'string' && val.value?.startsWith('{name')) {
      //     try {
      //       const parsed = JSON.parse(val.value);
      //       val.value = parsed;
      //     } catch (e: any) {
      //       console.log('got error during JSON parsing', val.value, e.message);
      //     }
      //   }
      // }
      // rawTokens[key] = val
      rawTokens[key] = LocalState.getItem<IServerToken>(key);
    });

    const newTokens: ITokens = { ...defaultTokensValue };

    for (const [key, value] of Object.entries(rawTokens)) {
      if (value) {
        const item = LocalState.getItem<IServerToken>(key);
        const expiration = item?.expiration;
        const remove = item?.remove;
        if (remove || (expiration && expiration < Date.now())) {
          LocalState.removeItem(key);
        } else if (value?.value !== undefined) {
          newTokens[key] = TokenFactoryMap[key](value.value);
        }
      }
    }
    return newTokens;
  } catch (error) {
    return defaultTokensValue;
  }
};

// Used to provide default values for the tokens defined for our system.
// If the token is missing, the session state service will provide this default
// value in place of 'nothing'.
const defaultTokensValue = {
  [AUTH_REMEMBER_ME_TOKEN_NAME]: undefined,
  [AUTH_ID_TOKEN_NAME]: undefined,
  [AUTH_SESSION_TOKEN_NAME]: undefined,
  [AUTH_STATE_TOKEN_NAME]: undefined,
  [AUTH_SESSION_EXPIRATION_TOKEN_NAME]: undefined,
  [AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME]: undefined,
  [AUTH_IDENTIFY_USER_TOKEN_NAME]: undefined,
};

// Default context value merging the default token data and the stubbed operations.
const defaultManagerValue: ISessionStateManager = {
  ...defaultTokensValue,
  // ...extractTokens(),
  updateTokens: () => {
    // default - no-op
  },
};

interface SessionStateManagerProviderProps {
  children: React.ReactNode;
}

const SessionStateManagerContext =
  createContext<ISessionStateManager>(defaultManagerValue);

SessionStateManagerContext.displayName = 'SessionStateManagerContext';

export const SessionStateManagerProvider: FC<
  SessionStateManagerProviderProps
> = ({ children }) => {
  const [serverTokens, setServerTokens] = useState<ITokens>(extractTokens());

  // TODO: Peter: Old version had this inside memo and not as a use callback. Would that help?
  const updateTokens = () => {
    const newTokens = extractTokens();

    const updateRequired = true;

    // TODO: Peter: an attempt to optimize refreshes. Leave this for a bit ... I may yet experiment a bit more.
    // const updateRequired =
    //   !!Object.keys(newTokens).find(
    //     (key) => newTokens[key] !== serverTokens[key],
    //   ) ||
    //   !!Object.keys(serverTokens).find((key) => serverTokens[key] !== newTokens[key]);

    if (updateRequired) {
      // Log.silly('updating tokens !!!!!!!', newTokens); //, LogCategory.TOKENS);
      setServerTokens(newTokens);
    } else {
      // Log.silly('skipping session state update!!!!!!', null); //, LogCategory.TOKENS);
    }
  };

  const value = useMemo(
    () => ({
      ...extractTokens(),
      updateTokens,
    }),
    [serverTokens, updateTokens],
  );

  Log.silly('rendering session state manager', null, [
    LogCategory.TOKENS,
    LogCategory.RENDERING,
  ]);

  return (
    <SessionStateManagerContext.Provider value={value}>
      {children}
    </SessionStateManagerContext.Provider>
  );
};

/**
 * This hook will provide you with current values for all tokens (and keep them updated)
 * as well as give you some useful functions you can call to force the update of tokens
 * from the current document and/or remove one or more named tokens from the current
 * document and from this context.
 */
export const useSessionState = (): ISessionStateManager => {
  const context = useContext(SessionStateManagerContext);
  if (context === undefined) {
    throw new Error(
      'useSessionState must be used within a SessionStateProvider',
    );
  }
  return context;
};

/**
 * This hook will return and keep you updated with the value of the named token.
 * @param name
 */
export function useToken<T extends string | Date>(
  name: TokenName,
): T | undefined {
  const context = useContext(SessionStateManagerContext);
  if (context === undefined) {
    throw new Error(
      'useSessionState must be used within a SessionStateProvider',
    );
  }
  return context[name] ? (context[name] as T) : undefined;
}
